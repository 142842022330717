import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dbb9915"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading" }
const _hoisted_2 = {
  key: 0,
  class: "heading__title"
}
const _hoisted_3 = {
  key: 1,
  class: "heading__subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.subtitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.subtitle), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}