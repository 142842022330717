<template>
    <svg class="icon" :class="{ 'icon-spin': spin, 'icon-viewbox': viewBox }" :viewBox="viewBox" :height="height" :width="width">
        <use :href="`#${icon}`" />
    </svg>
</template>

<script setup lang="ts">
import { viewBoxes } from "@/config/viewBoxes";

interface Props {
    icon: string;
    spin?: boolean;
    height?: string;
    width?: string;
}

const props = defineProps<Props>();

const viewBox = viewBoxes[props.icon];
</script>

<style lang="scss" scoped>
.icon {
    fill: currentColor;

    &.icon-spin {
        animation: icon-spin 2s infinite linear;
    }
}

.icon-viewbox {
    width: unset;
    height: unset;
}

@keyframes icon-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}
</style>
