import { UserType } from "../Enums";
import { AgentUserResponse } from "../api/AgentUserResponse";
import { CustomerResponse } from "../api/customer/CustomerResponse";
import { AgentAccount } from "../front/agent/AgentAccount";

export type User = {
    email?: string;
    type: UserType;
    number?: number | string;
    defaultProductCode?: string;
    accountTitle?: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    mobilePhone?: string;
    zip?: string;
    address?: string;
    address2?: string;
    city?: string;
    country?: string;
    isIslander?: boolean;
    isCardCustomer?: boolean;
    customerType?: "Commuter" | "Discount" | "Staff" | "TravelCard" | "Regular";
    selectedProductCode?: string;
    contact?: string;
    agentAccount?: AgentAccount;
};

export function mapFromCustomerLoginResponse(data: CustomerResponse): User {
    return {
        type: UserType.Customer,
        name: `${data.firstName} ${data.lastName}`,
        firstName: data.firstName,
        lastName: data.lastName,
        number: data.customerNumber,
        email: data.email,
        address: data.address,
        address2: data.address2,
        city: data.city,
        country: data.country,
        zip: data.zip,
        mobilePhone: data.mobilePhone,
        customerType: data.customerType,
        isIslander: data.isIslander,
        isCardCustomer: ["Commuter", "TravelCard", "Discount", "Staff"].includes(data.customerType),
    };
}

export function mapFromAgentLoginResponse(agent: AgentUserResponse): User {
    return {
        type: UserType.Agent,
        name: agent.name,
        number: agent.agentNumber,
        defaultProductCode: agent.account.productCode,
        selectedProductCode: agent.account.productCode,
        agentAccount: new AgentAccount(agent.account),
    };
}
