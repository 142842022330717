import useApi from "@/composables/useApi";
import { AllRoutesCode } from "@/models/Constants";
import { CancelBookingResultDto } from "@/models/api/CancelBookingResultDto";
import { CancelBookingResult } from "@/models/front/cancelBooking/CancelBookingResult";
import { MyBooking } from "@/models/front/customer/MyBooking";
import { ref } from "vue";
import { useQueryClient } from "vue-query";

export type RefundParam = {
    refundMethod: string;
    bank?: string;
    accountNumber?: string;
    accountHolder?: string;
    clearingNumber?: string;
    name?: string;
    giro?: string;
};

const useCancelBooking = (accessToken: string, route: string) => {
    const booking = ref<MyBooking>();
    const isLoading = ref(false);
    const isConfirming = ref(false);
    const data = ref<CancelBookingResult>();
    const error = ref("");

    const routeToCancel = route === AllRoutesCode ? undefined : route;

    const queryClient = useQueryClient();

    const { post } = useApi();

    const prepareForCancellation = () => {
        isLoading.value = true;

        return post<CancelBookingResultDto>(`booking/cancel/prepare`, {
            accessToken,
            route: routeToCancel,
        })
            .then((resp) => {
                data.value = new CancelBookingResult(resp.data);
                return data.value;
            })
            .catch((err) => {
                error.value = err.message;
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    const confirmCancellation = (refund: RefundParam) => {
        isConfirming.value = true;
        return post<CancelBookingResultDto>("booking/cancel/confirm", {
            accessToken,
            route: routeToCancel,
            refund,
        })
            .then(() => {
                // If we update straight away the lightbox will be closed too fast
                setTimeout(() => {
                    queryClient.invalidateQueries("myBookings");
                }, 2500);
            })
            .catch((err) => {
                error.value = err.message;
                return Promise.reject();
            })
            .finally(() => {
                isConfirming.value = false;
            });
    };

    return {
        prepareForCancellation,
        confirmCancellation,
        booking,
        isLoading,
        isConfirming,
        data,
        error,
    };
};

export default useCancelBooking;
