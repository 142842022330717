<template>
    <div class="giftcard">
        <BaseButton
            type="button"
            class="btn--transparent"
            :class="{ active: showGiftCard }"
            @click="showGiftCard = !showGiftCard"
            icon="chevron/chevron-down"
            iconSize="sm"
        >
            <div>
                <b>{{ $t("booking.checkout.paymentMethod.giftcard.title") }}</b>
                <p>{{ $t("booking.checkout.paymentMethod.giftcard.subtitle") }}</p>
            </div>
        </BaseButton>
        <div v-if="showGiftCard" class="giftcard__expanded">
            <form v-if="showGiftCardForm || !giftcards.length" @submit.prevent="submitForm">
                <div class="giftcard__inputs">
                    <BaseInput
                        :label="$t('booking.checkout.paymentMethod.giftcard.labels.number')"
                        name="cardNumber"
                        type="tel"
                        mask="#### #### #### #### ###"
                        class="number"
                        v-model="v$.cardNumber.$model"
                        :hasError="v$.cardNumber.$error"
                    />

                    <BaseInput
                        :label="$t('booking.checkout.paymentMethod.giftcard.labels.cvc')"
                        name="cvc"
                        type="tel"
                        mask="###"
                        class="cvc"
                        v-model="v$.cvc.$model"
                        :hasError="v$.cvc.$error"
                    />
                </div>
                <BaseButton :loading="isLoading" :disabled="!v$.cvc.$model.length || !v$.cardNumber.$model.length">
                    {{ $t("booking.checkout.paymentMethod.giftcard.button") }}
                </BaseButton>
            </form>
            <BaseMessages :messages="[...new Set(v$.$errors.map((e) => e.$message)), error]" />
            <div v-if="giftcards.length" class="giftcard_valid">
                <div v-for="(card, index) in giftcards" :key="index" class="giftcard_valid__card">
                    <div class="info">
                        <div class="checkmark-box">
                            <SvgIcon icon="checkmark" class="check" />
                        </div>
                        <div class="numbers">
                            <p class="label">
                                {{ $t("booking.checkout.paymentMethod.giftcard.labels.number") }}
                            </p>
                            <p class="number">
                                {{ card.pan }}
                            </p>
                        </div>
                    </div>
                    <div class="payment-info">
                        <p class="balance">{{ card.balance }}:-</p>
                        <p class="label" v-if="card.balance">
                            ({{ $t("booking.checkout.paymentMethod.giftcard.labels.sumAfterUse") }} {{ card.balanceAfterUse }}:-)
                        </p>
                    </div>
                    <div class="remove-box" @click="removeCard(card)">
                        <SvgIcon icon="x" class="remove" />
                    </div>
                </div>
                <BaseButton
                    v-if="remainingSum > 0 && !showGiftCardForm"
                    @click="showGiftCardForm = true"
                    class="button"
                    type="button"
                    icon="booking/plus"
                    iconSize="sm"
                    iconPosition="left"
                    >{{ $t("booking.checkout.paymentMethod.giftcard.labels.addNew") }}</BaseButton
                >
            </div>
            <hr />
            <p class="giftcard__bottom" v-if="leftToPay">
                {{ $t("booking.checkout.paymentMethod.giftcard.remainingSum") }} {{ remainingSum }}:-
                {{ $t("booking.checkout.paymentMethod.giftcard.chooseMethod") }}
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseMessages from "@/components/base/BaseMessages.vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import useGiftCard from "@/composables/useGiftCard";
import { validCardNumber, validCvc } from "@/helpers/validators";
import { GiftCardBalanceRequest } from "@/models/api/GiftCardBalanceRequest";
import { GiftCardDetails } from "@/models/front/GiftCard";
import useVuelidate from "@vuelidate/core";
import { computed, reactive, ref } from "vue";

interface Props {
    leftToPay: number;
    modelValue: GiftCardDetails[];
}

const props = defineProps<Props>();
const emit = defineEmits(["update:modelValue"]);
const showGiftCard = ref(false);
const { giftCardBalance, isLoading } = useGiftCard();
const giftcardbalance = ref(props.leftToPay);

const giftcards = computed({
    get(): GiftCardDetails[] {
        return props.modelValue;
    },
    set(value: GiftCardDetails[]) {
        emit("update:modelValue", value);
    },
});

const showGiftCardForm = ref(true);

const remainingSum = computed(() => {
    let sum = props.leftToPay;
    giftcards.value.forEach((card) => {
        if (!card.balance) return;
        sum = sum - card.balance;
    });
    return sum <= 0 ? 0 : sum;
});

const state = reactive({
    cardNumber: "",
    cvc: "",
});

const rules = {
    cardNumber: { validCardNumber },
    cvc: { validCvc },
};

const v$ = useVuelidate(rules, state);
const error = ref();

const removeCard = (card: GiftCardDetails) => {
    const index = giftcards.value.findIndex((c) => c === card);
    giftcards.value.splice(index, 1);
};

const addNewCard = () => {
    error.value = "";
    giftCardBalance({
        cardNumber: state.cardNumber.replace(/\s/g, ""),
        cvc: state.cvc,
    } as GiftCardBalanceRequest)
        .then((resp) => {
            if (resp.giftCardBalance <= 0) return;
            giftcardbalance.value = resp.giftCardBalance;

            giftcards.value.push({
                pan: state.cardNumber,
                cvc: state.cvc,
                balance: resp.giftCardBalance,
                balanceAfterUse: resp.giftCardBalance - remainingSum.value <= 0 ? 0 : resp.giftCardBalance - remainingSum.value,
            });

            state.cardNumber = "";
            state.cvc = "";
            v$.value.$reset();
            showGiftCardForm.value = false;
            return giftcardbalance;
        })
        .catch((err) => {
            error.value = err.message;
        });
};

const submitForm = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) {
        return;
    }

    const alreadyAdded = giftcards.value.find((card) => card.pan === state.cardNumber);
    if (alreadyAdded) return;

    addNewCard();
};
</script>

<style lang="scss" scoped>
@import "~/styles/icons.scss";
.giftcard {
    margin-top: $space-md;

    > button {
        height: auto;
        white-space: break-spaces;
        text-align: left;

        :deep(.icon) {
            flex-shrink: 0;
            align-self: flex-end;
            margin-bottom: 5px;
        }

        &:not(.active) {
            :deep(.icon) {
                transform: rotate(-90deg);
            }
        }
    }

    form {
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;
        align-items: center;
        padding: $space-sm 0;

        button {
            padding: 0 50px;
        }
    }

    &__inputs {
        display: flex;
        flex-flow: row wrap;
        gap: $space-sm;

        .number {
            flex: 2;
            min-width: 170px;
        }

        .cvc {
            flex: 1;
            min-width: 75px;
        }
    }

    &__expanded {
        padding: $space-sm $space-md;
        background-color: $c-gray-300;
        border-radius: 6px;

        .giftcard_valid {
            .button {
                background: none;
                border: none;
                color: $c-black-100;
                font-size: 1.2rem;
                font-style: italic;
                padding-left: 0px;
            }

            &__card {
                margin: 20px 0px;
                display: flex;
                gap: 60px;
                align-items: flex-end;
                position: relative;
                .info {
                    display: flex;
                    gap: 10px;

                    .checkmark-box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 35px;
                        width: 35px;
                        min-height: 24px;
                        min-width: 24px;
                        color: $c-black-100;
                        background-color: $c-white;
                        border-radius: 50%;
                        border: 1px solid $c-green-100;

                        .icon {
                            width: 20px;
                            height: 20px;
                        }
                    }
                    .numbers {
                        display: flex;
                        flex-direction: column;
                        .label {
                            color: grey;
                            font-size: 0.9rem;
                        }
                    }
                }

                .payment-info {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    .balance {
                        font-size: 1.5rem;
                    }

                    .label {
                        font-style: italic;
                    }
                }

                .remove-box {
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    top: 0;
                    right: 0;
                    cursor: pointer;

                    .remove {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }

    hr {
        margin: 20px 0px;
    }

    &__bottom {
        font-size: 0.9rem;
        font-weight: 100;
        color: $c-gray-dark-2;
    }
}
</style>
