import { storageWithExpiration } from "@/helpers/storageHelper";
import { Direction } from "@/models/Enums";
import { SeatingReservation } from "@/models/api/ExtrasResponse";
import { defineStore } from "pinia";

type SeatingState = {
    outward: PartialSeatingState;
    return: PartialSeatingState;
};

type PartialSeatingState = {
    seats: string[];
    resourceCode: string;
    identifier: string;
};

export const useSeatingStore = defineStore("seating", {
    state: () => {
        return {
            outward: {},
            return: {},
        } as SeatingState;
    },
    getters: {
        getSelectedSeats: (state) => (direction: Direction) => {
            if (direction === Direction.Outward) {
                return state.outward;
            } else {
                return state.return;
            }
        },
    },
    actions: {
        setFromApi(direction: Direction, reservation?: SeatingReservation) {
            const selectedSeats = {
                identifier: reservation?.identifier ?? "",
                resourceCode: reservation?.resourceCode ?? "",
                seats: reservation?.reservations ?? [],
            };

            if (direction === Direction.Outward) {
                this.outward = selectedSeats;
            } else {
                this.return = selectedSeats;
            }
        },
        setSelectedSeats(direction: Direction, selectedSeats: PartialSeatingState) {
            if (direction === Direction.Outward) {
                return (this.outward = selectedSeats);
            } else {
                return (this.return = selectedSeats);
            }
        },
    },
    persist: {
        storage: storageWithExpiration(localStorage),
    },
});
