import { CoVehicleResponse } from "~/models/api/customer/CoVehiclesResponse";

export class CoVehicle {
    vehicleId: number;
    licenseNumber: string;
    make: string;
    length: number;
    resourceCode: string;
    description: string;
    isDefault: boolean;
    vehicleType: string;
    delete: boolean
    isEditable: boolean;

    constructor(resp: CoVehicleResponse) {
        this.vehicleId = resp.vehicleId;
        this.licenseNumber = resp.licenseNumber;
        this.make = resp.make;
        this.length = resp.length;
        this.resourceCode = resp.resourceCode;
        this.description = resp.description;
        this.isDefault = resp.isDefault;
        this.vehicleType = resp.vehicleType;
        this.delete = resp.delete;
        this.isEditable = resp.isEditable ?? true;
    }
}

