import { eachDayOfInterval, endOfWeek, startOfToday, startOfWeek, parseISO, max, min, isValid, isBefore, startOfHour } from "date-fns";
import { getLocale } from "./localeHelper";
import { formatInTimeZone } from "date-fns-tz";

export const formatDate = (date: string | Date, dateFormat: string): string => {
    return formatInTimeZone(new Date(date), "Europe/Stockholm", dateFormat, { locale: getLocale() });
};

export const dateAndTime = (date: string | Date): string => {
    return formatDate(date, "yyyy-MM-dd HH:mm");
};

export const YYYYMMDD = (date: string | Date): string => {
    return formatDate(date, "yyyy-MM-dd");
};

export const ddMMM = (date: string | Date): string => {
    return formatDate(date, "dd MMM");
};

export const ddMMYYYY = (date: string | Date): string => {
    return formatDate(date, "dd/MM/yyyy");
};

export const HHmm = (date: string | Date): string => {
    return formatDate(date, "HH:mm");
};

export const HHmmss = (date: string | Date): string => {
    return formatDate(date, "HH:mm:ss");
};

export const EEEddM = (date: string | Date): string => {
    return formatDate(date, "EEE dd/M");
};

export const EEEEddM = (date: string | Date): string => {
    return formatDate(date, "EEEE dd/M");
};

export const EEEEddMMMM = (date: string | Date): string => {
    return formatDate(date, "EEEE dd MMMM");
};

export const ddMMMMEEEE = (date: string | Date): string => {
    return formatDate(date, "dd MMMM EEEE");
};
export const ddMMMM = (date: string | Date): string => {
    return formatDate(date, "dd MMMM");
};

export const EEddMMMM = (date: string | Date): string => {
    return formatDate(date, "EE dd MMMM");
};

export const EEEE = (date: string | Date): string => {
    return formatDate(date, "EEEE");
};

export const DDMM = (date: string | Date): string => {
    return formatDate(date, "dd/M");
};

export const today = (): Date => {
    return startOfToday();
};

export const dMMM = (date: string | Date): string => {
    return formatDate(date, "d MMM");
};

export const getStartOfThisWeek = () => {
    return startOfWeek(today(), { locale: getLocale() });
};

export const getEndOfThisWeek = () => {
    return endOfWeek(today(), { locale: getLocale() });
};

export const parseDate = (date: string) => {
    return parseISO(date);
};
export const getShortWeekdays = () => {
    const start = getStartOfThisWeek();
    const end = getEndOfThisWeek();

    const days = eachDayOfInterval({ start, end });

    return days.map((d) => formatDate(d, "E"));
};

export const maxDate = (dates: Date[]) => {
    return max(dates);
};

export const minDate = (dates: Date[]) => {
    return min(dates);
};

export const isValidDate = (date: string): Boolean => {
    return isValid(parseISO(date));
};

export const firstDateOfWeek = (date: Date): Date => {
    return startOfWeek(date, { locale: getLocale() });
};

export const lastDateOfWeek = (date: Date): Date => {
    return endOfWeek(date, { locale: getLocale() });
};

export const hourHasPassed = (date: Date): boolean => {
    return isBefore(startOfHour(date), Date.now());
};

export const eachDayBetween = (start: Date, end: Date): Date[] => {
    return eachDayOfInterval({
        start,
        end
    });
}
