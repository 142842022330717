<template>
    <div class="messages">
        <ul>
            <li v-for="message in messages.filter((m) => !!m)" class="message" :class="'message--' + messageType.toString()" :key="message">
                <SvgIcon :icon="'booking/' + iconType" />
                <span>{{ message }}</span>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { BaseMessageType } from "@/models/Enums";
import SvgIcon from "@/components/base/SvgIcon.vue";

interface Props {
    messages: any[];
    messageType?: BaseMessageType;
}

const props = withDefaults(defineProps<Props>(), {
    messageType: BaseMessageType.Error,
});

const iconType = computed(() => {
    switch (props.messageType) {
        case BaseMessageType.Info:
            return "info";
        case BaseMessageType.Success:
        case BaseMessageType.Warning:
        case BaseMessageType.Error:
        default:
            return "alert";
    }
});
</script>

<style lang="scss" scoped>
.messages {
    ul {
        li {
            + li {
                margin-top: $space-sm;
            }

            &.message {
                $prefix: ".message";
                display: flex;
                align-items: center;
                gap: 8px;
                color: $c-red-100;
                @include font(p);
                font-size: $fs-14;

                :deep(.icon) {
                    width: 1rem;
                }

                span {
                    display: block;
                    flex: 1;
                    line-height: $fs-14;
                    margin-top: 2px;
                    color: $c-black-100;
                }

                &--info {
                    color: $c-blue-300;
                }

                &--success {
                    color: $c-green-100;
                }

                &--warning {
                    color: $c-orange-100;
                }

                &--error {
                    color: $c-red-100;
                }
            }
        }
    }
}
</style>
