import { CheckoutResponse } from "../api/CheckoutResponse";
import { PaymentAlternative, mapFromPaymentAlternativeResponse, mapFromRefundAlternativeResponse } from "./PaymentAlternative";
import { AgentAccount } from "./agent/AgentAccount";
import { BasketSection, mapFromBasketSectionResponse } from "./basket/BasketGroup";
import { AddressInformation, mapFromAddressInformationResponse } from "./customer/AddressInformation";

export class Checkout {
    bookingNumber: number;
    leftToPay: number;
    paid: number;
    address: AddressInformation;
    refundAlternatives: PaymentAlternative[];
    paymentAlternatives: PaymentAlternative[];
    paymentReference?: string;
    sections: BasketSection[];
    currency: string;
    account?: AgentAccount;
    isPayment: boolean;
    isRefund: boolean;
    showGiftCard: boolean;
    postInvoicing: boolean;

    constructor(resp: CheckoutResponse) {
        this.bookingNumber = resp.bookingNumber;
        this.leftToPay = resp.leftToPay;
        this.paid = resp.paid;
        this.address = mapFromAddressInformationResponse(resp.address);
        this.refundAlternatives = resp.refundAlternatives?.map((r) => mapFromRefundAlternativeResponse(r)) ?? [];
        this.paymentAlternatives = resp.paymentAlternatives?.map((p) => mapFromPaymentAlternativeResponse(p)) ?? [];
        this.paymentReference = resp.paymentReference ?? '';
        this.sections = resp.sections.map((p) => mapFromBasketSectionResponse(p));
        this.currency = resp.currency;
        this.account = resp.account ? new AgentAccount(resp.account) : undefined;

        this.isPayment = resp.isPayment;
        this.isRefund = resp.isRefund;
        this.showGiftCard = resp.showGiftCard;

        this.postInvoicing = !!resp.account && !resp.paymentAlternatives?.length;
    }
}
