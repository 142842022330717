import { departureRoutes } from "@/config/routes";
import { getCurrency } from "@/helpers/priceHelpers";
import { Direction, TripType } from "@/models/Enums";
import { BookingRoute } from "@/models/api/BookingRoute";
import { OpenBookingResult } from "@/models/api/OpenBookingResult";
import { CurrentCampaign } from "@/models/front/CurrentCampaign";
import { Passenger } from "@/models/front/Passenger";
import { Vehicle } from "@/models/front/Vehicle";
import { BookingPart, BookingState, PassengerState } from "@/models/store/BookingState";
import { defineStore } from "pinia";
import { useDepartureStore } from "./departure";
import { usePassengerStore } from "./passenger";
import { useResourceStore } from "./resource";
import { useResourceSplitStore } from "./resourceSplit";
import { useSeatingStore } from "./seatingStore";
import { useUserStore } from "./user";
import { useVehicleStore } from "./vehicle";
import { storageWithExpiration } from "@/helpers/storageHelper";
import { useTransferStore } from "@/store/transferStore";
const locale = document.getElementsByTagName("html")[0].getAttribute("lang");

const getInitialState = () => {
    const userStore = useUserStore();
    return {
        productCode: "",
        bookingNumber: -1,
        isAmendment: false,
        accessToken: "",
        campaign: {
            title: "",
            description: "",
            couponCode: "",
        },
        differentDetailsReturn: false,
        tripType: TripType.Return,
        outward: {
            route: userStore.getUser.isIslander ? departureRoutes.VINY : departureRoutes.NYVI,
            date: undefined,
            isLocked: false,
        } as BookingPart,
        return: {
            route: userStore.getUser.isIslander ? departureRoutes.NYVI : departureRoutes.VINY,
            date: undefined,
            isLocked: false,
        } as BookingPart,
    } as BookingState;
};

export const useBookingStore = defineStore("booking", {
    state: () => getInitialState(),
    getters: {
        getBookingNumber(): number {
            return this.bookingNumber;
        },
        getIsAmending(): boolean {
            return this.isAmendment;
        },
        getBooking(state): BookingState {
            return state as BookingState;
        },
        getRoute:
            (state) =>
            (direction: Direction): string => {
                if (direction === Direction.Outward) {
                    return state.outward.route;
                }
                return state.return.route;
            },
        getDate:
            (state) =>
            (direction: Direction): Date | undefined => {
                if (direction === Direction.Outward) {
                    return state.outward.date ? new Date(state.outward.date as Date) : undefined;
                }
                return state.return.date ? new Date(state.return.date as Date) : undefined;
            },
        getDifferentDetailsReturn(): boolean {
            return this.differentDetailsReturn;
        },
        getProductCode(): string {
            return this.productCode;
        },
        getCampaign(): CurrentCampaign {
            return this.campaign;
        },
        hasCampaign(): boolean {
            return !!this.campaign.description;
        },
        getCurrency(): string {
            return getCurrency(locale ?? "sv");
        },
        getTrips(): Direction[] {
            const trips = [];

            if (!this.outward.isLocked) {
                trips.push(Direction.Outward);
            }

            if (this.tripType === TripType.Return) {
                trips.push(Direction.Return);
            }

            return trips;
        },
        getIsLocked:
            (state) =>
            (direction: Direction): boolean => {
                if (direction === Direction.Outward) {
                    return state.outward.isLocked ?? false;
                }
                return state.return.isLocked ?? false;
            },
        getAccessToken: (state) => {
            return state.accessToken;
        },
    },
    actions: {
        setBookingNumber(bookingNumber: number) {
            this.bookingNumber = bookingNumber;
        },
        setIsAmendment(isAmendment: boolean) {
            this.isAmendment = isAmendment;
        },
        setTripType(tripType: TripType) {
            this.tripType = tripType;
            if(tripType === TripType.Single){
                this.setDate(Direction.Return, undefined)
            }
        },
        setRoute(direction: Direction, route: string) {
            if (direction === Direction.Outward) {
                this.outward.route = route;
            } else {
                this.return.route = route;
            }
        },
        setDate(direction: Direction, date?: Date) {
            if (direction === Direction.Outward) {
                this.outward.date = date;
            } else {
                this.return.date = date;
            }
        },
        setIsDifferentDetails(isDifferentDetailsReturn: boolean) {
            this.differentDetailsReturn = isDifferentDetailsReturn;
        },
        setProductCode(productCode: string) {
            this.productCode = productCode;
        },
        setCampaign(campaign: CurrentCampaign) {
            this.campaign = campaign;
        },
        setAccessToken(accessToken: string) {
            this.accessToken = accessToken;
        },
        setOutwardDetailsOnReturn() {
            const passengerStore = usePassengerStore();
            const vehicleStore = useVehicleStore();
            const outwardPassengers = passengerStore.getPassengers(Direction.Outward);
            const returnPassengers = passengerStore.getPassengers(Direction.Return);

            const newPassengers: PassengerState[] = [];
            for (let i = 0; i < returnPassengers.length; i++) {
                newPassengers.push({
                    ...outwardPassengers[i],
                    type: returnPassengers[i].type,
                    number: returnPassengers[i].number,
                    id: returnPassengers[i].id,
                });
            }
            passengerStore.setPassengers(Direction.Return, newPassengers);
            vehicleStore.setVehicle(Direction.Return, vehicleStore.getVehicle(Direction.Outward));
        },
        setFromApi(data: OpenBookingResult, isAmendment = false) {
            const routes = data.routes.map((r) => new BookingRoute(r));

            if (!routes.length) return;

            const outwardRoute = routes[0];
            this.outward.route = outwardRoute.route;
            this.outward.date = outwardRoute.departureDate;
            this.outward.isLocked = outwardRoute.isLocked;
            this.tripType = TripType.Single;

            if (routes.length > 1) {
                const returnRoute = routes[1];
                this.return.route = returnRoute.route;
                this.return.date = returnRoute.departureDate;
                this.return.isLocked = returnRoute.isLocked;
                this.tripType = TripType.Return;
            }

            if (this.outward.isLocked || this.return.isLocked) {
                this.differentDetailsReturn = true;
            }

            if (data.campaign) {
                this.campaign.title = data.campaign.name;
                this.campaign.description = data.campaign.description;
            }

            this.isAmendment = isAmendment;
            this.bookingNumber = data.bookingNumber;
            this.productCode = data.productCode;

            usePassengerStore().setFromApi(data.passengers.map((p) => new Passenger(p)));
            useVehicleStore().setFromApi(data.vehicles.map((p) => new Vehicle(p)));
            useDepartureStore().setFromApi(routes);
            useResourceSplitStore().setFromApi(routes);
        },
        resetBookingState() {
            usePassengerStore().$reset();
            useDepartureStore().$reset();
            useResourceStore().$reset();
            useVehicleStore().$reset();
            useResourceSplitStore().$reset();
            useSeatingStore().$reset();
            useTransferStore().$reset();
            this.$reset();
        },
    },
    persist: {
        storage: storageWithExpiration(localStorage),
    },
});
