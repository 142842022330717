<template>
	<section class="heading">
		<h1 v-if="title" class="heading__title">{{title}}</h1>
		<div v-if="subtitle" class="heading__subtitle">
			<h2>{{subtitle}}</h2>
		</div>
	</section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		title: {
			type: String,
			required: false,
		},
		subtitle: {
			type: String,
			required: false,
		},
	},
	setup() {
		return {};
	},
});
</script>

<style lang="scss" scoped>
.heading {
	padding-top: 0;
	text-align: center;
	overflow: hidden;

	&__title {
		padding-bottom: $space-sm;
		font-family: $font-title;
		font-size: $fs-32;
		text-transform: uppercase;
	}

	&__subtitle {
		padding-bottom: $space-sm;
		text-align: center;

		h2 {
			margin-bottom: 0;
			margin-top: 0;
			border-bottom: 0;
			padding: 4px 0;
			font-family: $font-body;
			font-size: $fs-16;
			font-weight: $fw-regular;

			@include media-breakpoint-up(sm) {
				font-size: $fs-18;
			}
		}
	}
}
</style>