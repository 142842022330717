import { defineStore } from "pinia";

type QueueState = {
    key: string;
    isQueue: boolean;
};

export const useQueueStore = defineStore("queue", {
    state: () => {
        return {
            key: "",
            isQueue: false,
        } as QueueState;
    },
    getters: {
        getKey(state) {
            return state.key;
        },
        getIsQueue(state) {
            return state.isQueue;
        },
    },
    actions: {
        setKey(key: string) {
            this.key = key;
        },
        setIsQueue(val: boolean) {
            this.isQueue = val;
        },
    },
    persist: {
        storage: sessionStorage,
    },
});
