<template>
    <div class="alert" role="alert" :class="[`alert--${type}`, { 'alert--no-margin': noMargin }]">
        <SvgIcon :icon="`alert/${type}`" />
        <span class="sr-only">{{ type }}</span>
        <div>
            <p>
                {{ message }}
            </p>
        </div>
        <button v-if="closable" @click="close" class="close">✕</button>
    </div>
</template>

<script lang="ts" setup>
import SvgIcon from "./SvgIcon.vue";

interface Props {
    message: string;
    type: string;
    closable?: boolean;
    id?: string;
    noMargin?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(["close"]);
const close = () => emit("close", props.id);
</script>

<style lang="scss" scoped>
@import "~/styles/icons.scss";

.sr-only {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.alert {
    display: flex;
    position: relative;
    margin: $space-md 0;
    padding: 1rem;
    background-color: var(--c-alert);
    color: var(--c-alert-content);
    border-radius: 0.5rem;

    p {
        font-family: $font-body;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-top: 0.125rem;
        margin-right: $space-lg;
    }

    .icon {
        display: inline;
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 1rem;
    }

    .close {
        position: absolute;
        right: $space-md;
        background: none;
        color: currentColor;
        cursor: pointer;
        opacity: 0.5;
        border: none;
        top: 1.125rem;
    }

    &--danger {
        background: #fef8f7;
        color: darken($c-red-dark, 10);
    }

    &--info {
        background: $c-blue-light;
        color: $c-blue-dark;
    }

    &--success {
        background: #f2f6e5;
        color: darken($c-green-100, 15);
    }

    &--no-margin {
        margin: 0;
    }
}
</style>
