<template>
    <BasePopper trigger="clickToOpen" placement="top">
        <template #default>
            <slot />
        </template>
        <template #content>
            <section class="forgot-password">
                <header>
                    <h4>{{ $t("login.forgotPassword.header") }}</h4>
                    <p>{{ $t("login.forgotPassword.body") }}</p>
                </header>
                <form @submit.prevent="submitForm">
                    <BaseInput name="email" label="Email" type="email" v-model="v$.email.$model" :hasError="v$.email.$error" />
                    <BaseMessages v-if="error" :messages="[error]" :messageType="BaseMessageType.Error" />
                    <BaseMessages v-if="success" :messages="[success]" :messageType="BaseMessageType.Success" />
                    <BaseButton :disabled="!!success" :loading="isLoading" class="btn--primary">
                        <span>{{ $t("button.send") }}</span>
                    </BaseButton>
                </form>
            </section>
        </template>
    </BasePopper>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/helpers/validators";
import { BaseMessageType } from "@/models/Enums";
import BaseMessages from "@/components/base/BaseMessages.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import BasePopper from "@/components/base/BasePopper.vue";
import useForgotPassword from "@/composables/login/useForgotPassword";
import useVuelidate from "@vuelidate/core";

const { sendForgotPassword, isLoading } = useForgotPassword();
const { t } = useI18n();
const success = ref();
const error = ref();

const state = reactive({
    email: "",
});

const rules = {
    email: {
        required,
    },
};

const v$ = useVuelidate(rules, state, { $stopPropagation: true });

const submitForm = async () => {
    v$.value.$touch();
    const valid = await v$.value.$validate();
    if (valid) {
        error.value = "";
        sendForgotPassword(state.email)
            .then(() => {
                success.value = t("login.forgotPassword.success");
            })
            .catch(() => (error.value = t("login.forgotPassword.error")));
    }
};
</script>

<style lang="scss" scoped>
.forgot-password {
    $prefix: ".forgot-password";

    form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }
}

:deep(.popper) {
    padding: 15px;
    background-color: $c-white-100;
    box-shadow: 0 8px 8px rgb($c-black-100, 15%);

    .messages {
        margin-top: 0;
    }
}
</style>
