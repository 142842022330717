import { storageWithExpiration } from "@/helpers/storageHelper";
import { BookingRoute } from "@/models/api/BookingRoute";
import { Direction } from "@/models/Enums";
import { DepartureState } from "@/models/store/BookingState";
import { defineStore } from "pinia";

const getInitialState = () => {
    return {
        outward: {
            id: "",
            departureId: "",
            identifier: "",
            ticketGroup: "",
            departureDate: undefined
        } as DepartureState,
        return: {
            id: "",
            departureId: "",
            identifier: "",
            ticketGroup: "",
            departureDate: undefined
        } as DepartureState,
    };
};

export const useDepartureStore = defineStore("departure", {
    state: () => {
        return getInitialState();
    },
    getters: {
        getDeparture:
            (state) =>
                (direction: Direction): DepartureState => {
                    if (direction === Direction.Outward) {
                        return state.outward;
                    } else {
                        return state.return;
                    }
                },
        getDepartureState: (state) => {
            return state;
        },
    },
    actions: {
        setDeparture(direction: Direction, departure: DepartureState) {
            if (direction === Direction.Outward) {
                return (this.outward = departure);
            } else {
                return (this.return = departure);
            }
        },
        setFromApi(routes: BookingRoute[]) {
            if (!routes.length) return;
            const outwardRoute = routes[0];
            this.outward.id = outwardRoute.id;
            this.outward.departureId = outwardRoute.departureId;
            this.outward.departureDate = outwardRoute.departureDate;
            this.outward.ticketGroup = outwardRoute.ticketGroup;

            if (routes.length > 1) {
                const returnRoute = routes[1];
                this.return.id = returnRoute.id;
                this.return.departureId = returnRoute.departureId;
                this.return.departureDate = returnRoute.departureDate;
                this.return.ticketGroup = returnRoute.ticketGroup;
            }
        },
    },
    persist: {
        storage: storageWithExpiration(localStorage),
    },
});
