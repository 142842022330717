<template>
		<BaseQuantity
			:id="Math.random.toString()"
			:name="Math.random.toString()"
			:minValue="0"
			:maxValue="17"
			v-model="childAge"
		/>
</template>

<script lang="ts">
import {computed, defineComponent } from 'vue';
	import BaseQuantity from '@/components/base/BaseQuantity.vue';

export default defineComponent({
	components: {
		BaseQuantity
	},
	props: {
		id: Number,
		age: {
			type: Number,
			default: 10
		}
	},
	setup(props, { emit }){
		const childAge = computed({
			get(): number {
				return props.age;
			},
			set(value: number) {
				emit('ageChanged', value, props.id)
			},
		});

		return {
			childAge
		}
	}
})
</script>

<style lang="scss">

</style>