<template>
    <MaintenanceWrapper hideAnimation id="booking-login" class="login-form">
        <form @submit.prevent="submitForm">
            <header>
                <h4>{{ $t("login.form.agentLogin.title") }}</h4>
                <p>{{ $t("login.form.agentLogin.subtitle") }}</p>
            </header>
            <div class="form__group">
                <div class="row">
                    <div class="col--12 col-sm--6 col-md--4">
                        <BaseInput
                            :label="$t('login.form.agentEmail')"
                            type="username"
                            name="username"
                            :hasError="v$.userName.$error"
                            :errors="v$.userName.$errors"
                            v-model.trim="v$.userName.$model"
                        />
                    </div>
                    <div class="col--12 col-sm--6 col-md--3">
                        <BaseInput
                            :label="$t('login.form.password')"
                            type="password"
                            name="password"
                            :hasError="v$.password.$error"
                            :errors="v$.password.$errors"
                            v-model.trim="v$.password.$model"
                        />
                    </div>
                    <div class="col--12 col-md--5">
                        <div class="button-container">
                            <BaseButton :loading="isLoading" :disabled="isLoading" class="btn--fixed-width btn--secondary">
                                {{ $t("button.login") }}
                            </BaseButton>
                            <LoginForgotPassword>
                                <a href="#" class="link">{{ $t("login.form.forgottenPassword") }}</a>
                            </LoginForgotPassword>
                        </div>
                    </div>
                    <div class="col--12 col-sm--12 col-md--7" v-if="loginAgentError?.length > 0">
                        <BaseMessages :messages="[loginAgentError]" />
                    </div>
                </div>
            </div>
        </form>
    </MaintenanceWrapper>
</template>

<script lang="ts" setup>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseMessages from "@/components/base/BaseMessages.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import useLogin from "@/composables/login/useLogin";
import useVuelidate from "@vuelidate/core";
import { required } from "@/helpers/validators";
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import LoginForgotPassword from "./LoginForgotPassword.vue";
import MaintenanceWrapper from "@/components/maintenance/MaintenanceWrapper.vue";

const { agentLogin, isLoading } = useLogin();
const { t } = useI18n();

const state = reactive({
    userName: "",
    password: "",
});

const rules = {
    userName: { required },
    password: { required },
};

const v$ = useVuelidate(rules, state);

const loginAgentError = ref<string>("");

const submitForm = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    agentLogin(state.userName, state.password, true)
        .then((resp) => {
            if (process.env.NODE_ENV === "production") {
                window.location.href = resp.url;
            } else {
                window.location.href = "/my-pages";
            }
        })
        .catch((err) => (loginAgentError.value = t(`errors.${err.response.data.code}`)));
};
</script>

<style lang="scss" scoped>
@import "~/styles/components/login-form.scss";
</style>
