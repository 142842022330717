import i18n from "@/config/i18n";

const locale = i18n.global.locale;

export const getCurrency = (locale: string) => {
    return "SEK"; // always default to SEK
}

export const formatPrice = (price?: number, currency?: string) => {
    if (typeof price === 'undefined') return "-";

    const curr = currency ?? getCurrency(locale.value);

    if (curr === "EUR") {
        return `${getCurrencySymbol(curr)}${price}`
    }
    return `${price}${getCurrencySymbol(curr)}`
}

export const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case "EUR":
            return '€';
        default:
            return ':-';
    }
}