<template>
    <form @submit.prevent="submitForm()" class="search">
        <BaseInput
            @keydown.down.prevent="activateNext(-1)"
            @click="clearSelectedItem()"
            ref="searchInput"
            tabindex="0"
            v-model="searchQuery"
            autofocus
        />
        <BaseButton class="btn--secondary" tabindex="0" :loading="isFetching">
            <SvgIcon :icon="'search/outline'" />
        </BaseButton>
        <ul v-if="(data?.results?.length ?? 0) > 0 && showDropdown && searchQuery.length > 2">
            <li v-for="(item, index) in siteSearchResults" :key="item.id">
                <a
                    class="item"
                    :href="item.url"
                    @keydown.up.prevent="activatePrevious(index)"
                    @keydown.down.prevent="activateNext(index)"
                    :tabindex="isSelected(index) ? 0 : -1"
                    :ref="(el) => setElements(el, index)"
                    :class="{ active: isSelected(index) }"
                >
                    {{ item.title }}
                    <SvgIcon :icon="'search/arrow-right'" />
                </a>
            </li>
            <li
                @keydown.enter.prevent="submitForm()"
                @keydown.space.prevent="submitForm()"
                @click="submitForm()"
                @keydown.up.prevent="activatePrevious(siteSearchResults.length)"
                :tabindex="isSelected(siteSearchResults.length) ? 0 : -1"
                :ref="(el) => setElements(el, siteSearchResults.length)"
                :class="{ active: isSelected(siteSearchResults.length) }"
            >
                <a class="more">
                    <span class="title">{{ $t("siteSearch.showMore") }}</span>
                </a>
            </li>
        </ul>
    </form>
</template>

<script lang="ts" setup>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import useSiteSearch from "@/composables/useSiteSearch";
import { getQueryParamValue } from "@/helpers/urlHelper";
import { useSiteSearchStore } from "@/store/siteSearch";
import { watchDebounced } from "@vueuse/core";
import { computed, onBeforeUpdate, onMounted, ref } from "vue";

interface Props {
    redirectToPage?: boolean;
    showDropdown?: boolean;
}

const props = defineProps<Props>();

const siteSearchStore = useSiteSearchStore();
const { goToPage, data, isFetching } = useSiteSearch();
const activeIndex = ref<number>();

// used for selecting focus
// on elements
const searchInput = ref();

const siteSearchResults = computed(() => data.value?.results.slice(0, 8) ?? []);

// used for reset selected item if
// user clicks in input field again
const clearSelectedItem = () => {
    activeIndex.value = undefined;
};

const activateNext = (index: number) => {
    activeIndex.value = (index ?? -1) + 1;
    const nextElement = elements.value[activeIndex.value];
    if (nextElement) {
        nextElement.focus();
    }
};

const activatePrevious = (index: number) => {
    activeIndex.value = index - 1;
    const nextElement = elements.value[activeIndex.value];
    if (nextElement) {
        nextElement.focus();
    } else {
        searchInput.value.focus();
    }
};

const isSelected = (index: number) => activeIndex.value === index;

const elements = ref<HTMLElement[]>([]);

const setElements = (el: HTMLElement, index: number) => {
    elements.value[index] = el;
};

onBeforeUpdate(() => (elements.value = []));

const searchQuery = ref(siteSearchStore.getQuery);

watchDebounced(
    searchQuery,
    (value) => {
        siteSearchStore.setQuery(value);
        siteSearchStore.setPage(1);
    },
    { debounce: 100 }
);

const submitForm = () => {
    if (props.redirectToPage) {
        goToPage();
    }
};

onMounted(() => {
    const query = getQueryParamValue("query");
    if (query) {
        searchQuery.value = query;
    }
});
</script>

<style lang="scss" scoped>
.search {
    position: relative;
    max-width: 20rem;
    border-radius: 4px;

    ul {
        left: 0;
        right: 0;
        position: absolute;
        display: block;
        border-radius: 4px;
        margin-top: $space-xs;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

        li,
        a {
            &:focus {
                outline: 0 none;
                outline-offset: 0;
                background: $c-gray;
            }
        }

        li {
            padding: $space-xs 0 $space-xs;
            background: $c-white;
            width: 100%;
            height: 3rem;
            border-top: 2px solid $c-gray-dark;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover,
            &.active {
                cursor: pointer;
                background: $c-gray;
            }

            &:last-of-type {
                &:hover,
                &.active {
                    cursor: pointer;
                    background: $c-gray;
                }

                height: 4rem;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            .item {
                padding: 0 $space-md 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 4px;
                color: $c-black;
                text-align: left;

                :deep() svg.icon {
                    color: $c-blue;
                }
            }

            .more {
                display: flex;
                justify-content: center;
                align-items: center;
                background: none;
                border: none;
                height: 100%;
                width: 100%;
                cursor: pointer;

                .title {
                    text-decoration: underline;
                }
            }
        }
    }

    .btn--secondary {
        position: absolute;
        top: 0;
        bottom: 0;
        left: auto;
        right: 0;
        border-radius: 0;
        height: 100%;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;

        :deep(.icon) {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
}
</style>
