import i18n from "@/config/i18n";
import { PassengerType } from "@/models/Enums";
import { PassengerState } from "@/models/store/BookingState";
import * as validators from "@vuelidate/validators";
import { addHours, startOfDay } from "date-fns";
import { today } from "./dateHelpers";

const { createI18nMessage } = validators;
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

export const required = withI18nMessage(validators.required);
export const integer = withI18nMessage(validators.integer);
export const email = withI18nMessage(validators.email);
export const numeric = withI18nMessage(validators.numeric);
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true });
export const minLength = withI18nMessage(validators.minLength, { withArguments: true });

const nonAdultPassengerTypes = [
    PassengerType.Infant, 
    PassengerType.Child, 
    PassengerType.InfantIslander, 
    PassengerType.ChildIslander,
    PassengerType.Teen,
    PassengerType.TeenIslander
]

const childPassengerTypes = [
    PassengerType.Infant, 
    PassengerType.Child, 
    PassengerType.InfantIslander, 
    PassengerType.ChildIslander,
];

const teenPassengerTypes = [
    PassengerType.Teen,
    PassengerType.TeenIslander
];

export const hasAdultPassenger = (passengers: PassengerState[]) => {
    return passengers.filter((p) => !nonAdultPassengerTypes.includes(p.type)).length > 0;
};

export const hasChildPassenger = (passengers: PassengerState[]) => {
    return passengers.filter((p) => childPassengerTypes.includes(p.type)).length > 0;
};

export const hasTeenPassenger = (passengers: PassengerState[]) => {
    return passengers.filter((p) => teenPassengerTypes.includes(p.type)).length > 0;
};

export const passengersRequired = (passengers: PassengerState[]) => {
    return passengers.length > 0;
};

export const outwardDateRequired = (outwardDate?: Date) => {
    return !!outwardDate && outwardDate >= today();
};

export const returnDateRequired = (isReturn: boolean, returnDate?: Date, outwardDate?: Date) => {
    return !isReturn || (!!returnDate && startOfDay(returnDate) >= startOfDay(outwardDate ?? new Date()));
};

export const dateOfBirthIsValid = (value: string, min: number, max: number, departureDate?: Date) => {
    const birthDate = new Date(value);
    const dateOfDeparture = departureDate ?? new Date();

    let diff = Math.abs(dateOfDeparture.getFullYear() - birthDate.getFullYear());
    // Check if date has passed
    const m = dateOfDeparture.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && dateOfDeparture.getDate() < birthDate.getDate())) {
        diff--;
    }

    return diff >= (min ?? 0) && diff <= (max ?? 150);
};

export function validGreeting(greeting: string) {
    if (greeting.length > 20) return false;
    const validGreeting = new RegExp('^[a-öA-Ö0-9!@#$%^&*(),. ?":{}|<>]*$');
    return validGreeting.test(greeting);
}

export function validName(greeting: string) {
    const validGreeting = new RegExp("^[a-öA-Ö]");
    return validGreeting.test(greeting);
}

export function validCardNumber(cardnumber?: string) {
    return !cardnumber || cardnumber.replace(/\s/g, "").length == 19;
}

export function validCvc(cvc?: string) {
    return !cvc || cvc.length == 3;
}

export function returnAfterOutward(outwardDepartureDate?: Date, returnDepartureDate?: Date) {
    // Not yet chosen or single
    if (!outwardDepartureDate || !returnDepartureDate) {
        return true;
    }

    // Add 3.5 hours to departure date, so ship has time to arrive
    return addHours(new Date(outwardDepartureDate), 3.5).getTime() < new Date(returnDepartureDate).getTime();
}

export function seatsForAllOrNone(passengers: PassengerState[], seats: string[]) {
    const selectedSeats = seats.filter((s) => s !== "?");
    return selectedSeats.length === 0 || passengers.length === selectedSeats.length;
}
