import { getPhoneCode, getPhoneWithoutCode } from "@/helpers/phoneHelper";
import { UserType } from "@/models/Enums";
import { AgentAccount } from "@/models/front/agent/AgentAccount";
import { AddressInformation } from "@/models/front/customer/AddressInformation";
import { User } from "@/models/store/User";
import { UserState } from "@/models/store/UserStore";
import { defineStore } from "pinia";
import { useBookingStore } from "./booking";
import { storageWithExpiration } from "@/helpers/storageHelper";

const getInitialState = () => {
    return {
        user: {
            type: UserType.Unknown,
        },
    } as UserState;
};

export const useUserStore = defineStore("user", {
    state: () => {
        return getInitialState();
    },
    getters: {
        getUserType(): UserType {
            return this.user.type;
        },
        isLoggedIn(): boolean {
            return this.user.type !== UserType.Unknown;
        },
        getUser(): User {
            return this.user;
        },
        getUserAddress(): AddressInformation {
            return {
                address: this.user.address ?? "",
                contact: this.user.contact ?? "",
                city: this.user.city ?? "",
                countryCode: this.user.country ?? "",
                email: this.user.email ?? "",
                firstName: this.user.firstName ?? "",
                lastName: this.user.lastName ?? "",
                mobilePhone: getPhoneWithoutCode(this.user.mobilePhone),
                phoneCode: getPhoneCode(this.user.mobilePhone),
                internalReference: "",
                zip: this.user.zip ?? "",
            };
        },
        isAgent(): boolean {
            return this.user.type === UserType.Agent;
        },
        getSelectedProductCode(): string | undefined {
            return this.user.selectedProductCode;
        },
        getAgentAccount(): AgentAccount | undefined {
            return this.user.agentAccount;
        },
        getIsNameReadonly(): boolean {
            return !!this.user.isCardCustomer;
        },
    },
    actions: {
        login(user: User) {
            this.user = user;
            const bookingStore = useBookingStore();
            bookingStore.setProductCode(user.defaultProductCode ?? "");
        },
        logout() {
            this.$state = getInitialState();
        },
        setSelectedProductCode(productCode?: string) {
            this.user.selectedProductCode = productCode;
        },
    },
    persist: {
        storage: storageWithExpiration(localStorage),
        paths: ["user"],
    },
});
