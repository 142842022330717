<template>
    <div class="cancellation">
        <div v-if="success" class="success">
            <BaseAlert type="success" :message="$t('myPages.myBookingCancel.bookingCancelled')" />
        </div>
        <div v-if="isLoading" class="cancellation__loading">
            <BaseSpinner color="dark" />
        </div>
        <div class="cancellation__sections" v-else-if="data">
            <BasketGroup
                v-for="section in data.sections"
                :key="section.route"
                :section="section"
                condensed
                :class="{ 'cancelled-route': section.isCancelled }"
            >
                <div class="cancellation-section" :class="{ danger: section.flexibility.isRefundable === false }">
                    <div class="type">
                        <strong>{{ section.flexibility.name }}</strong>
                        <template v-if="section.isCancelled">
                            -
                            {{
                                section.flexibility.isRefundable ? $t("myPages.myBookingCancel.refund") : $t("myPages.myBookingCancel.noRefund")
                            }}
                        </template>
                    </div>
                </div>
            </BasketGroup>
            <div class="summary">
                <div class="summary__paid">
                    {{ $t("myPages.myBookingCancel.amountPaid") }} <strong>{{ data.paid }}:-</strong>
                </div>
                <div class="summary__fees">
                    {{ $t("myPages.myBookingCancel.amountFees") }} <strong>{{ data.cancellationFee }}:-</strong>
                </div>
                <div class="summary__refund">
                    {{ $t("myPages.myBookingCancel.amountRefunded") }} <strong>{{ Math.abs(data.refund) }}:-</strong>
                </div>
            </div>
            <form @submit.prevent="confirm">
                <template v-if="isTravelCardRefund">
                    <p>{{ $t("myPages.myBookingCancel.refundToCard") }}</p>
                </template>
                <template v-else-if="data.refund < 0 && paymentAlternatives.length">
                    <p>
                        {{ $t("myPages.myBookingCancel.alternatives") }}
                    </p>
                    <PaymentOptions
                        class="payment-options"
                        v-model="v$.paymentMethod.$model"
                        :hasError="v$.paymentMethod.$error"
                        :paymentAlternatives="data.paymentAlternatives"
                        :reference="data.paymentReference"
                    >
                        <template #default="{ option }">
                            <BankAccountRefund v-if="option.paymentMethod === 'BANK'" ref="bankRefundRef" />
                            <GiroRefund v-else-if="option.paymentMethod === 'GIRO'" ref="giroRefundRef" />
                        </template>
                    </PaymentOptions>
                </template>
                <BaseMessages :messages="[...new Set(v$.$errors.map((e) => e.$message))]" />
                <BaseButton :loading="isConfirming" :disabled="cancelConfirmed" class="btn--fixed-width confirm">
                    {{ $t("myPages.myBookingCancel.confirmCancel") }}
                </BaseButton>
            </form>
        </div>
        <BaseAlert v-if="error" type="danger" :message="error" />
        <BaseButton class="btn--link cancel" @click="emit('close')">{{ $t("myPages.myBookingCancel.abortCancellation") }}</BaseButton>
    </div>
</template>

<script lang="ts" setup>
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseMessages from "@/components/base/BaseMessages.vue";
import BaseSpinner from "@/components/base/BaseSpinner.vue";
import BasketGroup from "@/components/basket/BasketGroup.vue";
import PaymentOptions from "@/components/payment/PaymentOptions.vue";
import BankAccountRefund from "@/components/refund/BankAccountRefund.vue";
import GiroRefund from "@/components/refund/GiroRefund.vue";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { reactive, ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import useCancelBooking from "../../composables/useCancelBooking";

interface Props {
    accessToken: string;
    route: string;
}

const props = defineProps<Props>();

const emit = defineEmits(["close"]);

const { t } = useI18n();

const state = reactive({
    paymentMethod: "",
});

const rules = {
    paymentMethod: {
        v: helpers.withMessage(
            () => t("validations.paymentMethodRequired"),
            (val: string) => !!val || data.value?.refund === 0
        ),
    },
};

const v$ = useVuelidate(rules, state);

const { prepareForCancellation, confirmCancellation, data, isLoading, isConfirming, error } = useCancelBooking(props.accessToken, props.route);

const isTravelCardRefund = ref(false);

prepareForCancellation().then((resp) => {
    if (resp) {
        isTravelCardRefund.value = resp.refund < 0 && resp.paymentAlternatives.every((pa) => pa.id === "RK");
        
        if (isTravelCardRefund.value) {
            state.paymentMethod = "RK";
        }
    }
});

const paymentAlternatives = computed(() => data.value?.paymentAlternatives?.filter((p) => p.id !== "RK") ?? []);

watch(data, () => {
    v$.value.$reset();
});

const success = ref(false);

const bankRefundRef = ref();
const giroRefundRef = ref();

const cancelConfirmed = ref(false);

const confirm = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    let params = {};
    if (bankRefundRef.value) {
        params = bankRefundRef.value.state;
    }

    if (giroRefundRef.value) {
        params = giroRefundRef.value.state;
    }

    confirmCancellation({
        refundMethod: state.paymentMethod,
        ...params,
    })
        .then(() => {
            success.value = true;
            setTimeout(() => {
                emit("close");
            }, 2000);
        })
        .finally(() => {
            cancelConfirmed.value = true;
        });
};
</script>

<style lang="scss" scoped>
.cancellation {
    position: relative;
    padding: 20px 15px 0px;
    &__loading {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__sections {
        :deep(.alert) {
            margin-top: 0;
        }

        :deep(.basket-group) {
            border-top: none;
            border: 1px solid $c-gray-100;
            padding: 10px;
            border-radius: 4px;
        }

        .cancelled-route {
            border-color: $c-red-dark;
            background-color: #fef8f7;
        }
    }

    &-section {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        max-width: 350px;
    }

    .success {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: $c-white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        border-radius: 6px;

        > :first-child {
            width: 100%;
        }
    }

    .summary {
        padding-top: 15px;
        padding-bottom: 10px;

        > * {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        &__refund {
            border-top: 1px solid $c-gray-100;
            font-size: $fs-18;
            padding-top: 10px;
        }
    }

    .payment-options {
        position: relative;
        padding-bottom: 20px;
    }

    .cancel,
    .confirm {
        display: block;
        margin: 0 auto;
        margin-top: 15px;
    }
}
</style>
