export const viewBoxes: Record<string, string> = {
	"booking/360": "0 0 100 35",
	"booking/alert-outline": "0 0 100 100",
	"booking/alert": "0 0 100 100",
	"booking/arrows-round-trip": "0 0 100 29",
	"booking/arrows-single-trip": "0 0 100 29",
	"booking/avatar": "0 0 100 92",
	"booking/avatars": "0 0 100 57",
	"booking/bed": "0 0 100 62",
	"booking/booking": "0 0 100 119",
	"booking/bus": "0 0 100 124",
	"booking/calendar": "0 0 100 88",
	"booking/car-full": "0 0 100 100",
	"booking/car": "0 0 100 91",
	"booking/card": "0 0 100 60",
	"booking/close-circle": "0 0 100 100",
	"booking/close": "0 0 100 100",
	"booking/conference": "0 0 100 60",
	"booking/daycabin": "0 0 100 70",
	"booking/delete": "0 0 100 126",
	"booking/details": "0 0 100 120",
	"booking/edit": "0 0 100 92",
	"booking/ferry": "0 0 100 38",
	"booking/food": "0 0 100 170",
	"booking/info": "0 0 100 100",
	"booking/link": "0 0 100 100",
	"booking/logout": "0 0 100 104",
	"booking/lounge-chair": "0 0 100 126",
	"booking/person-full": "0 0 100 104",
	"booking/person": "0 0 100 347",
	"booking/pet": "0 0 100 90",
	"booking/point": "0 0 100 136",
	"booking/search": "0 0 100 103",
	"booking/settings": "0 0 100 87",
	"booking/shift": "0 0 100 101",
	"booking/ticket-outline": "0 0 100 100",
	"booking/ticket": "0 0 100 100",
	"booking/travellers": "0 0 100 108",
	"booking/trustly": "0 0 100 21",
	"chevron/chevron-down": "0 0 100 61",
};
