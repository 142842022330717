<template>
    <Maintenance v-if="serviceUnavailable" :hideAnimation="hideAnimation" />
    <div v-else>
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import useApi from "@/composables/useApi";
import { defineAsyncComponent } from "vue";

interface Props {
    hideAnimation?: boolean;
}

defineProps<Props>();

const Maintenance = defineAsyncComponent(() => import("./Maintenance.vue"));
const { serviceUnavailable } = useApi();
</script>

<style scoped></style>
