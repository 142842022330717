<template>
    <BaseDropdown
        :label="label"
        :options="countries"
        :name="name"
        :hasError="hasError"
        :errors="errors"
        :disabled="disabled"
        v-model="inputValue"
        :searchable="true"
        autocomplete="off"
    />
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import BaseDropdown from "~/components/base/BaseDropdown.vue";
import { countries } from "~/config/countries";

export default defineComponent({
    props: {
        name: String,
        modelValue: String,
        label: {
            type: String,
            required: false,
        },
        valid: {
            type: Boolean,
            required: false,
            default: true,
        },
        hasError: {
            type: Boolean,
            required: false,
        },
        errors: {
            type: Array,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    components: { BaseDropdown },
    setup(props, { emit }) {
        const inputValue = computed({
            get(): string {
                return props.modelValue ?? "";
            },
            set(value: string) {
                emit("update:modelValue", value);
            },
        });
        return { inputValue, countries };
    },
});
</script>

<style scoped></style>
