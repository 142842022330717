import { defineStore } from "pinia";

type SavedCardState = {
    selectedCard?: string;
    saveCard: boolean;
};

type CheckoutState = {
    savedCard: SavedCardState;
};

export const useCheckoutStore = defineStore("checkout", {
    state: () => {
        return {
            savedCard: {
                selectedCard: undefined,
                saveCard: false,
            },
        } as CheckoutState;
    },
    getters: {
        getSavedCard: (state) => {
            return state.savedCard;
        },
    },
    actions: {
        setSelectedCard(selectedCard?: string) {
            this.savedCard.selectedCard = selectedCard;
        },
        setSaveCard(saveCard: boolean) {
            this.savedCard.saveCard = saveCard;
        },
    },
});
