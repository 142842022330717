import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c37ae56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "radio-button" }
const _hoisted_2 = ["id", "name", "disabled", "value", "step"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "radio",
      id: _ctx.id,
      name: _ctx.name,
      disabled: _ctx.disabled,
      value: _ctx.value,
      step: _ctx.step,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      tabindex: "0"
    }, null, 8, _hoisted_2), [
      [_vModelRadio, _ctx.inputValue]
    ]),
    _createElementVNode("label", { for: _ctx.id }, [
      _renderSlot(_ctx.$slots, "default", { label: _ctx.label }, () => [
        _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
      ], true)
    ], 8, _hoisted_3)
  ]))
}