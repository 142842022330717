<template>
    <BookingSpacer />
    <BaseSection :title="$t('booking.checkout.title')" :subtitle="$t('booking.checkout.subtitle')" :infoText="infoText">
        <form v-if="!error" class="checkout" @submit.prevent="submitForm">
            <BasePanel v-if="data?.isPayment || isLoading" alignLeft :hasError="v$.paymentMethod.$error">
                <div class="checkout__to-pay">
                    <p>
                        {{ $t("bookingSummary.leftToPay") }}: <strong>{{ formatPrice(data?.leftToPay) }}</strong>
                    </p>
                    <BaseButton @click="openBasket" class="btn--secondary" type="button">{{ $t('booking.checkout.openBasket') }}</BaseButton>
                </div>
                <div v-if="!data?.postInvoicing" class="checkout__header">
                    <h3>{{ $t("booking.checkout.paymentMethod.subtitle") }}</h3>
                    <p @click="showPaymentServicesLightbox = true" v-html="$t('booking.checkout.paymentMethod.readMore')"></p>
                    <BaseLightbox
                        align="start"
                        :title="$t('messages.paymentServices.title')"
                        v-model="showPaymentServicesLightbox"
                        :maxWidth="500"
                    >
                        <div class="payment-services" v-html="$t('messages.paymentServices.body')"></div>
                    </BaseLightbox>
                </div>
                <PaymentOptions
                    v-model="v$.paymentMethod.$model"
                    :paymentAlternatives="data?.paymentAlternatives"
                    :hasError="v$.paymentMethod.$error"
                    :disabled="leftToPay <= 0"
                    :isLoading="isLoading"
                    :expectedNumber="4"
                />
                <div v-if="data?.postInvoicing">
                    <h4>{{ $t("booking.checkout.paymentMethod.postInvoicing") }}</h4>
                </div>
                <GiftcardSection v-if="data?.showGiftCard" v-model="giftcardsToUse" :leftToPay="data?.leftToPay ?? 0" />
                <div v-if="!data?.postInvoicing" class="checkout__info">
                    <p v-html="$t('booking.checkout.paymentMethod.info')"></p>
                </div>
            </BasePanel>
            <BasePanel v-else-if="data?.isRefund">
                <div class="checkout__to-refund">
                    <p>
                        {{ $t("bookingSummary.toBeRefunded") }}: <strong>{{ formatPrice(Math.abs(data?.leftToPay)) }}</strong>
                    </p>
                    <BaseButton @click="openBasket" class="btn--secondary" type="button">{{ $t('booking.checkout.openBasket') }}</BaseButton>
                </div>
                <div class="checkout__header">
                    <h4>{{ $t("booking.refund.refundMethod.subtitle") }}</h4>
                    <p v-html="$t('booking.checkout.paymentMethod.readMore')"></p>
                </div>
                <PaymentOptions
                    v-model="v$.paymentMethod.$model"
                    :paymentAlternatives="data?.refundAlternatives"
                    :hasError="v$.paymentMethod.$error"
                    :reference="data.paymentReference"
                >
                    <template #default="{ option }">
                        <BankAccountRefund v-if="option.paymentMethod === 'BANK'" ref="bankRefundRef" />
                        <GiroRefund v-else-if="option.paymentMethod === 'GIRO'" ref="giroRefundRef" />
                    </template>
                </PaymentOptions>
                <div class="checkout__info">
                    <p v-html="$t('booking.checkout.paymentMethod.info')"></p>
                </div>
            </BasePanel>
            <BasePanel alignLeft v-else>
                <div v-if="!isAgent" class="checkout__header">
                    <h4>{{ $t("booking.checkout.paymentMethod.paid") }}</h4>
                </div>
            </BasePanel>
            <BasePanel alignLeft :hasError="v$.termsAccepted.$error">
                <BaseCheckbox v-model="v$.termsAccepted.$model" class="terms" id="terms" name="terms">
                    <span>{{ $t("booking.checkout.paymentMethod.acceptTerms") }}</span>
                    <span v-html="$t('booking.checkout.paymentMethod.readTerms')"></span>
                </BaseCheckbox>
            </BasePanel>
            <BaseMessages class="booking-messages" :messages="[...new Set(v$.$errors.map((e) => e.$message))]" />
            <BaseAlert v-if="errorMessage" :message="errorMessage" type="danger" />
            <BookingContinue>
                <BaseButton :loading="isConfirming">
                    {{ leftToPay > 0 && !data?.postInvoicing ? $t("booking.checkout.pay") : $t("booking.checkout.submit") }}
                </BaseButton>
            </BookingContinue>
        </form>
        <BasePanel v-else>
            <BaseAlert :message="(error as Error).message" type="danger" />
        </BasePanel>
    </BaseSection>
</template>

<script lang="ts" setup>
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox.vue";
import BaseLightbox from "@/components/base/BaseLightbox.vue";
import BaseMessages from "@/components/base/BaseMessages.vue";
import BasePanel from "@/components/base/BasePanel.vue";
import BaseSection from "@/components/base/BaseSection.vue";
import PaymentOptions from "@/components/payment/PaymentOptions.vue";
import BankAccountRefund from "@/components/refund/BankAccountRefund.vue";
import GiroRefund from "@/components/refund/GiroRefund.vue";
import { getQueryParamValue } from "@/helpers/urlHelper";
import { GiftCardDetails } from "@/models/front/GiftCard";
import useCheckoutQuery, { InitPaymentParams } from "@/modules/BookingFlow/composables/useCheckoutQuery";
import { useCheckoutStore } from "@/store/checkoutStore";
import { useUserStore } from "@/store/user";
import useVuelidate from "@vuelidate/core";
import { helpers, sameAs } from "@vuelidate/validators";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import BookingContinue from "../BookingContinue.vue";
import BookingSpacer from "../BookingSpacer.vue";
import GiftcardSection from "./GiftcardSection.vue";
import { formatPrice } from "@/helpers/priceHelpers";
import { useEventBus } from "@vueuse/core";
import { openBookingSummaryKey } from "@/models/Symbols/BookingSummary";

interface Props {
    type?: string;
}

const props = withDefaults(defineProps<Props>(), { type: "payment" });

const id = getQueryParamValue("id");
const { data, initPaymentMutation, error, isLoading } = useCheckoutQuery(props.type, id);
const { error: paymentError, isLoading: isConfirming } = initPaymentMutation;
const { t } = useI18n();

const eventBus = useEventBus(openBookingSummaryKey);

const openBasket = () => {
    eventBus.emit(true);
};

const userStore = useUserStore();
const isAgent = computed(() => userStore.isAgent);

const infoText = computed(() => {
    if (data.value?.postInvoicing) {
        return t("booking.postInvoicing.info");
    }

    if (isRefund.value) {
        return t("booking.refund.info");
    } else {
        return t("booking.checkout.info");
    }
});

const giftcardsToUse = ref<GiftCardDetails[]>([]);

const errorMessage = computed(() => {
    const message = (paymentError?.value as Error)?.message;
    if (message) {
        return message;
    }

    const errorCode = getQueryParamValue("error");
    return errorCode ? t(`errors.${errorCode}`) : "";
});

const leftToPay = computed(() => {
    return (data.value?.leftToPay ?? 0) - giftcardsToUse.value.map((gc) => gc.balance ?? 0).reduce((a, b) => a + b, 0);
});

const isRefund = computed(() => {
    return (data.value?.leftToPay ?? 0) < 0 && !!data.value?.refundAlternatives.length;
});

const state = reactive({
    termsAccepted: false,
    paymentMethod: "",
});

const noNeedToPay = computed(() => {
    return (!isRefund.value && leftToPay.value <= 0) || (!data.value?.paymentAlternatives.length && isAgent.value);
});

const rules = {
    termsAccepted: { sameAs: helpers.withMessage(t("validations.acceptTerms"), sameAs(true)) },
    paymentMethod: {
        v: helpers.withMessage(
            () => (isRefund.value ? t("validations.refundMethodRequired") : t("validations.paymentMethodRequired")),
            (value: string) => value.length > 0 || noNeedToPay.value
        ),
    },
};

const v$ = useVuelidate(rules, state);
const checkoutStore = useCheckoutStore();

const bankRefundRef = ref();
const giroRefundRef = ref();

const submitForm = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    let params = {};

    if (bankRefundRef.value) {
        params = bankRefundRef.value.state;
    }

    if (giroRefundRef.value) {
        params = giroRefundRef.value.state;
    }

    const request: InitPaymentParams = {
        bookingNumber: data.value?.bookingNumber ?? -1,
        terms: state.termsAccepted,
        type: props.type,
        giftCards: giftcardsToUse.value.map((g) => ({
            cvc: g.cvc,
            cardNumber: g.pan,
        })),
        creditCard: checkoutStore.getSavedCard,
    };

    if (isRefund.value && state.paymentMethod) {
        request.refund = { refundMethod: state.paymentMethod, ...params };
    } else if (leftToPay.value > 0) {
        request.paymentMethod = state.paymentMethod;
    }

    initPaymentMutation.mutateAsync(request).then((resp) => {
        window.location.replace(resp.paymentUrl);
    });
};

const showPaymentServicesLightbox = ref(false);
</script>

<style lang="scss" scoped>
.checkout {
    $prefix: ".checkout";
    position: relative;

    &__to-pay,
    &__to-refund {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        column-gap: 20px;
        row-gap: 10px;
        margin-bottom: 20px;

        > p > strong {
            font-size: 1.125rem;
        }

        > .btn {
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    .terms {
        padding-left: $space-sm;

        > :deep(label) {
            gap: 5px;
        }

        span {
            + span {
                margin-left: 5px;
            }
        }

        @include media-breakpoint-down(sm) {
            padding-left: $space-xs;
            > :deep(label) {
                flex-direction: column;
                align-items: flex-start !important;
            }
        }
    }

    :deep(.section) {
        > .content {
            position: relative;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
            @include font(h4);
            text-transform: none;
        }

        @include media-breakpoint-up(sm) {
            flex-direction: initial;
        }
    }

    &__giftcard {
        margin-top: 30px;
    }

    &__info {
        margin-top: $space-xl;

        p {
            font-size: $fs-14;
            opacity: $o-disabled;
        }
    }
}

.payment-services {
    :deep(> p) {
        line-height: 1.5;

        + p {
            margin-top: 10px;
        }
    }
}
</style>
