import { MyBookingResponse } from "~/models/api/customer/MyBookingResponse";
import { BasketSection, mapFromBasketSectionResponse } from "../basket/BasketGroup";
import { PaymentInfo } from "../PaymentInfo";

export class MyBooking {
    bookingNumber: number;
    sections: BasketSection[];
    paymentInfo: PaymentInfo;
    confirmationUrl: string;
    voucherUrl?: string;
    accessToken: string;
    canCancel: boolean;
    canEdit: boolean;
    canPay: boolean;
    isCancelled: boolean;
    isPaymentBooking: boolean;

    constructor(resp: MyBookingResponse) {
        this.bookingNumber = resp.bookingNumber;
        this.sections = resp.sections.filter((s) => s.flexibility.name).map((s) => mapFromBasketSectionResponse(s));
        this.paymentInfo = new PaymentInfo(resp.paymentInfo);
        this.confirmationUrl = resp.confirmationUrl;
        this.voucherUrl = resp.voucherUrl;
        this.accessToken = resp.accessToken;
        this.canCancel = resp.canCancel;
        this.canEdit = resp.canEdit;
        this.canPay = resp.canPay;
        this.isCancelled = !resp.sections.length;
        this.isPaymentBooking = resp.isPaymentBooking;
    }
}
