<template>
	<div class="spinner">
		<div
			class="bounce1"
			:style="`background-color: ${colorCode}; width: ${size}px; height: ${size}px;`"
		></div>
		<div
			class="bounce2"
			:style="`background-color: ${colorCode}; width:  ${size}px; height: ${size}px;`"
		></div>
		<div
			class="bounce3"
			:style="`background-color: ${colorCode}; width:  ${size}px; height: ${size}px;`"
		></div>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
	props: {
		color: {
			type: String,
			default: "light",
		},
		size: {
			type: Number,
			default: 8,
		},
	},
	setup(props) {
		const colorCode = computed(() => {
			switch (props.color) {
				case "dark":
					return "#375d75";
				default:
					return "#fff";
			}
		});

		return { colorCode };
	},
});
</script>

<style scoped>
.spinner {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.spinner > div {
	width: 8px;
	height: 8px;
	background-color: #fff;
	margin: 2px;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
</style>