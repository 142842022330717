import { MessageType } from "@/models/Enums";
import { ref } from "vue";
import useApi from "./useApi";

type SendSmsRequest = {
    accessToken: string;
    phoneNumber: string;
    bookingNumber: number;
    type: string;
    message: string;
}

const useSendMessage = () => {
    const isLoading = ref(false);
    const { post } = useApi();

    const sendEmail = (bookingNumber: number, type: MessageType, message: string, replyTo: string): Promise<void> => {
        isLoading.value = true;
        return post<void>("message/mail", {
            bookingNumber,
            type,
            message,
            replyTo,
        }).then((resp) => resp.data)
            .finally(() => {
                isLoading.value = false;
            });
    }

    const sendSms = (req: SendSmsRequest): Promise<void> => {
        isLoading.value = true;
        return post<void>("message/sms", req).then((resp) => resp.data)
            .finally(() => {
                isLoading.value = false;
            });
    };

    return {
        isLoading,
        sendEmail,
        sendSms
    }

};

export default useSendMessage;