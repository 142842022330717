<template>
    <section class="section">
        <div class="section__header" v-if="title">
            <h1>{{ title }}</h1>
            <p v-if="subtitle && !subtitleTrigger" v-html="subtitle"></p>
            <p v-else-if="subtitle && subtitleTrigger">
                {{ subtitle }} <span @click="() => emit('event')">{{ subtitleTrigger }}</span>
            </p>
        </div>
        <div class="info" v-if="infoIcon || infoText || $slots['info']" :class="infoClass">
            <div class="default" v-if="!$slots['info']">
                <SvgIcon :icon="infoIcon" v-if="infoIcon" />
                <h2 v-if="infoText">{{ infoText }}</h2>
            </div>
            <slot name="info"></slot>
        </div>
        <div class="content" v-if="$slots.default">
            <slot></slot>
        </div>
    </section>
</template>

<script lang="ts" setup>
import SvgIcon from "@/components/base/SvgIcon.vue";

interface Props {
    title?: string;
    subtitle?: string;
    subtitleTrigger?: string;
    infoIcon?: string;
    infoText?: string;
    infoClass?: string;
}

defineProps<Props>();

const emit = defineEmits(["event"]);
</script>

<style lang="scss" scoped>
.section {
    $prefix: ".section";

    &__header {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 30px;

        p {
            margin-top: 5px;

            > a[href] {
                color: $c-black-100;
                text-decoration: underline;
            }

            > span {
                color: $c-black-100;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    > .info {
        display: flex;
        position: relative;
        margin-bottom: 20px;
        height: 70px;
        background-color: $c-blue-300;
        color: $c-white-100;

        > .default {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px var(--p-padding-sm);
            width: 100%;
            height: 100%;

            @include media-breakpoint-up(sm) {
                padding: 15px var(--p-padding-md);
            }

            h2 {
                @include font(h4);
                text-transform: none;
            }

            .icon {
                position: absolute;
                left: var(--p-offset-sm);
                height: 24px;
                transform: translateX(-50%);

                @include media-breakpoint-up(sm) {
                    left: var(--p-offset-md);
                    height: 28px;
                }
            }
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: var(--p-offset-sm);
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-top-color: $c-blue-300;
            transform: translateX(-50%);

            @include media-breakpoint-up(sm) {
                left: var(--p-offset-md);
            }
        }
    }

    + #{$prefix} {
        margin-top: 50px;
    }
}
</style>
