import { UserType } from "@/models/Enums";
import { useUserStore } from "@/store/user";
import { computed } from "vue";
import { useQuery } from "vue-query";
import useApi from "../useApi";

const useIsLoggedInQuery = () => {
    const { get } = useApi();

    const userStore = useUserStore();

    const isAgent = computed(() => userStore.isAgent);

    const isAgentLoggedIn = () => {
        return get<boolean>("agent/isLoggedIn", "");
    };

    const isCustomerLoggedIn = () => {
        return get<boolean>("customer/isLoggedIn", "");
    };

    const fetcher = (): Promise<Boolean> => {
        const promise = isAgent.value ? isAgentLoggedIn() : isCustomerLoggedIn();
        return promise.then(() => {
            return true;
        });
    };

    const cacheKey = computed(() => {
        return ["isLoggedIn", userStore.getUserType, userStore.getUser.email];
    });

    return {
        ...useQuery(cacheKey.value, fetcher, {
            staleTime: 1000 * 60,
            retry: false,
            enabled: computed(() => userStore.isLoggedIn && userStore.getUserType !== UserType.Booking),
        }),
    };
};

export default useIsLoggedInQuery;
