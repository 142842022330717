<template>
    <Popper ref="popperRef" @click="close" class="popper-container" v-bind="$attrs" :arrow="true">
        <slot />
        <template #content="props">
            <slot name="content" v-bind="props" />
        </template>
    </Popper>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import Popper from "vue3-popper";

const popperRef = ref();
const close = (event: any) => {
    if (event.target.classList.contains("popper")) {
        if (popperRef.value && popperRef.value.close) {
            popperRef.value.close();
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~/styles/icons.scss";
:deep(.popper) {
    background: #fff;
    padding: 16px;
    border-radius: 6px;
    color: $c-black;
    box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
    transition: background 250ms ease-in-out, width 250ms ease-in-out;
}

:deep(.popper #arrow::before) {
    background: $c-white;
    position: absolute;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
    position: absolute;
    background: $c-white;
}

.popper-container {
    font-family: $font-body;
    font-weight: $fw-medium;

    .popper-close {
        cursor: pointer;
        background-color: transparent;
        position: absolute;
        right: 0;
        width: 30px;
        height: 20px;
        top: 4px;
        color: $c-gray-dark;
    }
}
</style>
