import { parseDate } from "@/helpers/dateHelpers";
import { getDepartureId } from "@/helpers/idHelper";
import { BookingRouteDto } from "./OpenBookingResult";

export class BookingRoute {
    id: string;
    departureId: string;
    route: string;
    departureDate: Date;
    arrivalDate?: Date;
    isLocked: boolean;
    isSplit: boolean;
    ticketGroup: string;

    constructor(resp: BookingRouteDto) {
        this.id = resp.id;
        this.departureId = getDepartureId(resp.route, resp.departureDateTime);
        this.route = resp.route;
        this.departureDate = parseDate(resp.departureDateTime);
        this.arrivalDate = parseDate(resp.arrivalDateTime);
        this.isLocked = resp.isLocked ?? false;
        this.isSplit = resp.isSplit ?? false;
        this.ticketGroup = resp.ticketGroup;
    }
}