<template>
    <div class="quantity-input">
        <vue-number-input
            :id="id"
            :name="name"
            v-model="inputValue"
            :disabled="disabled"
            :min="minValue"
            :max="maxValue"
            controls
            :class="{ active: active }"
        />
    </div>
</template>

<script lang="ts" setup>
import VueNumberInput from "@chenfengyuan/vue-number-input";
import { computed } from "vue";

interface Props {
    id: any;
    name?: string;
    modelValue: number;
    minValue?: number;
    maxValue?: number;
    disabled?: boolean;
    active?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits(["update:modelValue"]);

const inputValue = computed({
    get(): number {
        return props.modelValue ?? 0;
    },
    set(value: number) {
        emit("update:modelValue", value);
    },
});
</script>

<style lang="scss" scoped>
.quantity-input {
    .vue-number-input {
        display: flex;
        align-items: center;
        border: none;

        &.active {
            :deep() .vue-number-input__input {
                background-color: $c-blue;
                color: $c-white;
            }
        }

        :deep(.vue-number-input__button) {
            top: 50%;
            width: 1.75rem;
            height: 1.75rem;
            padding: $space-sm;
            background-color: $c-white-100;
            border: 1px solid $c-gray-200;
            border-radius: 100%;
            transform: translate3d(0, -50%, 0);
            cursor: pointer;

            &::before {
                width: 12px;
                height: 2px;
                background-color: #111827 !important;
            }

            &::after {
                height: 12px;
                width: 2px;
                background-color: #111827 !important;
            }

            &:disabled {
                cursor: initial;
                opacity: 0.5;
            }

            .vue-number-input__button--minus {
                left: 0;
            }

            .vue-number-input__button--plus {
                right: 0;
            }
        }

        :deep(.vue-number-input__input) {
            padding: 1px 2rem !important;
            width: 90px;
            height: 1.75rem;
            background: none;
            border: none;
            text-align: center;
            border-radius: 50%;
            background-clip: content-box;
        }
    }
}
</style>
