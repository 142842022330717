<template>
    <div class="basket" :class="{ 'basket--sticky': isSticky }">
        <div class="basket__header" @click="isExpanded = !isExpanded">
            <div class="basket-title" :class="{ isAmendment }">
                <span>{{ $t("bookingSummary.title") }}</span>
                <span v-if="isAmendment"
                    ><b>{{ bookingNumber }}</b></span
                >
            </div>
            <div class="basket__summary">
                <BaseSpinner v-if="isFetching" />
                <div class="total" v-else-if="price">
                    <span>{{ $t("bookingSummary.total") }}</span>
                    <span class="total-price">
                        <b>{{ formatPrice(price - totalDiscount) }}</b>
                    </span>
                </div>
                <SvgIcon :icon="'chevron/chevron-down'" :class="{ active: isExpanded }" />
            </div>
        </div>
        <BasketTransitionExpand :expanded="isExpanded">
            <div class="basket__content">
                <BasePanel>
                    <div v-if="productCode" class="product-code">{{ productCode }}</div>
                    <BasketGroup v-for="section in combinedSections" :key="section.route" :section="section" />
                    <ul class="basket__total" v-if="price">
                        <li v-if="amd">
                            <span> {{ $t("bookingSummary.amd") }}</span>
                            <span> {{ formatPrice(amd) }}</span>
                        </li>
                        <li>
                            <span>{{ $t("bookingSummary.total") }}</span>
                            <span>
                                <b>{{ formatPrice(price - totalDiscount) }}</b>
                            </span>
                        </li>
                        <template v-if="isAmendment">
                            <template v-if="isCheckout">
                                <li>
                                    <span>{{ $t("bookingSummary.paid") }}: </span>
                                    <span>
                                        <b>{{ formatPrice(paid) }}</b>
                                    </span>
                                </li>
                                <li v-if="(leftToPay ?? 0) < 0">
                                    <span>{{ $t("bookingSummary.toBeRefunded") }}: </span>
                                    <span>
                                        <b>{{ formatPrice(Math.abs(leftToPay as number)) }}</b>
                                    </span>
                                </li>
                                <li v-else-if="(leftToPay ?? 0) > 0">
                                    <span>{{ $t("bookingSummary.leftToPay") }}: </span>
                                    <span>
                                        <b>{{ formatPrice(leftToPay) }}</b>
                                    </span>
                                </li>
                            </template>
                            <li v-else>
                                <span>{{ $t("bookingSummary.unknownpayment") }}</span>
                            </li>
                        </template>
                        <li v-else-if="leftToPay">
                            <span>{{ $t("bookingSummary.leftToPay") }}: </span>
                            <span>
                                <b>{{ formatPrice(leftToPay) }}</b>
                            </span>
                        </li>
                    </ul>
                </BasePanel>
                <slot name="after"></slot>
            </div>
        </BasketTransitionExpand>
    </div>
    <div class="basket__overlay" v-if="isExpanded" @click="isExpanded = !isExpanded"></div>
</template>

<script lang="ts" setup>
import BasePanel from "@/components/base/BasePanel.vue";
import BaseSpinner from "@/components/base/BaseSpinner.vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import BasketGroup from "@/components/basket/BasketGroup.vue";
import BasketTransitionExpand from "@/components/basket/BasketTransitionExpand.vue";
import { formatPrice } from "@/helpers/priceHelpers";
import { Direction } from "@/models/Enums";
import { openBookingSummaryKey } from "@/models/Symbols/BookingSummary";
import { BasketGroupType, BasketResource, BasketSection } from "@/models/front/basket/BasketGroup";
import { useBookingStore } from "@/store/booking";
import { usePassengerStore } from "@/store/passenger";
import { useVehicleStore } from "@/store/vehicle";
import { useEventBus } from "@vueuse/core";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Props {
    sections?: BasketSection[];
    isFetching: boolean;
    leftToPay?: number;
    paid?: number;
    total?: number;
    amd?: number;
    productCode?: string;
    isSticky?: boolean;
    isCheckout?: boolean;
    displayEmptySections?: boolean;
}

const props = defineProps<Props>();

const isExpanded = ref(false);

const eventBus = useEventBus(openBookingSummaryKey);

eventBus.on((e) => {
    isExpanded.value = e;
});

const totalDiscount = computed(() => {
    return props.sections?.reduce((sum, section) => sum + (section.discount ?? 0), 0) ?? 0;
});

const bookingStore = useBookingStore();
const passengerStore = usePassengerStore();
const vehicleStore = useVehicleStore();
const { t } = useI18n();

const bookingNumber = computed(() => bookingStore.getBookingNumber);

const isAmendment = computed(() => bookingStore.getBooking.isAmendment);

const getEmptySection = (d: Direction): BasketSection => {
    const passengers = !bookingStore.getDifferentDetailsReturn
        ? passengerStore.getPassengers(Direction.Outward)
        : passengerStore.getPassengers(d);

    const vehicle = !bookingStore.getDifferentDetailsReturn ? vehicleStore.getVehicle(Direction.Outward) : vehicleStore.getVehicle(d);

    const basketVehicle: BasketResource[] = [];
    if (vehicle.code && vehicle.amount > 0) {
        const label = vehicle.amount > 1 ? `${vehicle.amount} ` + t(`resources.${vehicle.code}.label`) : t(`resources.${vehicle.code}.label`);

        basketVehicle.push({
            name: label,
            description: "-",
            amount: vehicle.amount,
        });
    }

    return {
        route: bookingStore.getRoute(d),
        departureDateTime: bookingStore.getDate(d),
        flexibility: {
            name: "-",
            description: "-",
            amount: 1,
        },
        passengers: passengers.map((p) => ({
            name: t(`passengerType.${p.type}`),
            description: "-",
            amount: 1,
        })),
        vehicles: basketVehicle,
        food: [],
        other: [],
        type: BasketGroupType.Ferry,
    };
};

// Add outward/return if we do not have it from the API yet
const combinedSections = computed(() => {
    // create a shallow copy of "props.sections" since we cannot push to it otherwise
    const sections: BasketSection[] = [...(props.sections ?? [])];

    if (!props.displayEmptySections) return sections;

    const trips = bookingStore.getTrips;

    trips.forEach((d) => {
        if (sections.findIndex((s) => s.route === bookingStore.getRoute(d)) < 0) {
            const emptySection = getEmptySection(d);
            sections.push(emptySection);
        }
    });

    let filteredSections = sections;

    if (sections.filter((s) => s.type === BasketGroupType.Ferry).length > 2) {
        const routes = trips.map((t) => bookingStore.getRoute(t));
        filteredSections = sections.filter((s) => routes.includes(s.route));
    }

    return filteredSections.sort((a, b) => {
        return (a.departureDateTime?.getTime() ?? 0) - (b.departureDateTime?.getTime() ?? 0);
    });
});

const price = computed(() => {
    return combinedSections.value.reduce((sum, curr) => sum + (curr.price ?? 0), 0) + (props.amd ?? 0);
});
</script>

<style lang="scss" scoped>
.basket {
    $prefix: ".basket";
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    &#{$prefix}--sticky {
        @include media-breakpoint-up(lg) {
            position: sticky;
            padding-top: 0;
            top: 195px;
        }
    }

    @include media-breakpoint-up(lg) {
        position: relative;
        width: initial;
        height: initial;
        padding-top: $space-xl;
        z-index: 11;

        #{$prefix}__content {
            max-height: unset;
        }
    }

    &__summary {
        display: flex;
        gap: 30px;
        align-items: center;
    }

    .total {
        display: flex;
        gap: 5px;
        flex-flow: row wrap;
    }

    .product-code {
        line-height: 1.5rem;
        text-align: center;
        background-color: $c-blue-100;
        margin-bottom: $space-sm;
        margin-top: -$space-sm;
    }

    #{$prefix}__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 25px var(--p-padding-sm);
        width: 100%;
        background-color: $c-blue-300;
        color: $c-white-100;
        cursor: pointer;
        box-shadow: 0 2px 4px 0 rgba(14, 30, 37, 0.12), 0 2px 16px 0 rgba(14, 30, 37, 0.32);

        @include media-breakpoint-up(lg) {
            align-items: flex-end;
            padding: 15px var(--p-padding-md);
            height: var(--dg-basket-header-lg);
            cursor: initial;
            box-shadow: none;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: var(--p-offset-sm);
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-top-color: $c-blue-300;
            transform: translateX(-50%);

            @include media-breakpoint-up(sm) {
                left: var(--p-offset-md);
            }
        }

        > .basket-title {
            &.isAmendment {
                > span:first-child {
                    font-size: 0.875rem;
                }
            }
            > span {
                display: block;
            }
        }

        :deep(.spinner) {
            position: initial;
            transform: initial;
            height: 20px;
        }

        :deep(.icon) {
            width: 16px;
            transform: rotateZ(180deg);

            &.active {
                transform: rotateZ(0deg);
            }

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    #{$prefix}__content {
        overflow: auto;
        background-color: $c-white-100;

        @include media-breakpoint-down(md) {
            max-height: 65vh;
        }
    }

    #{$prefix}__total {
        display: flex;
        flex-direction: column;
        gap: $space-sm;
        border-top: 1px solid $c-gray-100;
        background-color: $c-white-100;
        padding: $space-md var(--p-padding-sm);
        margin: calc(var(--p-padding-sm) * -1);
        margin-top: $space-lg;
        position: sticky;
        bottom: 0;

        li {
            display: flex;
            justify-content: space-between;
        }

        @include media-breakpoint-up(md) {
            padding: $space-md var(--p-padding-md);
            margin: calc(var(--p-padding-md) * -1);
            margin-top: $space-lg;
        }

        > p {
            line-height: 1;
        }
    }

    > #{$prefix}__transition-expand {
        @include media-breakpoint-up(lg) {
            display: block !important;
            height: auto !important;
        }
    }
}

.basket__overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($c-black-100, 0.5);
    z-index: 10;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}
</style>
