import { ElasticSearch, ElasticSearchResponse } from "../api/SiteSearchResponse";

export class SiteSearch {
    results: SiteSearchItem[];
    page: number;
    resultCount: number;
    pageCount: number;

    constructor(response: ElasticSearchResponse) {
        this.page = response.meta.page.current;
        this.resultCount = response.meta.page.total_results;
        this.pageCount = response.meta.page.total_pages;
        this.results = response.results.map(r => new SiteSearchItem(r));
    }
}

export class SiteSearchItem {
    imageUrl?: string;
    body: string;
    description: string;
    title: string;
    urlSnippet: string;
    url: string;
    id: string;

    constructor(response: ElasticSearch) {
        this.body = response.body_content?.snippet ?? response.body_content?.raw;
        this.title = response.dg_title?.snippet ?? response.title?.raw;
        this.url = response.url?.raw;
        this.urlSnippet = response.url?.snippet;
        this.id = response.id?.raw;
        this.imageUrl = response.dg_image?.raw;
        this.description = response.dg_description?.snippet;
    }
}