<template>
    <div class="booking-box">
        <header>
            <slot name="header"></slot>
        </header>
        <slot></slot>
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
.booking-box {
    width: 100%;
    padding: 25px 15px 30px;
    background-color: $c-white-100;
    border-radius: $br-dialog;
    border: 1px solid $c-color-primary;
    border-top-left-radius: 0;
    margin-right: 10px;

    @include media-breakpoint-up(sm) {
        padding: 20px 30px;
    }
}
</style>