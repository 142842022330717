<template>
    <div class="giro-refund">
        <BaseInput :label="$t('fields.giroName.label')" v-model="v$.name.$model" :hasError="v$.name.$error" />
        <BaseInput :label="$t('fields.giroNumber.label')" v-model="v$.giro.$model" :hasError="v$.giro.$error" />
    </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import BaseInput from "../base/BaseInput.vue";
import { required } from "@/helpers/validators";
import useVuelidate from "@vuelidate/core";

const state = reactive({
    name: "",
    giro: "",
});

const rules = {
    name: {
        required,
    },
    giro: {
        required,
    },
};

const v$ = useVuelidate(rules, state);

defineExpose({
    state,
});
</script>

<style scoped lang="scss">
.giro-refund {
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
    padding: 10px 0;
}
</style>
