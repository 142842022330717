import { getLocaleString } from "@/helpers/localeHelper";
import countryListJson from "./countrylist.json";

export const countryList = countryListJson;

interface Country {
    label: TranslatedString;
    countryCode: string;
    phoneCode: string;
}

interface TranslatedString {
    en: string;
    de: string;
    sv: string;
}

export const countryCodes = countryListJson.map((c: Country) => ({
    label: `${c.label[getLocaleString() as keyof TranslatedString]} +${c.phoneCode}`,
    value: c.phoneCode
}))

export const countries = countryListJson.map((c: Country) => ({
    label: c.label[getLocaleString() as keyof TranslatedString],
    value: c.countryCode
}))