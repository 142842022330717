import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseDropdown = _resolveComponent("BaseDropdown")!

  return (_openBlock(), _createBlock(_component_BaseDropdown, {
    label: _ctx.label,
    options: _ctx.countries,
    name: _ctx.name,
    hasError: _ctx.hasError,
    errors: _ctx.errors,
    disabled: _ctx.disabled,
    modelValue: _ctx.inputValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
    searchable: true,
    autocomplete: "off"
  }, null, 8, ["label", "options", "name", "hasError", "errors", "disabled", "modelValue"]))
}