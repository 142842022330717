import { storageWithExpiration } from "@/helpers/storageHelper";
import { Direction } from "@/models/Enums";
import { BookingRoute } from "@/models/api/BookingRoute";
import { defineStore } from "pinia";

type SplitState = {
    outward: SplitPart;
    return: SplitPart;
};

type SplitPart = {
    isSplit: boolean;
    splitPassengerIds: string[];
};

export const useResourceSplitStore = defineStore("resourceSplit", {
    state: () => {
        return {
            outward: {
                isSplit: false,
                splitPassengerIds: [],
            },
            return: {
                isSplit: false,
                splitPassengerIds: [],
            },
        } as SplitState;
    },
    getters: {
        getIsSplit: (state) => (direction: Direction) => {
            if (direction === Direction.Outward) {
                return state.outward.isSplit;
            } else {
                return state.return.isSplit;
            }
        },
        getPassengerIds: (state) => (direction: Direction) => {
            if (direction === Direction.Outward) {
                return state.outward.splitPassengerIds;
            } else {
                return state.return.splitPassengerIds;
            }
        },
    },
    actions: {
        setFromApi(routes: BookingRoute[]) {
            if (!routes.length) return;

            const outwardRoute = routes[0];
            this.outward.isSplit = outwardRoute.isSplit;

            if (routes.length > 1) {
                const returnRoute = routes[1];
                this.return.isSplit = returnRoute.isSplit;
            } else {
                this.return.isSplit = false;
            }
        },
        setIsSplit(direction: Direction, isSplit: boolean) {
            if (direction === Direction.Outward) {
                return (this.outward.isSplit = isSplit);
            } else {
                return (this.return.isSplit = isSplit);
            }
        },
        setSplitPassengers(direction: Direction, passengerIds: string[]) {
            if (direction === Direction.Outward) {
                return (this.outward.splitPassengerIds = passengerIds);
            } else {
                return (this.return.splitPassengerIds = passengerIds);
            }
        },
    },
    persist: {
        storage: storageWithExpiration(localStorage),
    },
});
