import i18n from "@/config/i18n";
import { de, enGB, sv } from "date-fns/locale";

const locales = { en: enGB, sv, de };

export const getLocale = () => {
    switch (i18n.global.locale.value) {
        case "sv":
            return locales.sv;
        case "de":
            return locales.de;
        default:
            return locales.en;
    }
};

export const getLocaleString = () => {
    return i18n.global.locale.value;
};

export const getBookitLocaleStringForCurrentLocale = () => {
    switch (i18n.global.locale.value) {
        case "sv":
            return "SE";
        case "de":
            return "DE";
        default:
            return ""; 
    }
};

export const getPhoneCodeForCurrentLocale = () => {
    switch (i18n.global.locale.value) {
        case "sv":
            return "46";
        case "de":
            return "49";
        default:
            return ""; 
    }
};


export const translateIfExists = (key: string) => {
    return i18n.global.te(key) ? i18n.global.t(key) : undefined;
};