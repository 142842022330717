import i18n from "./i18n";


export const accommodationConfig = [
   {
      type: 0,
      multipleRooms: false,
   },
   {
      type: 34938,
      multipleRooms: true,

   },
   {
      type: 14865,
      multipleRooms: false,
   },
   {
      type: 4632,
      multipleRooms: false
   }
].map((o) => ({
   ...o,
   value: o.type,
   label: i18n.global.t(`onebasket.accommodation.types.${o.type}`)
}));