import useApi from "@/composables/useApi";
import { UserType } from "@/models/Enums";
import { CreditCardResponse } from "@/models/api/customer/CreditCardResponse";
import { CreditCard } from "@/models/front/customer/CreditCard";
import { useUserStore } from "@/store/user";
import { computed } from "vue";
import { useMutation, useQuery, useQueryClient } from "vue-query";

const useMyCreditCardsQuery = () => {
    const userStore = useUserStore();
    const { patch, get, del } = useApi();

    const queryClient = useQueryClient();

    const fetcher = () => {
        return get<CreditCardResponse[]>(`customer/creditcards`, "").then((resp) => resp.data?.map((d) => new CreditCard(d)) ?? []);
    };

    const updateMutation = useMutation(
        (req: CreditCardResponse) => {
            return patch(`customer/creditcards`, req);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("myCreditCards");
            },
        }
    );

    const deleteMutation = useMutation(
        (req: CreditCardResponse) => {
            return del(`customer/creditcards`, req);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("myCreditCards");
            },
        }
    );

    return {
        ...useQuery(["myCreditCards", userStore.getUser.number], fetcher, {
            staleTime: Infinity,
            enabled: computed(() => userStore.getUserType === UserType.Customer),
        }),
        updateMutation,
        deleteMutation,
    };
};

export default useMyCreditCardsQuery;
