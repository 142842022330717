<template>
    <BaseButton @click="open" class="btn--info" type="button">
        <SvgIcon :style="`height: ${size}px`" class="icon--info" icon="booking/info" />
    </BaseButton>
</template>

<script lang="ts" setup>
import useDialog from "@/composables/useDialog";
import { DialogType } from "@/models/Symbols/Dialog";
import { useI18n } from "vue-i18n";
import BaseButton from "./BaseButton.vue";
import SvgIcon from "./SvgIcon.vue";

interface Props {
    title: string;
    body: string;
    size?: number;
}

const props = defineProps<Props>();

const { t } = useI18n();
const { displayDialog } = useDialog();

const open = () => {
    displayDialog({
        title: props.title,
        message: props.body,
        yes: t("button.ok"),
        type: DialogType.info,
    });
};
</script>

<style scoped></style>
