import i18n from "@/config/i18n";
import { departureRoutes } from "@/config/routes";
import { parseDate } from "@/helpers/dateHelpers";
import { translateIfExists } from "@/helpers/localeHelper";
import { BookingDataType } from "@/models/Enums";
import { BasketDialogItemResponse, BasketResourceResponse, BasketSectionResponse } from "@/models/api/basket/BasketResponse";

type Basket = {
    bookingNumber: number;
    bookingSections: BasketSection[];
    leftToPay?: number;
    paid?: number;
    total?: number;
};

export type BasketSection = {
    price?: number;
    discount?: number;
    campaignName?: string;
    route: string;
    ship?: string;
    flexibility: BasketResource;
    arrivalDateTime?: Date;
    departureDateTime?: Date;
    passengers: BasketResource[];
    vehicles: BasketResource[];
    food: BasketResource[];
    other: BasketResource[];
    isCancelled?: boolean;
    isLocked?: boolean;
    type: BasketGroupType;
};

export type BasketResource = {
    name?: string;
    description: string;
    information?: string;
    price?: number;
    seat?: string;
    amount: number;
    isPrepaid?: boolean;
    isRefundable?: boolean;
};

export const mapFromBasketSectionResponse = (resp: BasketSectionResponse): BasketSection => {
    // Determine if the route is a bus transfer route by checking that it doesn't match any known departureRoutes

    return {
        price: resp.price,
        route: resp.route,
        discount: resp.discount,
        campaignName: resp.campaginName,
        arrivalDateTime: resp.arrivalDateTime ? parseDate(resp.arrivalDateTime) : undefined,
        departureDateTime: parseDate(resp.departureDateTime),
        ship: i18n.global.t(`ships.${resp.ship}`),
        flexibility: mapFromBasketResourceResponse(resp.flexibility),
        passengers: getItems(resp.passengers ?? []),
        vehicles: getItems(resp.vehicles ?? [], BookingDataType.Vehicle),
        food: getItems(resp?.food ?? []),
        other: getItems(resp.other?.filter((o) => o.name !== "FLEX").concat(resp.transfer ?? []) ?? [], undefined, getBasketGroupType(resp)),
        isLocked: resp.isLocked,
        type: getBasketGroupType(resp),
    };
};

export enum BasketGroupType {
    Ferry,
    Bus,
    Card,
}

export const getBasketGroupType = (section: BasketSectionResponse): BasketGroupType => {
    const isBusRoute = !new RegExp(Object.values(departureRoutes).join("|")).test(section.route);
    const isCardRoute = section.route === "KORT";

    if (isCardRoute) return BasketGroupType.Card;
    else if (isBusRoute) return BasketGroupType.Bus;

    return BasketGroupType.Ferry;
};

const mapFromBasketResourceResponse = (
    resp: BasketResourceResponse,
    dialog?: BasketDialogItemResponse,
    type?: BookingDataType,
    groupType?: BasketGroupType
): BasketResource => {
    return {
        name: getName(resp, dialog),
        description: getDescription(resp, dialog, type, groupType),
        information: resp.information ?? "",
        price: dialog?.price ?? resp.price,
        amount: resp.amount,
        isPrepaid: dialog?.isPrepaid ?? false,
        seat: dialog?.seat,
        isRefundable: !resp.name?.toLowerCase().includes("mini") && !resp.name?.toLowerCase().includes("alla+bil"),
    };
};

const getItems = (items: BasketResourceResponse[], type?: BookingDataType, groupType?: BasketGroupType): BasketResource[] => {
    return items?.flatMap((i) =>
        i.items.length > 0
            ? i.items.map((dialog) => mapFromBasketResourceResponse(i, dialog, type))
            : [mapFromBasketResourceResponse(i, undefined, type, groupType)]
    );
};

const getName = (item: BasketResourceResponse, dialog?: BasketDialogItemResponse) => {
    const amount = `${item.amount > 1 ? item.amount : ""} `;

    switch (item.group) {
        case "F":
            return `${amount}${translateIfExists(`resources.${item.name}.label`) ?? item.description}`;
        case "H":
            return `${amount}${translateIfExists(`resources.${item.name}.label`) ?? item.description}`;
        case "P":
        case "V":
            return dialog?.type ? `${translateIfExists(`passengerType.${dialog.type}`) ?? dialog.type}` : "";
        case "S":
            return `${amount}${item.description}`;
        default:
            return `${amount}${translateIfExists(`resources.${item.name}.label`) ?? item.name ?? ""}`;
    }
};

const getDescription = (
    item: BasketResourceResponse,
    dialog?: BasketDialogItemResponse,
    type?: BookingDataType,
    groupType?: BasketGroupType
) => {
    if (groupType === BasketGroupType.Card) return item.description ?? "";
    if (groupType === BasketGroupType.Bus) return item.description ?? "";
    if (!dialog || type === BookingDataType.Vehicle) {
        return translateIfExists(`booking.${item.subType.charAt(0).toLowerCase()}${item.subType.slice(1)}`) ?? "";
    }

    return translateIfExists(`resources.${item.name}.label`) ?? item.description ?? "";
};
