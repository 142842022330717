import useApi from "@/composables/useApi";
import { TimetableRouteResponse } from "@/models/api/TimetableRouteResponse";
import { TimetableRoute } from "@/models/front/TimetableRoute";
import { useQuery } from "vue-query";


const useRoutesQuery = () => {
    const { get } = useApi();

    const fetcher = () => {
        return get<TimetableRouteResponse[]>('timetable/routes', '').then((resp) => resp.data.map(d => new TimetableRoute(d)))
    }

    return {
        ...useQuery(['routes'], fetcher, {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60
        }),
    }
}

export default useRoutesQuery;

