import { TimetableRouteResponse, TimetableRouteStopResponse } from "../api/TimetableRouteResponse";

export class TimetableRoute {
    routeCode: string;
    busStops: TimetableRouteStop[];

    constructor(resp: TimetableRouteResponse) {
        this.routeCode = resp.routeCode;
        this.busStops = resp.busStops.map((bs) => new TimetableRouteStop(bs))
    }
}

export class TimetableRouteStop {
    id: number;
    name: string;

    constructor(resp: TimetableRouteStopResponse) {
        this.id = resp.id;
        this.name = resp.name;
    }
}
