<template>
    <div class="saved-cards">
        <template v-if="data && data.length > 0">
            <fieldset>
                <legend>{{ $t("booking.checkout.savedCards.title") }}</legend>
                <BaseRadio
                    class="saved-card"
                    v-for="card in data"
                    :key="card.cardId"
                    :id="card.cardId"
                    :value="card.cardId"
                    name="saved-cards"
                    v-model="state.selectedCard"
                >
                    <div>
                        <div class="saved-card__description" v-if="card.description">{{ card.description }}</div>
                        <div class="saved-card__title">
                            {{ `${card.cardType} ${card.maskedNumber}` }}
                        </div>
                        <div class="saved-card__subtitle">{{ $t("booking.checkout.savedCards.expires") }} {{ card.expiryDate }}</div>
                    </div>
                </BaseRadio>
                <BaseRadio class="saved-card" id="new" :value="''" name="saved-cards" v-model="state.selectedCard">
                    <div>
                        <div class="saved-card__title">{{ $t("booking.checkout.savedCards.addCard.title") }}</div>
                        <div class="saved-card__subtitle">{{ $t("booking.checkout.savedCards.addCard.subtitle") }}</div>
                    </div>
                </BaseRadio>
            </fieldset>
        </template>
        <div class="save-card">
            <BaseCheckbox
                id="save-card"
                :disabled="!!state.selectedCard"
                v-model="state.saveCard"
                :label="$t('booking.checkout.savedCards.saveCard')"
            >
                <template #default="{ label }">
                    {{ label }}
                    <BaseInfo
                        class="save-card__info"
                        :title="$t('booking.checkout.savedCards.saveCardInfo.title')"
                        :body="$t('booking.checkout.savedCards.saveCardInfo.body')"
                    />
                </template>
            </BaseCheckbox>
        </div>
    </div>
</template>

<script setup lang="ts">
import BaseCheckbox from "@/components/base/BaseCheckbox.vue";
import BaseInfo from "@/components/base/BaseInfo.vue";
import BaseRadio from "@/components/base/BaseRadio.vue";
import useMyCreditCardsQuery from "@/modules/MyPages/composables/useMyCreditCardsQuery";
import { useCheckoutStore } from "@/store/checkoutStore";
import { reactive, watch } from "vue";

const checkoutStore = useCheckoutStore();

const { data } = useMyCreditCardsQuery();

const state = reactive({
    selectedCard: undefined,
    saveCard: false,
});

watch(state, (current) => {
    checkoutStore.setSelectedCard(current.selectedCard);
    checkoutStore.setSaveCard(current.saveCard);
});
</script>

<style scoped lang="scss">
.saved-cards {
    padding-top: $space-xs;
    padding-bottom: $space-lg;

    fieldset {
        display: flex;
        flex-flow: row wrap;
        gap: 10px;
        legend {
            margin-bottom: 10px;
            font-weight: bold;
        }

        :deep(.radio-button) {
            min-width: 190px;
            --radio-size: 20px;
            flex: 1;

            input[type="radio"] {
                + label {
                    border-radius: 4px;
                    border: 1px solid $c-gray-100;
                    padding: 10px 20px 10px 40px;
                    min-height: 60px;
                    height: 100%;

                    &::before {
                        left: 10px;
                    }
                    &::after {
                        left: 15px;
                    }
                }

                &:checked {
                    + label {
                        border-color: $c-blue;
                        background-color: rgba($c-blue, 0.1);
                    }
                }
            }
        }

        .saved-card {
            &__title,
            &__description {
                font-size: 0.875rem;
            }

            &__subtitle {
                font-size: 0.875rem;
                opacity: 0.8;
            }
        }
    }

    .save-card {
        margin-top: 15px;

        &__info {
            margin-bottom: 6px;
            margin-left: 3px;
        }
    }
}
</style>
