import { VehicleResultDto } from "../api/VehicleResponse";

export class Vehicle {
    code: string;
    license: string;
    isGasOrElectric: boolean;
    requiresLicense?: boolean;
    route: string;
    petInVehicle: boolean;

    constructor(resp: VehicleResultDto) {
        this.code = resp.resourceCode;
        this.license = resp.licenseNumber;
        this.requiresLicense = resp.requiresLicenseNumber;
        this.isGasOrElectric = resp.isGasOrElectric;
        this.route = resp.route;
        this.petInVehicle = resp.hasPet ?? false;
    }
}