
import { CreditCardResponse } from "~/models/api/customer/CreditCardResponse";

export class CreditCard {
    cardId: string;
    cardType: string;
    expiryDate: string;
    maskedNumber: string;
    description: string
    isEditable: boolean;
    tokenId: string;

    constructor(resp: CreditCardResponse) {
        this.cardId = resp.cardId;
        this.cardType = resp.cardType;
        this.expiryDate = resp.expiryDate;
        this.maskedNumber = resp.maskedNumber;
        this.description = resp.description;
        this.isEditable = resp.isEditable ?? true;
        this.tokenId = resp.tokenId;
    }
}
