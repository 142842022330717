import i18n from "@/config/i18n";
import { PaymentAlternativeResponse } from "../api/PaymentAlternativeResponse";

export type PaymentAlternative = {
    id: string;
    paymentMethod: string;
    label: string;
    description: string;
    icon?: string;
};

export const mapFromPaymentAlternativeResponse = (resp: PaymentAlternativeResponse) => {
    const lowerCaseName = resp.name.toLowerCase();

    return {
        id: resp.name,
        paymentMethod: resp.paymentMethod,
        label: i18n.global.t(`booking.checkout.paymentMethod.${lowerCaseName}.title`),
        description: i18n.global.t(`booking.checkout.paymentMethod.${lowerCaseName}.description`),
        icon: lowerCaseName === "trustly" ? `booking/${lowerCaseName}` : undefined,
    };
};

export const mapFromRefundAlternativeResponse = (resp: string) => {
    const lowerCaseName = resp.toLowerCase();

    return {
        id: resp,
        paymentMethod: resp,
        label: i18n.global.t(`booking.refund.refundMethod.${lowerCaseName}.title`),
        description: i18n.global.t(`booking.refund.refundMethod.${lowerCaseName}.description`),
    };
};
