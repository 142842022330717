<template>
    <MyBookingBox class="booking-cancel">
        <p>{{ $t("myPages.myBookingCancel.subtitle") }}</p>
        <form @submit.prevent="submitForm">
            <div class="options">
                <BaseRadio
                    v-for="option in options"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                    :id="`${booking.bookingNumber}-${option.value}`"
                    v-model="v$.action.$model"
                    :name="`${booking.bookingNumber}-action`"
                    :disabled="option.disabled"
                />
            </div>

            <BaseButton :disabled="!v$.action.$model" class="btn--secondary" @click="openLightbox">
                {{ $t("myPages.myBookings.cancel") }}
            </BaseButton>
        </form>
        <BaseLightbox
            v-model="show"
            :title="$t('myPages.myBookingCancel.title')"
            :subtitle="booking.bookingNumber.toString()"
            :maxWidth="650"
            :closable="false"
        >
            <MyBookingCancelLightbox @close="onLightboxClosed" :accessToken="booking.accessToken" :route="state.action" :booking="booking" />
        </BaseLightbox>
    </MyBookingBox>
</template>

<script lang="ts" setup>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseLightbox from "@/components/base/BaseLightbox.vue";
import BaseRadio from "@/components/base/BaseRadio.vue";
import { AllRoutesCode } from "@/models/Constants";
import { OptionItem } from "@/models/OptionItem";
import { MyBooking } from "@/models/front/customer/MyBooking";
import useVuelidate from "@vuelidate/core";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import MyBookingBox from "./MyBookingBox.vue";
import MyBookingCancelLightbox from "./MyBookingCancelLightbox.vue";
import useUnloadBookings from "@/composables/useUnloadBookings";

interface Props {
    booking: MyBooking;
}

const props = defineProps<Props>();

const { t } = useI18n();

const options = computed(() => {
    const getOptionLabel = (index: number) => {
        return index == 0 ? t("myPages.myBookingCancel.cancelTrip") : t("myPages.myBookingCancel.cancelReturnTrip");
    };

    const options: (OptionItem & { disabled: boolean })[] = [];

    if (props.booking.sections.length > 1) {
        options.push(
            ...props.booking.sections.map((s, i) => ({
                value: s.route,
                label: getOptionLabel(i),
                disabled: s.isLocked ?? false,
            }))
        );
    }

    if (!props.booking.sections.some((s) => s.isLocked)) {
        options.push({
            value: AllRoutesCode,
            label: t("myPages.myBookingCancel.cancelRoundTrip"),
            disabled: false,
        });
    }

    return options;
});

const state = reactive({
    action: "",
});
const rules = {
    action: {
        v: (value: string) => !!value,
    },
};

const v$ = useVuelidate(rules, state);
const show = ref(false);

const openLightbox = () => {
    show.value = true;
};

const submitForm = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    show.value = true;
};

const { unload } = useUnloadBookings(false);

const onLightboxClosed = () => {
    unload();
    show.value = false;
};
</script>

<style lang="scss" scoped>
@import "~/styles/icons.scss";

.booking-cancel {
    p {
        margin-bottom: $space-sm;
    }

    form {
        display: flex;
        flex-flow: row wrap;
        gap: $space-md;
        justify-content: space-between;

        .options {
            display: flex;
            gap: $space-md;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                width: 100%;
            }
        }
    }
}
</style>
