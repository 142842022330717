export const rangeHighlights = {
    highlight: {
        start: {
            style: {
                backgroundColor: "var(--blue-600)", // blue
            },
            contentStyle: {
                color: "#ffffff", // color of the text
            },
        },
        base: {
            style: {
                backgroundColor: "#dcecf2", // light blue
            },
        },
        end: {
            style: {
                backgroundColor: "var(--blue-600)", // blue
            },
            contentStyle: {
                color: "#ffffff", // color of the text
            },
        },
    },
};