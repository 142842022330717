<template>
    <div class="islander-box">
        <div class="islander-box__section">
            <div class="islander-box__icon">
                <SvgIcon :icon="'booking/info'" />
            </div>
            <div class="islander-box__content">
                <p v-html="$t('islander.description')"></p>
                <div class="islander-box__input">
                    <BaseInput
                        :name="'personalNumber'"
                        :label="'ÅÅÅÅMMDDXXXX'"
                        :hasError="hasError"
                        mask="############"
                        v-model="inputValue"
                    />
                </div>
                <BaseMessages v-if="errorMessage" class="islander-box__error" type="danger" :messages="[errorMessage]" />
            </div>
        </div>
        <div class="islander-box__section">
            <div class="islander-box__icon">
                <SvgIcon :icon="'booking/travellers'" />
            </div>
            <div class="islander-box__content">
                <p v-html="$t('islander.disclaimer')"></p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import BaseInput from "@/components/base/BaseInput.vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import { computed } from "vue";
import BaseMessages from "../base/BaseMessages.vue";

interface Props {
    hasError?: boolean;
    modelValue: string;
    errorMessage?: string;
}
const props = defineProps<Props>();

const emit = defineEmits(["update:modelValue"]);

const inputValue = computed({
    get() {
        return props.modelValue;
    },
    set(value: string) {
        emit("update:modelValue", value);
    },
});
</script>

<style lang="scss" scoped>
.islander-box {
    $prefix: ".islander-box";
    background-color: $c-yellow-200;
    border-radius: 8px;

    &__section {
        display: flex;
        gap: $space-md;
        position: relative;
        padding: $space-sm $space-md;

        .icon {
            height: 24px;
            width: 24px;
            color: $c-blue-dark;
        }

        &:last-child {
            .icon {
                color: $c-black-100;
            }
        }
    }

    &__input {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        gap: $space-sm;
        margin-top: $space-sm;
        > :first-child {
            flex: 1;
            max-width: 300px;
            min-width: 150px;
        }
    }

    &__error {
        margin-top: 5px;
    }
}
</style>
