import { createI18n } from "vue-i18n";
import de from "../locales/de.json";
import en from "../locales/en.json";
import sv from "../locales/sv.json";

const getLocale = () => {
    // Read language from user agent if we're on the app
    const userAgent = navigator.userAgent.trim();
    const userAgentLang = userAgent.substring(userAgent.length - 2);

    if (["sv", "en"].includes(userAgentLang)) {
        return userAgentLang;
    }
    return document.getElementsByTagName("html")[0].getAttribute("lang");
};

const locale = getLocale();

const i18n = createI18n({
    legacy: false,
    locale: locale || "sv",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "sv",
    globalInjection: true,
    messages: { sv, en, de },
});

export default i18n;
