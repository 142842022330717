<template>
    <MyBookingBox>
        <template #header>{{ $t("myPages.myBookings.refund.text") }}</template>
        <BaseButton @click="goToRefund" :loading="isLoading" class="btn--primary btn--fixed-width btn--secondary">
            {{ $t("myPages.myBookings.refund.button") }}</BaseButton
        >
    </MyBookingBox>
</template>

<script lang="ts" setup>
import BaseButton from "@/components/base/BaseButton.vue";
import { MyBooking } from "@/models/front/customer/MyBooking";
import useOpenBooking from "../../composables/useOpenBooking";
import MyBookingBox from "./MyBookingBox.vue";

interface Props {
    booking: MyBooking;
}

const props = defineProps<Props>();

const { openBooking, isLoading } = useOpenBooking();

const goToRefund = () => {
    openBooking(props.booking.accessToken, "Refund").then((resp) => {
        window.location.href = resp.url;
    });
};
</script>

<style lang="scss" scoped>
.btn--primary {
    margin-top: 20px;
}
</style>
