import i18n from "@/config/i18n";
import { apiTokenKey, whoamiKey } from "@/config/storageKeys";
import { storageWithExpiration } from "@/helpers/storageHelper";
import { ErrorCodes, PageUrl } from "@/models/Enums";
import { RuntimeConfig } from "@/models/RuntimeConfig";
import { DialogType } from "@/models/Symbols/Dialog";
import { eventBusKey } from "@/models/Symbols/Eventbus";
import { useQueueStore } from "@/store/queue";
import { useUserStore } from "@/store/user";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import Cookies from "js-cookie";
import { inject, readonly, ref } from "vue";
import { useI18n } from "vue-i18n";
import useDialog from "./useDialog";
import useRuntimeConfig from "./useRuntimeConfig";
import useSnackbar from "./useSnackbar";
import useRedirect from "./useRedirect";
import { postMessage } from "@/helpers/messageHandler";
import { translateIfExists } from "@/helpers/localeHelper";

type ApiOptions = {
    displayErrors?: boolean;
    useBaseUrl?: boolean;
    suppressSessionExpired?: boolean;
};

axios.defaults.withCredentials = true;

const devMode = process.env.NODE_ENV !== "production";
const serviceUnavailable = ref(false);
const tokenStorage = storageWithExpiration(localStorage);

const setServiceUnavailable = (value: boolean) => {
    serviceUnavailable.value = value;
};

const defaultOptions: ApiOptions = {
    displayErrors: true,
    useBaseUrl: true,
    suppressSessionExpired: false,
};

const useApi = (options?: ApiOptions) => {
    const { displayErrors, suppressSessionExpired, useBaseUrl } = { ...defaultOptions, ...(options ?? {}) };

    const { getRuntimeConfig } = useRuntimeConfig();
    const { t } = useI18n();
    const { displaySnack } = useSnackbar();
    const { displayDialog } = useDialog();
    const userStore = useUserStore();
    const queueStore = useQueueStore();
    const { redirect } = useRedirect();

    const eventBus = inject(eventBusKey);

    const getToken = () => tokenStorage.getItem(apiTokenKey);
    const setToken = (value: string) => tokenStorage.setItem(apiTokenKey, value);
    const removeToken = () => {
        tokenStorage.removeItem(apiTokenKey);
        Cookies.remove(whoamiKey);
        eventBus?.emit("update-whoami", "");
    };

    const setTokenFromResponse = (resp: AxiosResponse) => {
        if (resp?.headers["x-dg-token"]) {
            setToken(resp.headers["x-dg-token"]);
            userStore.$persist();
        }
    };

    const getBaseUrl = (c: RuntimeConfig) => {
        return useBaseUrl ? c.apiBaseUrl : "";
    };

    const post = <T>(url: string, data: unknown, headers: Record<string, string> = {}, signal?: AbortSignal): Promise<AxiosResponse<T>> => {
        return getRuntimeConfig().then((c) =>
            execute<T>(axios.post<T>(`${getBaseUrl(c)}${url}`, data, getRequestConfig("POST", data, headers, signal)))
        );
    };

    const get = <T>(url: string, data: unknown, headers: Record<string, string> = {}, signal?: AbortSignal): Promise<AxiosResponse<T>> => {
        return getRuntimeConfig().then((c) =>
            execute<T>(axios.get<T>(`${getBaseUrl(c)}${url}`, getRequestConfig("GET", data, headers, signal)))
        );
    };

    const del = <T>(url: string, data: unknown, headers: Record<string, string> = {}): Promise<AxiosResponse<T>> => {
        return getRuntimeConfig().then((c) => execute<T>(axios.delete<T>(`${getBaseUrl(c)}${url}`, getRequestConfig("DELETE", data, headers))));
    };

    const put = <T>(url: string, data: unknown, headers: Record<string, string> = {}): Promise<AxiosResponse<T>> => {
        return getRuntimeConfig().then((c) => execute<T>(axios.put<T>(`${getBaseUrl(c)}${url}`, data, getRequestConfig("PUT", data, headers))));
    };

    const patch = <T>(url: string, data: unknown, headers: Record<string, string> = {}): Promise<AxiosResponse<T>> => {
        return getRuntimeConfig().then((c) =>
            execute<T>(axios.patch<T>(`${getBaseUrl(c)}${url}`, data, getRequestConfig("PATCH", data, headers)))
        );
    };

    const execute = <T>(fetcher: Promise<AxiosResponse<unknown>>): Promise<AxiosResponse<T>> => {
        return new Promise((resolve, reject) => {
            fetcher
                .then((resp) => {
                    setTokenFromResponse(resp);
                    resolve(resp as AxiosResponse<T>);
                })
                .catch((err) => {
                    if (!suppressSessionExpired && err?.response?.status === 401) {
                        onSessionExpired();
                    } else {
                        handleError(err);
                    }

                    reject(err);
                }) as Promise<AxiosResponse<T> | void>;
        });
    };

    const getRequestConfig = (method: Method, data: unknown, headers: Record<string, string>, signal?: AbortSignal) => {
        if (method == "GET") {
            return { ...getHeaders(headers), params: data, signal };
        }
        if (method == "DELETE") {
            return {
                ...getHeaders(headers),
                data,
                signal,
            };
        }
        return { ...getHeaders(headers), signal };
    };

    const getHeaders = (optionalHeaders: Record<string, string>) => {
        const token = getToken();
        return {
            headers: {
                ...(token
                    ? {
                          "x-dg-token": token,
                      }
                    : {}),
                ...optionalHeaders,
                "Accept-Language": i18n.global.locale.value,
                "X-DG-ID": userStore.getUser.number?.toString() ?? "",
            },
        };
    };

    const authenticate = () => {
        return getRuntimeConfig().then(() => {
            if (!getToken()) {
                return post("auth", {}).catch((err) => {
                    removeToken();
                    return Promise.reject(err);
                });
            }
        });
    };

    const setErrorMessage = (err: AxiosError<any, unknown>) => {
        if (i18n.global.te(`errors.${err.response?.data?.errorCode}`)) {
            err.message = i18n.global.t(`errors.${err.response?.data?.errorCode}`, {
                message: translateIfExists(`apiMessages.${err?.response?.data?.message}`) ?? "",
            });
        } else if (devMode) {
            err.message = err.toString();
        } else {
            err.message = i18n.global.t("errors.1");
        }
    };

    const handleError = (err: AxiosError) => {
        if (err?.response?.status === 420) {
            serviceUnavailable.value = true;
        } else if (err?.response?.status === 429) {
            queueStore.setIsQueue(true);
            redirect(PageUrl.Queue, `?returnUrl=${window.location.href}`);
        }
        setErrorMessage(err);
        if ((!displayErrors && err.response) || serviceUnavailable.value) {
            return;
        }

        const errorCode = (err.response?.data as any)?.errorCode;
        if (errorCode) {
            switch (errorCode) {
                case ErrorCodes.BookingLocked:
                    displayDialog({
                        title: t("messages.bookingLocked.title"),
                        message: err.message,
                        yes: t("button.ok"),
                        type: DialogType.info,
                    });
                    break;
            }
        }

        if (err.message && err.code !== "ERR_CANCELED" && !serviceUnavailable.value) {
            displaySnack({
                type: "danger",
                message: err.message,
                id: Date.now().toString(),
            });
        }
    };

    const delay = (t: number, value: unknown = undefined) => {
        return new Promise(function (resolve) {
            setTimeout(resolve.bind(null, value), t);
        });
    };

    type PromiseFunction<T> = () => Promise<T>;
    const reAuthenticate = <T>(call: PromiseFunction<T>, count = 3): Promise<T> => {
        let lastError: AxiosError;
        return new Promise((resolve, reject) => {
            if (count > 0) {
                authenticate().then(() => {
                    call()
                        .then((data: T) => resolve(data))
                        .catch((err) => {
                            lastError = err;
                            removeToken();
                            reAuthenticate(call, count - 1);
                        });
                });
            } else {
                reject(lastError ?? "");
            }
        });
    };

    const retry = <T>(call: PromiseFunction<T>, count = 3): Promise<T> => {
        let lastError: AxiosError;
        return new Promise((resolve, reject) => {
            if (count > 0) {
                call()
                    .then((data: T) => resolve(data))
                    .catch((err) => {
                        lastError = err;
                        delay(500).then(() => retry(call, count - 1));
                    });
            } else {
                reject(lastError);
            }
        });
    };

    const onSessionExpired = () => {
        displayDialog({
            title: t("messages.sessionExpired.title"),
            message: t("messages.sessionExpired.body"),
            yes: t("button.ok"),
            callback: () => {
                userStore.$reset();
                sessionStorage.clear();
                localStorage.clear();
                removeToken();

                if (postMessage("sessionexpired") === false) {
                    window.location.replace(location.origin);
                }
            },
            type: DialogType.redirect,
        });
    };

    return {
        post,
        get,
        del,
        patch,
        put,
        removeToken,
        authenticate,
        getToken,
        reAuthenticate,
        delay,
        retry,
        setToken,
        serviceUnavailable: readonly(serviceUnavailable),
        onSessionExpired,
        setServiceUnavailable,
    };
};

export default useApi;
