import { RuntimeConfig } from "@/models/RuntimeConfig";
import axios from "axios";
import PQueue from "p-queue";
import { ref } from "vue";

const version = process.env.PACKAGE_VERSION;
const url = process.env.RUNTIME_CONFIG_URL ?? "/vue/dg/dist/runtimeConfig.json";

const config = ref<RuntimeConfig>({
    apiBaseUrl: "https://api.destinationgotland.se/api/v2/",
    account_path: {
        en: "/en/ferry/book/my-pages",
        sv: "/sv/farja/boka/mina-sidor",
        de: "/de/fahre/buchen/meine-seiten/",
    },
    booking_path: {
        en: "/en/ferry/book",
        sv: "/sv/farja/boka",
        de: "/de/fahre/buchen",
    },
    queue_path: {
        en: "/en/ferry/queue",
        sv: "/sv/farja/queue",
        de: "/de/fahre/queue",
    },
});

const read = ref(false);
const queue = new PQueue({ concurrency: 1 });

const useRuntimeConfig = () => {
    const fetcher = async () => {
        if (read.value) return config.value;

        const runtimeConfig = await axios.get(url + "?version=" + version);
        const json = runtimeConfig.data;
        config.value = {
            apiBaseUrl: json.api_base_url,
            account_path: json.account_path,
            booking_path: json.booking_path,
            queue_path: json.queue_path,
        };

        read.value = true;

        return config.value;
    };

    const getRuntimeConfig = async () => {
        return queue.add<RuntimeConfig>(fetcher) as Promise<RuntimeConfig>;
    };

    return {
        getRuntimeConfig,
    };
};

export default useRuntimeConfig;
