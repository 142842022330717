<template>
    <section class="login-form">
        <header v-if="title">
            <h4>{{ title }}</h4>
            <p v-if="subtitle">{{ subtitle }}</p>
        </header>
        <form @submit.prevent="loginUser">
            <div class="row">
                <div class="col--12 col-sm--6 col-md--4">
                    <BaseInput
                        :label="$t('login.form.email')"
                        type="username"
                        name="username"
                        :hasError="v$.userForm.userName.$error"
                        :errors="v$.userForm.userName.$errors"
                        v-model.trim="v$.userForm.userName.$model"
                    />
                </div>
                <div class="col--12 col-sm--6 col-md--3">
                    <BaseInput
                        :label="$t('login.form.password')"
                        type="password"
                        name="password"
                        :hasError="v$.userForm.password.$error"
                        :errors="v$.userForm.password.$errors"
                        v-model.trim="v$.userForm.password.$model"
                    />
                </div>
                <div class="col--12 col-md--5">
                    <div class="button-container">
                        <BaseButton
                            :loading="loadingForm"
                            :disabled="loadingForm || verificationActive"
                            class="btn--secondary btn--fixed-width"
                            type="submit"
                        >
                            <span>{{ $t("button.login") }}</span>
                        </BaseButton>
                        <LoginForgotPassword>
                            <a class="link">{{ $t("login.form.forgottenPassword") }}</a>
                        </LoginForgotPassword>
                    </div>
                </div>
                <div class="col--12" v-if="loginError?.length > 0">
                    <BaseMessages :messages="[loginError]" />
                </div>
            </div>
        </form>
        <VerifyIslander
            v-if="islanderVerificationId"
            @success="verificationDone = true"
            @login="loginUser"
            class="islander-verification"
            :id="islanderVerificationId"
        />
    </section>
</template>

<script lang="ts" setup>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseMessages from "@/components/base/BaseMessages.vue";
import useLogin from "@/composables/login/useLogin";
import { required } from "@/helpers/validators";
import useVuelidate from "@vuelidate/core";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import LoginForgotPassword from "./LoginForgotPassword.vue";
import VerifyIslander from "./VerifyIslander.vue";
import useDialog from "@/composables/useDialog";
import { DialogType } from "@/models/Symbols/Dialog";

interface Props {
    title: string;
    subtitle?: string;
    force?: boolean;
}

const props = defineProps<Props>();

const loadingForm = ref<boolean>(false);
const { customerLogin } = useLogin();
const { t } = useI18n();

const state = reactive({
    userForm: {
        userName: "",
        password: "",
    },
});

const rules = {
    userForm: {
        userName: { required },
        password: { required },
    },
};

const v$ = useVuelidate(rules, state);

const loginError = ref<string>("");

const emit = defineEmits(["onSuccess"]);

const { displayDialog } = useDialog();

const onBookingReset = (url: string, isIslander: boolean) => {
    const message = isIslander ? t("messages.bookingResetIslander.body") : t("messages.bookingResetCardCustomer.body");
    const title = isIslander ? t("messages.bookingResetIslander.title") : t("messages.bookingResetCardCustomer.title");
    displayDialog({
        message,
        title,
        type: DialogType.redirect,
        yes: t("button.ok"),
        callback: () => {
            if (process.env.NODE_ENV === "production") {
                window.location.href = url;
            } else {
                window.location.href = "/my-pages";
            }
        },
    });
};

const islanderVerificationId = ref("");
const verificationDone = ref(false);

const verificationActive = computed(() => (!!islanderVerificationId.value ? !verificationDone.value : false));

const loginUser = async () => {
    const isFormCorrect = await v$.value.userForm.$validate();
    if (!isFormCorrect) return;

    loadingForm.value = true;
    islanderVerificationId.value = "";
    customerLogin(state.userForm.userName, state.userForm.password, props.force ?? false)
        .then((resp) => {
            if (resp.status === 202) {
                onBookingReset(resp.data.url, resp.data.customer.isIslander);
            } else {
                emit("onSuccess", resp.data.url);
            }
        })
        .catch((err) => {
            if (err.response?.data?.errorCode === 11005) {
                islanderVerificationId.value = err.response?.data?.id;
            } else {
                loginError.value = t(`errors.${err.response.data.errorCode}`);
            }
        })
        .finally(() => (loadingForm.value = false));
};
</script>

<style lang="scss" scoped>
@import "~/styles/components/login-form.scss";
.islander-verification {
    margin-top: $space-sm;
}
</style>
