import { groupBy } from "@/helpers/groupBy";
import { Direction } from "@/models/Enums";
import { Vehicle } from "@/models/front/Vehicle";
import { VehicleState } from "@/models/store/BookingState";
import { defineStore } from "pinia";
import { useBookingStore } from "./booking";
import { storageWithExpiration } from "@/helpers/storageHelper";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isEqual = require("lodash.isequal");

const getInitialState = () => {
    return {
        outward: {
            code: "",
            amount: 1,
            license: "",
            isGasOrElectric: false,
            unknownLicense: false,
            requiresLicense: false,
            petInVehicle: false,
        } as VehicleState,
        return: {
            code: "",
            amount: 1,
            license: "",
            isGasOrElectric: false,
            unknownLicense: false,
            requiresLicense: false,
            petInVehicle: false,
        } as VehicleState,
    };
};

export const useVehicleStore = defineStore("vehicle", {
    state: () => {
        return getInitialState();
    },
    getters: {
        getVehicle:
            (state) =>
            (direction: Direction): VehicleState => {
                const bookingStore = useBookingStore();
                switch (direction) {
                    case Direction.Outward:
                        return state.outward as VehicleState;
                    case Direction.Return:
                        return (bookingStore.differentDetailsReturn ? state.return : state.outward) as VehicleState;
                }
            },
    },
    actions: {
        setVehicle(direction: Direction, vehicle: VehicleState) {
            if (direction === Direction.Outward) {
                this.outward = vehicle;
            } else {
                this.return = vehicle;
            }
        },
        resetVehicle(direction: Direction) {
            const defaultVehicle = {
                code: "",
                amount: 1,
                license: "",
                isGasOrElectric: false,
                unknownLicense: false,
                petInVehicle: false,
            } as VehicleState;
            if (direction === Direction.Outward) {
                this.outward = defaultVehicle;
            } else {
                this.return = defaultVehicle;
            }
        },
        hasVehicle(direction: Direction) {
            if (direction === Direction.Outward) {
                return !!this.outward.code && this.outward.amount > 0;
            } else {
                return !!this.return.code && this.return.amount > 0;
            }
        },
        setFromApi(details: Vehicle[]) {
            const routes = groupBy(details, "route");
            const bookingStore = useBookingStore();

            for (const route in routes) {
                const vehicles = details.filter((detail) => detail.route == route);

                const direction = route === bookingStore.getBooking.outward.route ? Direction.Outward : Direction.Return;

                const storedVehicle = this.getVehicle(direction);

                if (vehicles.length) {
                    const veh = vehicles[0];

                    this.setVehicle(direction, {
                        ...storedVehicle,
                        ...veh,
                        amount: veh.requiresLicense ? 1 : vehicles.length,
                    });
                }
            }

            const outwardVehicle = {
                ...this.outward,
                route: "",
            };

            const returnVehicle = {
                ...this.return,
                route: "",
            };

            if (!isEqual(outwardVehicle, returnVehicle)) {
                bookingStore.setIsDifferentDetails(true);
            }
        },
    },
    persist: {
        storage: storageWithExpiration(localStorage),
    },
});
