import useApi from "@/composables/useApi";
import { CustomerCardResponse } from "@/models/api/customer/CustomerCardResponse";
import { CustomerCard } from "@/models/front/customer/CustomerCard";
import { useUserStore } from "@/store/user";
import { computed } from "vue";
import { useQuery } from "vue-query";

const useCustomerCardsQuery = () => {
    const { get } = useApi();
    const userStore = useUserStore();

    const fetcher = () => {
        return get<CustomerCardResponse>("customer/cards", "").then((resp) => {
            if (resp.data) {
                return new CustomerCard(resp.data);
            }
            return undefined;
        });
    };

    return {
        ...useQuery(["cards", userStore.getUser.number], fetcher, {
            refetchOnWindowFocus: false,
            staleTime: 60 * 1000,
            enabled: computed(() => !!userStore.getUser?.isCardCustomer),
        }),
    };
};

export default useCustomerCardsQuery;
