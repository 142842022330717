import { PaymentInfoResponse } from "../api/PaymentInfoResponse";

export class PaymentInfo {
    currency: string;
    paid: number;
    leftToPay: number;
    total: number;
    VAT: number;
    totalExcludingVAT: number;
    paymentStatus: string;

    constructor(resp: PaymentInfoResponse) {
        this.currency = resp.currency;
        this.paid = resp.paid;
        this.leftToPay = resp.leftToPay;
        this.total = resp.total;
        this.VAT = resp.vat;
        this.totalExcludingVAT = resp.totalExcludingVAT;
        this.paymentStatus = resp.paymentStatus;
    }
}
