import { getPassengerType } from "@/helpers/passengerHelper";
import { PassengerResultDto } from "../api/PassengerResponse";

export class Passenger {
    country: string;
    dateOfBirth?: string;
    disability?: string;
    firstName: string;
    lastName: string;
    gender: string;
    id: string;
    title: string;
    type: string;
    route: string;
    isEmpty?: boolean;
    isEditable?: boolean;
    isIslander: boolean;
    needsValidation: boolean;

    constructor(resp: PassengerResultDto) {
        this.country = resp.country;
        this.dateOfBirth = resp.dateOfBirth;
        this.disability = resp.disability;
        this.firstName = resp.firstName;
        this.lastName = resp.lastName;
        this.gender = resp.gender;
        this.id = resp.id;
        this.title = resp.title;
        this.type = getPassengerType(resp.passengerType, resp.isIslander);
        this.route = resp.route;
        this.isEmpty = resp.isEmpty;
        this.isEditable = resp.isEditable ?? true;
        this.isIslander = resp.isIslander ?? false;
        this.needsValidation = resp.needsValidation ?? false;
    }
}