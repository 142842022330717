import { ref } from "vue";
import { useI18n } from "vue-i18n";
import useApi from "../useApi";

const useForgotPassword = () => {
    const isLoading = ref(false);
    const isSuccess = ref(false);
    const { post } = useApi();
    const { locale } = useI18n();

    const sendForgotPassword = (email: string) => {
        if (isSuccess.value) return Promise.resolve();
        isLoading.value = true;
        return post("reset-password", { userName: email, language: locale.value })
            .then(() => {
                isSuccess.value = true;
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    return {
        sendForgotPassword,
        isLoading,
        isSuccess,
    };
};

export default useForgotPassword;
