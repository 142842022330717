import { readonly, ref } from "vue";
import useApi from "../useApi";
import i18n from "@/config/i18n";
import { postMessage } from "@/helpers/messageHandler";

const loggingOut = ref(false);

const useLogout = () => {
    const { removeToken } = useApi();
    const { get } = useApi();

    const logout = (redirect = true) => {
        loggingOut.value = true;
        //api call logout
        get("logout", "").finally(() => {
            sessionStorage.clear();
            localStorage.clear();
            removeToken();

            if (postMessage('logout') === false) {
                window.location.replace(location.origin);
            }

            if (redirect) {
                window.location.href = "/" + i18n.global.locale.value;
            } else {
                loggingOut.value = false;
            }
        });
    };

    return { logout, loggingOut: readonly(loggingOut) };
};

export default useLogout;
