<template>
    <div class="checkbox" :class="{ 'checkbox--error': hasError, disabled: disabled, 'checkbox--valid': valid }">
        <input type="checkbox" :id="id" :name="name" :disabled="disabled" v-model="inputValue" tabindex="0" />
        <label :for="id">
            <slot :label="label">
                <span>{{ label }}</span>
            </slot>
        </label>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

interface Props {
    id: string;
    name?: string;
    modelValue?: any;
    hasError?: boolean;
    label?: string;
    disabled?: boolean;
    valid?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits(["update:modelValue"]);

const inputValue = computed({
    get(): any {
        return props.modelValue ?? false;
    },
    set(value: any) {
        emit("update:modelValue", value);
    },
});
</script>

<style lang="scss" scoped>
.checkbox {
    --checkbox-size: 20px;
    display: flex;
    position: relative;
    align-items: center;

    input[type="checkbox"] {
        position: absolute;
        left: -9999px;
        opacity: 0;

        + label {
            display: flex;
            align-items: center;
            position: relative;
            padding-left: calc(var(--checkbox-size) + 10px);
            @include font("p");
            cursor: pointer;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                width: var(--checkbox-size);
                height: var(--checkbox-size);
                background-color: $c-white-100;
                border: 1px solid $c-gray-200;
                border-radius: $br-input;
                transform: translateY(-50%);
            }

            :deep() > span {
                display: block;
                margin-bottom: -2px;

                a[href] {
                    color: $c-blue-300;
                    text-decoration: underline;
                }
            }
        }

        &:checked {
            + label {
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: calc((var(--checkbox-size) - (var(--checkbox-size) - 4px)) / 2);
                    width: calc(var(--checkbox-size) - 4px);
                    height: calc(var(--checkbox-size) - 4px);
                    background: url("~@/assets/svg/checkmark.svg") no-repeat center 0;
                    background-size: calc(var(--checkbox-size) - 4px) calc(var(--checkbox-size) - 4px);
                    border-radius: 100%;
                    transform: translateY(-50%);
                }
            }
        }

        &:not(:disabled) {
            &:focus {
                + label::before {
                    outline: 2px solid $c-blue-200;
                    outline-offset: -1px;
                }
            }

            &:hover {
                + label::before {
                    border-color: $c-blue-dark;
                }
            }
        }

        &:disabled {
            + label {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }

    &--error {
        input[type="checkbox"] {
            + label {
                &::before {
                    background-color: rgba($c-red-100, 10%);
                    border-color: $c-red-100;
                }
            }
        }
    }
}
</style>
