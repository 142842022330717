import { OptionItem } from "@/models/OptionItem";

export const departureRoutes = {
	NYVI: "NYVI",
	VINY: "VINY",
	OSVI: "OSVI",
	VIOS: "VIOS",
	VIVA: "VIVA",
	VAVI: "VAVI"
}

export const getReturnRoutes = (route: string, routes: OptionItem[]) => {
	const origin = route.substring(0, 2)
	const destination = route.substring(2, 4);
	return routes.filter(r => {
		if (destination == 'VI') {
			return r.value.startsWith(destination);
		}

		if (origin == 'VI') {
			return r.value.endsWith(origin)
		}

		return true;
	})
}

export const getReverseRoute = (route: string) => {
	return `${route.substring(2, 4)}${route.substring(0, 2)}`
}