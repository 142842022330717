import { HHmm, YYYYMMDD } from "@/helpers/dateHelpers";
import { TimetableDepartureResponse } from "../api/TimetableDepartureResponse";
import { departureRoutes } from "@/config/routes";
import { addMinutes } from "date-fns";

export class TimetableDeparture {
    id: string;
    departureDate: string;
    arrivalDateTime: Date;
    departureDateTime: Date;
    isFreight: boolean;
    route: string;
    ship: string;
    departureTime: string;
    arrivalTime: string;
    busDeparture?: Date;
    isOldTransfer: boolean;

    constructor(resp: TimetableDepartureResponse) {
        this.id = `${resp.route}|${resp.departureDateTime}`;
        this.arrivalDateTime = new Date(resp.arrivalDateTime);
        this.departureDateTime = new Date(resp.departureDateTime);
        this.departureDate = YYYYMMDD(this.departureDateTime);
        this.isFreight = resp.isFreight;
        this.route = resp.route;
        this.ship = resp.ship;
        this.departureTime = HHmm(resp.departureDateTime);
        this.arrivalTime = HHmm(resp.arrivalDateTime);
        this.isOldTransfer = false;

        if (this.departureDateTime > new Date(2024, 4, 3)) {
            this.busDeparture = resp.busTimeTableItem?.departureDateTime;
        } else if ([departureRoutes.NYVI, departureRoutes.VINY].includes(this.route)) {
            this.isOldTransfer = true;
            if (this.route === departureRoutes.NYVI) {
                //Bus leaves 1 hour 45 minutes before departure
                this.busDeparture = addMinutes(this.departureDateTime, -105);
            } else {
                //Bus leaves 1 hour 15 minutes after arrival
                this.busDeparture = addMinutes(this.arrivalDateTime, 75);
            }
        }
    }
}
