import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-264be1f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["name", "disabled", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field__container", { active: _ctx.active }])
  }, [
    _createElementVNode("label", {
      class: "field__label",
      for: _ctx.name
    }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _withDirectives(_createElementVNode("textarea", {
      name: _ctx.name,
      onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
      class: _normalizeClass(["field__input", { 'field__input--error': _ctx.hasError, disabled: _ctx.disabled, 'field__input--valid': _ctx.valid }]),
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputValue) = $event)),
      disabled: _ctx.disabled,
      placeholder: _ctx.placeholder
    }, null, 42, _hoisted_2), [
      [_vModelText, _ctx.inputValue]
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}