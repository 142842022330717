import { PageUrl } from "@/models/Enums";
import { LocaleString } from "@/models/RuntimeConfig";
import { useI18n } from "vue-i18n";
import useRuntimeConfig from "./useRuntimeConfig";

const useRedirect = () => {
    const { locale } = useI18n();
    const { getRuntimeConfig } = useRuntimeConfig();

    const redirectToPage = (path: LocaleString, query: string) => {
        window.location.href = path[locale.value as keyof LocaleString] + query
    }

    const redirect = (url: PageUrl, query = '') => {
        getRuntimeConfig().then((x) => { redirectToPage(x[url], query) });
    }

    return {
        redirect
    }
}

export default useRedirect;