import { CancelBookingResultDto } from "@/models/api/CancelBookingResultDto";
import { PaymentAlternative, mapFromRefundAlternativeResponse } from "../PaymentAlternative";
import { BasketSection, mapFromBasketSectionResponse } from "../basket/BasketGroup";

export class CancelBookingResult {
    bookingNumber: number;
    sections: BasketSection[];
    paid: number;
    cancellationFee: number;
    refund: number;
    paymentAlternatives: PaymentAlternative[];
    paymentReference: string;

    constructor(resp: CancelBookingResultDto) {
        this.bookingNumber = resp.bookingNumber;
        this.paid = resp.paid;
        this.refund = resp.refund;
        this.cancellationFee = resp.cancellationFee;
        this.sections = resp.cancellationBookingSections.map((s) => ({
            ...mapFromBasketSectionResponse(s),
            isCancelled: true,
        }));

        this.paymentAlternatives = resp.refundAlternatives.map((r) => mapFromRefundAlternativeResponse(r));
        this.paymentReference = resp.paymentReference;
    }
}
