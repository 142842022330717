import { CustomerResponse } from "@/models/api/customer/CustomerResponse";
import { AddressInformation } from "@/models/front/customer/AddressInformation";
import { mapFromCustomerLoginResponse } from "@/models/store/User";
import { ref } from "vue";
import { useQueryClient } from "vue-query";
import useApi from "../useApi";
import useLogin from "./useLogin";

const useCreateAccount = () => {
    const { post } = useApi();
    const { setUserState } = useLogin();
    const queryClient = useQueryClient();

    const isLoading = ref(false);

    const error = ref<Error>();

    const createAccount = (address: AddressInformation) => {
        error.value = undefined;
        isLoading.value = true;
        return post<CustomerResponse>("customer/create", address)
            .then((resp) => {
                queryClient.invalidateQueries("address-info");
                setUserState(mapFromCustomerLoginResponse(resp.data));
                return resp.data;
            })
            .catch((err) => {
                error.value = err;
                return Promise.reject(err);
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    return {
        createAccount,
        isLoading,
        error,
    };
};

export default useCreateAccount;
