<template>
    <div class="basket-group" :class="{ 'is-locked': section.isLocked }">
        <component :is="basketGroupRenderer" :section="section" :condensed="condensed" />
        <slot :section="section">
            <ResourceList icon="booking/travellers" :resources="section.passengers" v-if="section.passengers.length" />
            <ResourceList icon="booking/car" :resources="section.vehicles" v-if="section.vehicles.length" />
            <ResourceList icon="booking/food" :resources="section.food" v-if="section.food.length" />
            <ResourceList
                icon="booking/other"
                :resources="section.other"
                v-if="section.other.length"
                :isCard="section.type === BasketGroupType.Card"
                :isBus="section.type === BasketGroupType.Bus"
            />
            <div class="basket-group__extra" v-if="section.flexibility.name?.trim()?.length">
                <span>{{ $t("bookingSummary.flexibility") }}: {{ section.flexibility.name }}</span>
                <span>{{ formatPrice(section.flexibility.price) }}</span>
            </div>
            <div class="basket-group__sum" v-if="section.discount">
                <span>{{ $t("bookingSummary.discount") }}</span>
                <span class="price">-{{ formatPrice(section.discount) }}</span>
            </div>
            <div class="basket-group__sum campaign" v-if="section.campaignName">
                <span>{{ $t("bookingSummary.campaignCode") }}</span>
                <span class="code">{{ section.campaignName }}</span>
            </div>
            <div class="basket-group__sum">
                <span>{{ $t("bookingSummary.sum") }}</span>
                <span class="price">{{
                    formatPrice(section.price && section.discount ? section.price - section.discount : section.price)
                }}</span>
            </div>
        </slot>
    </div>
</template>

<script lang="ts" setup>
import ResourceList from "@/components/basket/ResourceList.vue";
import { formatPrice } from "@/helpers/priceHelpers";
import { BasketGroupType, BasketSection } from "@/models/front/basket/BasketGroup";
import FerrySectionRenderer from "./renderers/FerrySectionRenderer.vue";
import { computed } from "vue";
import BusSectionRenderer from "./renderers/BusSectionRenderer.vue";

interface Props {
    section: BasketSection;
    condensed?: boolean;
}

const props = withDefaults(defineProps<Props>(), { condensed: false });

const renderers = {
    [BasketGroupType.Ferry]: FerrySectionRenderer,
    [BasketGroupType.Bus]: BusSectionRenderer,
    [BasketGroupType.Card]: "div",
};

const basketGroupRenderer = computed(() => renderers[props.section.type ?? 0]);
</script>

<style lang="scss" scoped>
.basket-group {
    $prefix: ".basket-group";

    #{$prefix}__extra {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    #{$prefix}__sum {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .price {
            margin-top: -3px;
            padding: 3px 10px 3px 20px;
            background-color: $c-gray-300;
        }
    }

    + #{$prefix} {
        margin-top: $space-md;
        border-top: 1px solid $c-gray-100;
        padding-top: $space-md;
    }
}

.basket {
    .icon {
        display: block;
        width: 40px;
        height: 16px;
    }

    ul {
        margin-top: 10px;

        > li {
            > span {
                display: block;
                line-height: 1.25;
            }
        }
    }
}
</style>
