import useApi from "@/composables/useApi";
import { SiteSearchRequest } from "@/models/api/SiteSearchRequest";
import { ElasticSearchResponse } from "@/models/api/SiteSearchResponse";
import { SiteSearch } from "@/models/front/SiteSearch";
import { useSiteSearchStore } from "@/store/siteSearch";
import { computed } from "vue";
import { useQuery } from "vue-query";

const useSiteSearchQuery = () => {
    const { post } = useApi({ displayErrors: false, useBaseUrl: false });

    const store = useSiteSearchStore();

    const params = computed(() => {
        return {
            query: store.getQuery,
            page: store.getPage,
            size: 10,
        } as SiteSearchRequest;
    });

    const enabled = computed(() => params.value.query.length > 2);

    const fetcher = () => {
        return post<ElasticSearchResponse>(`/api/site/search`, params.value).then((resp) => {
            const result = new SiteSearch(resp.data);
            return result;
        });
    };

    return {
        ...useQuery(["siteSearch", params], fetcher, {
            staleTime: Infinity,
            enabled,
            keepPreviousData: true,
        }),
    };
};

export default useSiteSearchQuery;
