<template>
    <div class="login-container">
        <div class="login__tabs">
            <div class="row">
                <div class="col--6 col-sm--4">
                    <LoginTab
                        :title="$t('login.form.ferryLogin.tab.title')"
                        :subtitle="$t('login.form.ferryLogin.tab.subtitle')"
                        :active="activeComponent === 'LoginFerry'"
                        :icon="'booking/ferry'"
                        @click="activeComponent = 'LoginFerry'"
                    />
                </div>
                <div class="col--6 col-sm--4">
                    <LoginTab
                        :title="$t('login.form.agentLogin.tab.title')"
                        :subtitle="$t('login.form.agentLogin.tab.subtitle')"
                        :active="activeComponent === 'LoginAgent'"
                        :icon="'booking/avatars'"
                        @click="activeComponent = 'LoginAgent'"
                    />
                </div>
                <div class="col--6 col-sm--4 d--none d-sm--block">
                    <LoginTab
                        class="accomodation"
                        :title="$t('login.form.accomodationLogin.tab.title')"
                        :subtitle="$t('login.form.accomodationLogin.tab.subtitle')"
                        :active="activeComponent === 'LoginAccomodation'"
                        :icon="'booking/bed'"
                        @click="activeComponent = 'LoginAccomodation'"
                        :linkUrl="accommodationLoginUrl"
                    />
                </div>
            </div>
        </div>
        <div class="login__content">
            <div class="booking">
                <component :is="(components as any)[activeComponent]" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import LoginAccomodation from "./LoginAccomodation.vue";
import LoginAgent from "./LoginAgent.vue";
import LoginFerry from "./LoginFerry.vue";
import LoginTab from "./LoginTab.vue";

const components = { LoginFerry, LoginAgent, LoginAccomodation };
const activeComponent = ref<string>("LoginFerry");

const accommodationLoginUrl = ref(`${document.getElementById("login")?.dataset.linkAccommodation}`);
</script>

<style scoped lang="scss">
.login {
    &__tabs {
        > .row {
            margin: 0 -2px;

            @include media-breakpoint-up(sm) {
                margin: 0 -5px;
            }

            @include media-breakpoint-up(lg) {
                margin: 0 -15px;
            }

            > [class*="col-"] {
                padding: 0 2px !important;

                @include media-breakpoint-up(sm) {
                    padding: 0 5px !important;
                }

                @include media-breakpoint-up(lg) {
                    padding: 0 15px !important;
                }
            }
        }
    }

    &__content {
        padding: 30px 15px;
        border: 1px solid $c-blue-dark;
        border-radius: 8px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: #fff;

        @include media-breakpoint-up(md) {
            padding: 30px;
        }

        .booking,
        .accommodation,
        > .partner {
            display: none;
        }

        .booking {
            display: block;
        }
    }
}
</style>
