import { countries } from "@/config/countries";
import { getPhoneCode, getPhoneWithoutCode } from "@/helpers/phoneHelper";
import { AddressInformationResponse } from "@/models/api/customer/AddressInformationResponse";
import { DropdownOption } from "~/models/DropdownOption";

export type AddressInformation = {
    acceptsContact?: boolean;
    allowUpdateIslander?: boolean;
    title?: string;
    firstName: string;
    lastName: string;
    address: string;
    zip: string;
    city: string;
    county?: string;
    countryCode: string;
    country?: string;
    homePhone?: string;
    workPhone?: string;
    mobilePhone: string;
    phoneCode: string;
    internalReference?: string;
    email: string;
    contact: string;
    iban?: string;
    isIslander?: boolean;
    isCommuter?: boolean;
};

export const mapFromAddressInformationResponse = (resp: AddressInformationResponse) => {
    const split = resp.name.split(" ");

    return {
        acceptsContact: resp.acceptsContact,
        allowUpdateIslander: resp.allowUpdateIslander,
        title: resp.title,
        firstName: resp.firstName ?? split[0],
        lastName: resp.lastName ?? split[1],
        address: resp.address,
        zip: resp.zip,
        city: resp.city,
        county: resp.county,
        countryCode: resp.countryCode,
        country: getCountry(resp.countryCode),
        homePhone: resp.homePhone,
        workPhone: resp.workPhone,
        phoneCode: getPhoneCode(resp.mobilePhone),
        mobilePhone: getPhoneWithoutCode(resp.mobilePhone),
        internalReference: resp.internalReference,
        email: resp.email,
        contact: resp.contact,
        iban: resp.iban,
        isIslander: resp.isIslander,
    };
};

function getCountry(countryCode: string) {
    return countries.find((c: DropdownOption) => c.value === countryCode)?.label ?? countryCode;
}
