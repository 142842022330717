import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49ca8d7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "spinner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "bounce1",
      style: _normalizeStyle(`background-color: ${_ctx.colorCode}; width: ${_ctx.size}px; height: ${_ctx.size}px;`)
    }, null, 4),
    _createElementVNode("div", {
      class: "bounce2",
      style: _normalizeStyle(`background-color: ${_ctx.colorCode}; width:  ${_ctx.size}px; height: ${_ctx.size}px;`)
    }, null, 4),
    _createElementVNode("div", {
      class: "bounce3",
      style: _normalizeStyle(`background-color: ${_ctx.colorCode}; width:  ${_ctx.size}px; height: ${_ctx.size}px;`)
    }, null, 4)
  ]))
}