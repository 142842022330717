<template>
    <label :for="id" class="toggle-switch">
        <input :name="name" :id="id" type="checkbox" v-model="inputValue" />
        <span class="toggle-switch__slider"></span>
        <span class="toggle-switch__label">{{ label }}</span>
    </label>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
    id?: string;
    label?: string;
    modelValue: boolean;
    name?: string;
}

const props = defineProps<Props>();

const emit = defineEmits(["update:modelValue"]);

const inputValue = computed({
    get(): boolean {
        return props.modelValue ?? false;
    },
    set(value: boolean) {
        emit("update:modelValue", value);
    },
});
</script>

<style scoped lang="scss">
.toggle-switch {
    $prefix: ".toggle-switch";
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    cursor: pointer;

    input[type="checkbox"] {
        position: absolute;
        left: -9999px;
        opacity: 0;

        &:checked {
            ~ #{$prefix}__slider {
                background-color: $c-blue-200;

                &::before {
                    transform: translate3d(15px, -50%, 0);
                }
            }
        }

        &:not(:disabled):focus {
            ~ #{$prefix}__slider {
                outline: 2px solid $c-blue-200;
                outline-offset: -1px;
            }
        }
    }

    &__slider {
        width: 35px;
        height: 20px;
        background-color: $c-gray-400;
        border: 1px solid $c-gray-200;
        border-radius: 10px;
        @include transition($t-fast, "background-color");

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 2px;
            height: 16px;
            width: 16px;
            background-color: $c-white-100;
            border-radius: 100%;
            transform: translate3d(0, -50%, 0);
            @include transition($t-fast, "transform");
        }
    }
}
</style>
