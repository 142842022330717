import { YYYYMMDD } from "@/helpers/dateHelpers";
import { CardTransactionResponse } from "@/models/api/customer/CustomerCardResponse";

export class Transaction {
    date: Date;
    amount: number;
    note: string;
    currency: string;
    bookingNumber: number;
    link: string;
    constructor(resp: CardTransactionResponse) {
        this.date = new Date(YYYYMMDD(resp.date));
        this.amount = resp.amount;
        this.note = resp.note;
        this.currency = resp.currency;
        this.bookingNumber = resp.bookingNumber;
        this.link = resp.link;
    }
}
