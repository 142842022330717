import { parseDate } from "@/helpers/dateHelpers";
import { CustomerCardResponse, TravelCardResponse } from "@/models/api/customer/CustomerCardResponse";
import { Transaction } from "./Transaction";

export class CustomerCard {
    leftToPay: number;
    paid: number;
    travelCard: TravelCard;

    constructor(resp: CustomerCardResponse) {
        this.leftToPay = resp.leftToPay;
        this.paid = resp.paid;
        this.travelCard = new TravelCard(resp.travelCard);
    }
}

class TravelCard {
    status: string;
    cardName: string;
    balance: number;
    cardNumber: string;
    validFrom?: Date;
    validTo?: Date;
    active: boolean;
    transactions: Transaction[];

    constructor(resp: TravelCardResponse) {
        this.status = resp.status;
        this.cardName = resp.cardName;
        this.balance = resp.balance;
        this.cardNumber = resp.cardNumber;
        this.validFrom = resp.validFrom ? parseDate(resp.validFrom) : undefined;
        this.validTo = resp.validTo ? parseDate(resp.validTo) : undefined;
        this.active = resp.active;
        this.transactions = resp.transactions.map((t) => new Transaction(t));
    }
}
