<template>
    <form class="customer-details__form" @submit.prevent="submitForm">
        <h4>{{ $t("fields.title") }}</h4>
        <div class="row">
            <div class="col--12" v-if="includeIslander">
                <div class="input-group">
                    <p>{{ $t("fields.isIslander.label") }}</p>
                    <div class="radio-container">
                        <BaseRadio
                            v-model="v$.isIslander.$model"
                            id="islander-false"
                            name="islander"
                            :label="$t('fields.isIslander.no')"
                            :value="false"
                        />
                        <BaseRadio
                            v-model="v$.isIslander.$model"
                            id="islander-true"
                            name="islander"
                            :label="$t('fields.isIslander.yes')"
                            :value="true"
                        />
                    </div>
                    <IslanderBox v-if="v$.isIslander.$model" :hasError="v$.islanderSSN.$error" v-model.trim="v$.islanderSSN.$model" />
                </div>
            </div>
            <div class="col--12 col-md--8">
                <div class="input-group">
                    <BaseInput
                        name="firstName"
                        :label="$t('fields.firstName.label')"
                        :hasError="v$.firstName.$error"
                        :valid="!v$.firstName.$invalid"
                        v-model.trim="v$.firstName.$model"
                    />
                </div>
                <div class="input-group">
                    <BaseInput
                        name="lastName"
                        :label="$t('fields.lastName.label')"
                        :hasError="v$.lastName.$error"
                        :valid="!v$.lastName.$invalid"
                        v-model.trim="v$.lastName.$model"
                    />
                </div>
                <div class="input-group">
                    <BaseInput
                        name="contact"
                        :label="$t('fields.company.label')"
                        :hasError="v$.contact.$error"
                        :valid="!v$.contact.$invalid"
                        v-model.trim="v$.contact.$model"
                    />
                </div>
                <div class="input-group">
                    <BaseInput
                        name="address"
                        :label="$t('fields.address.label')"
                        :hasError="v$.address.$error"
                        :valid="!v$.address.$invalid"
                        v-model.trim="v$.address.$model"
                    />
                </div>
                <div class="input-group">
                    <div class="row">
                        <div class="col--4">
                            <BaseInput
                                type="tel"
                                name="zip"
                                :label="$t('fields.zip.label')"
                                :hasError="v$.zip.$error"
                                :valid="!v$.zip.$invalid"
                                v-model.trim="v$.zip.$model"
                            />
                        </div>
                        <div class="col--8">
                            <BaseInput
                                name="city"
                                :label="$t('fields.city.label')"
                                :hasError="v$.city.$error"
                                :valid="!v$.city.$invalid"
                                v-model.trim="v$.city.$model"
                            />
                        </div>
                    </div>
                </div>
                <div class="input-group">
                    <BaseCountrySelect
                        :label="$t('fields.country.label')"
                        name="country"
                        :hasError="v$.countryCode.$error"
                        :valid="!v$.countryCode.$invalid"
                        v-model="v$.countryCode.$model"
                    />
                </div>
                <div class="input-group">
                    <div class="row">
                        <div class="col--4">
                            <BaseDropdown
                                :options="countryCodes"
                                name="phoneCode"
                                :label="$t('fields.phoneCode.label')"
                                :hasError="v$.phoneCode.$error"
                                :valid="!v$.phoneCode.$invalid"
                                v-model.trim="v$.phoneCode.$model"
                                searchable
                            />
                        </div>
                        <div class="col--8">
                            <BaseInput
                                name="mobilePhone"
                                :label="$t('fields.mobilePhone.label')"
                                type="tel"
                                :hasError="v$.mobilePhone.$error"
                                :errors="v$.mobilePhone.$errors"
                                :valid="!v$.mobilePhone.$invalid"
                                v-model.trim="v$.mobilePhone.$model"
                            />
                        </div>
                    </div>
                    <p>{{ $t("fields.mobilePhone.info") }}</p>
                </div>
                <div class="input-group">
                    <BaseInput
                        name="email"
                        :label="$t('fields.email.label')"
                        :hasError="v$.email.$error"
                        :valid="!v$.email.$invalid"
                        v-model.trim="v$.email.$model"
                    />
                </div>
            </div>
            <div class="col--12 col-md--8" v-if="includePassword">
                <div class="customer-details__password">
                    <h4>{{ $t("fields.password.title") }}</h4>
                    <div class="input-group">
                        <BaseInput
                            :hasError="v$.password.$error"
                            :valid="!v$.password.$invalid"
                            v-model.trim="v$.password.$model"
                            type="password"
                            name="password"
                            :label="$t('fields.password.label')"
                        />
                    </div>
                    <div class="input-group">
                        <BaseInput
                            :hasError="v$.passwordRepeat.$error"
                            :valid="!v$.passwordRepeat.$invalid"
                            v-model.trim="v$.passwordRepeat.$model"
                            type="password"
                            name="repeatPassword"
                            :label="$t('fields.passwordRepeat.label')"
                        />
                    </div>
                </div>
            </div>
            <div class="col--12 col-md--8">
                <BaseAlert v-if="error" :message="error.message" type="danger" />
                <div class="button-container">
                    <BaseButton :loading="isLoading" class="btn--secondary btn--fixed-width">
                        <span>{{ includePassword ? $t("button.register") : $t("button.continue") }}</span>
                    </BaseButton>
                </div>
            </div>
        </div>
    </form>
</template>

<script lang="ts" setup>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseRadio from "@/components/base/BaseRadio.vue";
import IslanderBox from "@/components/customer/IslanderBox.vue";
import { countryCodes } from "@/config/countries";
import { getBookitLocaleStringForCurrentLocale, getPhoneCodeForCurrentLocale } from "@/helpers/localeHelper";
import { format } from "@/helpers/phoneHelper";
import { email, required } from "@/helpers/validators";
import { AddressInformation } from "@/models/front/customer/AddressInformation";
import useVuelidate from "@vuelidate/core";
import { reactive } from "vue";
import BaseAlert from "../base/BaseAlert.vue";
import BaseCountrySelect from "../base/BaseCountrySelect.vue";
import BaseDropdown from "../base/BaseDropdown.vue";
import { minLength } from "@vuelidate/validators";

interface Props {
    includeIslander?: boolean;
    includePassword?: boolean;
    address?: AddressInformation;
    error?: Error;
    isLoading?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits(["submit"]);

const validPassword = () => {
    return !props.includePassword || state.password.length >= 6;
};

const state = reactive({
    isIslander: false,
    islanderSSN: "",
    firstName: props.address?.firstName,
    lastName: props.address?.lastName,
    contact: props.address?.contact,
    address: props.address?.address,
    zip: props.address?.zip,
    city: props.address?.city,
    countryCode: props.address?.countryCode ? props.address?.countryCode : getBookitLocaleStringForCurrentLocale(),
    phoneCode: props.address?.phoneCode ? props.address?.phoneCode : getPhoneCodeForCurrentLocale(),
    mobilePhone: props.address?.mobilePhone ?? "",
    email: props.address?.email,
    password: "",
    passwordRepeat: "",
});

const rules = {
    isIslander: {},
    islanderSSN: {
        v: (value: string) => !state.isIslander || value.length == 12,
    },
    firstName: { required },
    lastName: { required },
    contact: {},
    address: { required },
    zip: { required },
    city: { required },
    countryCode: { required },
    phoneCode: { required },
    mobilePhone: { required, minLength: minLength(6) },
    email: { required, email },
    password: { v: validPassword },
    passwordRepeat: { v: (val: string) => !props.includePassword || val === state.password },
};

const v$ = useVuelidate(rules, state);

const submitForm = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    emit("submit", { ...state, mobilePhone: format(state.mobilePhone, state.phoneCode) });
};
</script>

<style lang="scss" scoped>
.customer-details {
    $prefix: ".customer-details";

    #{$prefix}__form {
        display: block;

        :deep() .row {
            margin: 0 -5px;

            > [class*="col-"] {
                padding: 0 5px !important;

                + [class*="col--12"] {
                    margin-top: $space-md;
                }
            }
        }

        > h4 {
            margin-bottom: 20px;
        }
    }

    #{$prefix}__password {
        > h4 {
            margin-bottom: 2px;
            font-size: $fs-18;
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
    }

    .radio-container {
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        :deep() .radio-button {
            + .radio-button {
                margin-top: 10px;

                @include media-breakpoint-up(md) {
                    margin-top: 0;
                    margin-left: 10px;
                }
            }
        }
    }
}

.input-group {
    $prefix: ".input-group";

    > * + p {
        margin-top: 10px;
    }

    + #{$prefix} {
        margin-top: 10px;
    }
}
</style>
