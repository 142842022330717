import { CoPassengerResponse } from "~/models/api/customer/CoPassengerResponse";
import { countries } from "~/config/countries";
import { DropdownOption } from "~/models/DropdownOption";
import { PassengerType } from "~/models/Enums";
import { parseDate } from "@/helpers/dateHelpers";

export class CoPassenger {
    title: string;
    firstName: string;
    lastName: string;
    countryCode: string;
    country: string;
    dateOfBirth: Date;
    gender: string;
    note: string;
    email: string;
    passengerType: PassengerType;
    coPaxId: string;
    delete: Boolean;
    frequentTravellerNumber: string;
    name: string;
    needsValidation: boolean;
    isEditable: boolean;

    constructor(resp: CoPassengerResponse) {
        this.title = resp.title;
        this.firstName = resp.firstName;
        this.lastName = resp.lastName;
        this.countryCode = resp.countryCode;
        this.country = this.getCountry();
        this.dateOfBirth = parseDate(resp.dateOfBirth);
        this.gender = resp.gender;
        this.note = resp.note;
        this.email = resp.email;
        this.title = resp.title;
        this.passengerType = resp.passengerType;
        this.coPaxId = resp.coPaxId;
        this.delete = resp.delete;
        this.frequentTravellerNumber = resp.frequentTravellerNumber;
        this.name = resp.name;
        this.needsValidation = resp.needsValidation;
        this.isEditable = resp.isEditable;
    }

    getCountry() {
        return countries.find((c: DropdownOption) => c.value === this.countryCode)?.label ?? this.countryCode;
    }
}
