import useApi from "@/composables/useApi";
import { CheckoutResponse } from "@/models/api/CheckoutResponse";
import { InitPaymentResponse } from "@/models/api/InitPaymentResponse";
import { PaymentGiftCardDto } from "@/models/api/giftCard/PaymentGiftCardDto";
import { Checkout } from "@/models/front/Checkout";
import { RefundParam } from "@/modules/MyPages/composables/useCancelBooking";
import { useBookingStore } from "@/store/booking";
import { useMutation, useQuery } from "vue-query";

type SavedCardRequest = {
    selectedCard?: string;
    saveCard: boolean;
};

export type InitPaymentParams = {
    type: string;
    bookingNumber: number;
    paymentMethod?: string;
    terms: boolean;
    giftCards: PaymentGiftCardDto[];
    creditCard?: SavedCardRequest;
    refund?: RefundParam;
};

const useCheckoutQuery = (type: string, id?: string) => {
    const { get, post } = useApi();
    const bookingStore = useBookingStore();

    const fetcher = () => {
        return get<CheckoutResponse>(`checkout/${type}`, { id }).then((resp) => {
            const data = new Checkout(resp.data);
            bookingStore.setBookingNumber(data.bookingNumber);
            return data;
        });
    };

    const getCancelUrl = (): string => {
        const baseUrl = window.location.href.split("?")[0];
        const param = location.search.length > 0 ? "&" : "?";
        return `${baseUrl}${location.search}${param}error=paymentCancelled`;
    };

    const initPaymentMutation = useMutation((params: InitPaymentParams) => {
        return post<InitPaymentResponse>((type === "moto" ? type : "confirm") + "/init", {
            bookingNumber: params.bookingNumber,
            giftCards: params.giftCards,
            terms: params.terms,
            cancelUrl: getCancelUrl(),
            creditCard: params.creditCard,
            ...(params.paymentMethod ? { paymentMethod: params.paymentMethod } : {}),
            ...(params.refund ? { refundMethod: params.refund } : {}),
            type: params.type,
        }).then((resp) => resp.data);
    });

    return {
        ...useQuery("checkout", fetcher, {
            staleTime: 1000,
            retry: false,
            refetchOnWindowFocus: false,
        }),
        initPaymentMutation,
    };
};

export default useCheckoutQuery;
