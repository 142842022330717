import { useSiteSearchStore } from '@/store/siteSearch';
import useSiteSearchQuery from '@/modules/SiteSearch/composables/useSiteSearchQuery';

const useSiteSearch = () => {
	const siteSearchStore = useSiteSearchStore();

	const goToPage = () => {
		const url = document.getElementById('sitesearchwidget')?.dataset.link ?? '/site-search';
		window.location.href = `${url}?query=${siteSearchStore.getQuery}`;
	};

	return { goToPage, ...useSiteSearchQuery() };
};

export default useSiteSearch;
