import i18n from "./i18n";

export const genders = [
    {
        label: i18n.global.t('genders.M'),
        value: "M"
    },
    {
        label: i18n.global.t('genders.F'),
        value: "F"
    },
]