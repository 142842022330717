<template>
    <section class="login-form">
        <header v-if="title">
            <h4>{{ title }}</h4>
            <p v-if="subtitle">{{ subtitle }}</p>
        </header>
        <form @submit.prevent="loginBooking">
            <div class="row">
                <div class="col--12 col-sm--6 col-md--4">
                    <BaseInput
                        :label="$t('login.form.ferryLogin.bookingNumber.label')"
                        type="tel"
                        name="bookingnumber"
                        :hasError="v$.bookingForm.bookingNumber.$error"
                        :errors="v$.bookingForm.bookingNumber.$errors"
                        v-model.trim="v$.bookingForm.bookingNumber.$model"
                    />
                </div>
                <div class="col--12 col-sm--6 col-md--3">
                    <BaseInput
                        :label="$t('login.form.ferryLogin.bookingNumber.phoneNumber')"
                        type="tel"
                        name="phone"
                        :hasError="v$.bookingForm.phoneNumber.$error"
                        :errors="v$.bookingForm.phoneNumber.$errors"
                        v-model.trim="v$.bookingForm.phoneNumber.$model"
                    />
                </div>
                <div class="col--12 col-md--5">
                    <div class="button-container">
                        <BaseButton :loading="loadingForm" :disabled="loadingForm" class="btn--secondary btn--fixed-width">
                            <span>{{ $t("button.login") }}</span>
                        </BaseButton>
                    </div>
                </div>
                <div class="col--12" v-if="loginError?.length > 0">
                    <BaseMessages :messages="[loginError]" />
                </div>
            </div>
        </form>
    </section>
</template>

<script lang="ts" setup>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseMessages from "@/components/base/BaseMessages.vue";
import useLogin from "@/composables/login/useLogin";
import { numeric, required } from "@/helpers/validators";
import useVuelidate from "@vuelidate/core";
import { reactive, ref } from "vue";

interface Props {
    title: string;
    subtitle?: string;
}

defineProps<Props>();

const loadingForm = ref<boolean>(false);
const { bookingLogin } = useLogin();

const state = reactive({
    bookingForm: {
        bookingNumber: undefined,
        phoneNumber: "",
    },
});

const rules = {
    bookingForm: {
        phoneNumber: { required },
        bookingNumber: { required, numeric },
    },
};

const v$ = useVuelidate(rules, state);

const loginError = ref<string>("");

const emit = defineEmits(["onSuccess"]);

const loginBooking = async () => {
    const isValidForm = await v$.value.bookingForm.$validate();

    if (!isValidForm) return;

    loadingForm.value = true;
    if (state.bookingForm.bookingNumber) {
        bookingLogin(state.bookingForm.bookingNumber, state.bookingForm.phoneNumber)
            .then((resp) => {
                console.log("emit success");
                emit("onSuccess", resp);
            })
            .catch((err) => {
                loginError.value = err.message;
            })
            .finally(() => {
                loadingForm.value = false;
            });
    }
};
</script>

<style lang="scss" scoped>
@import "~/styles/components/login-form.scss";
</style>
