<template>
    <MaintenanceWrapper hideAnimation class="ferry-login">
        <div class="ferry-login__group">
            <LoginUser
                force
                :title="$t('login.form.ferryLogin.normal.title')"
                :subtitle="$t('login.form.ferryLogin.registration.subtitle')"
                @onSuccess="redirect"
            />
        </div>
        <div class="ferry-login__group">
            <LoginBooking
                :title="$t('login.form.ferryLogin.bookingNumber.title')"
                :subtitle="$t('login.form.ferryLogin.bookingNumber.subtitle')"
                @onSuccess="redirectToBooking"
            />
        </div>
        <div class="ferry-login__group">
            <a @click="showSignUp = !showSignUp" class="registration-link" href="#">
                <SvgIcon icon="booking/avatar" />
                <div class="registration-link__text">
                    <h4>{{ $t("login.form.ferryLogin.registration.title") }}</h4>
                    <p>{{ $t("login.form.ferryLogin.registration.subtitle") }}</p>
                </div>
            </a>
            <div class="customer-details" v-if="showSignUp">
                <CustomerDetailsForm @submit="registerCustomer" includeIslander includePassword :error="error" :isLoading="isCreatingCustomer" />
            </div>
        </div>
    </MaintenanceWrapper>
</template>

<script lang="ts" setup>
import SvgIcon from "@/components/base/SvgIcon.vue";
import CustomerDetailsForm from "@/components/customer/CustomerDetailsForm.vue";
import MaintenanceWrapper from "@/components/maintenance/MaintenanceWrapper.vue";
import useCreateAccount from "@/composables/login/useCreateAccount";
import { getQueryParamValue } from "@/helpers/urlHelper";
import { OpenBookingResult } from "@/models/api/OpenBookingResult";
import { AddressInformation } from "@/models/front/customer/AddressInformation";
import { computed, ref } from "vue";
import LoginBooking from "./LoginBooking.vue";
import LoginUser from "./LoginUser.vue";

const showSignUp = ref(false);

const redirectToBooking = (resp: OpenBookingResult) => {
    window.location.href = resp.url;
};

const campaignParam = getQueryParamValue("campaign");
const mmidParam = getQueryParamValue("mmid");
const voucherParam = getQueryParamValue("voucher");

const hasCampaignRoute = computed(()=> !!campaignParam.length || !!mmidParam.length || !!voucherParam.length)

const redirect = (url: string) => {
    if(hasCampaignRoute.value){
        window.location.reload();    
    }
    else if (process.env.NODE_ENV === "production") {
        window.location.href = url;
    } else {
        window.location.href = "/my-pages";
    }
};

const { createAccount, isLoading: isCreatingCustomer, error } = useCreateAccount();

const registerCustomer = (state: AddressInformation) => {
    createAccount(state).then(() => {
        redirect(`${document.getElementById("login")?.dataset.link}`);
    });
};
</script>

<style lang="scss" scoped>
.ferry-login {
    $prefix: ".ferry-login";

    #{$prefix}__group {
        + #{$prefix}__group {
            margin-top: 30px;
        }
    }

    .registration-link {
        display: flex;
        align-items: center;
        gap: 10px;
        color: $c-black-100;

        :deep(.icon) {
            width: 34px;
        }

        &__text {
            position: relative;
            margin-bottom: -3px;

            &::after {
                content: "";
                display: block;
                position: absolute;
                right: -18px;
                bottom: 6px;
                width: 10px;
                height: 10px;
                border: 2px solid $c-black;
                border-right: 0;
                border-bottom: 0;
                transform: rotate(135deg);
            }

            p {
                display: none;

                @include media-breakpoint-up(sm) {
                    display: block;
                }
            }
        }
    }

    .customer-details {
        margin-top: 20px;
        background-color: $c-gray-300;
        padding: 20px;
    }
}
</style>
