import { storageWithExpiration } from "@/helpers/storageHelper";
import { Direction } from "@/models/Enums";
import { ExtrasResource, ExtrasSection } from "@/models/Extras";
import { ResourceState } from "@/models/store/BookingState";
import { defineStore } from "pinia";

const getInitialState = () => {
    return {
        outward: [] as ResourceState[],
        return: [] as ResourceState[],
    };
};

export const useResourceStore = defineStore("resource", {
    state: () => {
        return getInitialState();
    },
    getters: {
        getResources:
            (state) =>
            (direction: Direction): ResourceState[] => {
                if (direction === Direction.Outward) {
                    return state.outward as ResourceState[];
                }
                return state.return as ResourceState[];
            },
        getResourceState: (state) => {
            return state;
        },
    },
    actions: {
        setResource(direction: Direction, resource: ExtrasResource, value: number) {
            const resourceArray = this.getResources(direction);
            const count = resourceArray.filter((p) => p.identifier === resource.identifier).length;
            if (count > value) {
                this.removeResource(direction, resource, value);
            } else {
                this.addResource(direction, resource, value);
            }
        },
        addResource(direction: Direction, resource: ExtrasResource, value: number) {
            if (value === 0) return;

            for (let i = 0; i < value; i++) {
                if (direction === Direction.Outward) {
                    if (!this.outward.find((p) => p.identifier === resource.identifier && p.number === i + 1)) {
                        this.outward.push({ id: resource.id, identifier: resource.identifier, code: resource.code, number: i + 1 });
                    }
                } else {
                    if (!this.return.find((p) => p.identifier === resource.identifier && p.number === i + 1)) {
                        this.return.push({ id: resource.id, identifier: resource.identifier, code: resource.code, number: i + 1 });
                    }
                }
            }
        },
        removeResource(direction: Direction, resource: ExtrasResource, value: number) {
            if (direction === Direction.Outward && this.outward.length > 0) {
                this.outward = this.outward.filter((p) => p.number <= value || p.identifier !== resource.identifier);
            } else if (this.return.length > 0) {
                this.return = this.return.filter((p) => p.number <= value || p.identifier !== resource.identifier);
            }
        },
        setResources(direction: Direction, value: ResourceState[]) {
            if (direction === Direction.Outward) {
                this.outward = value;
            } else if (direction === Direction.Return) {
                this.return = value;
            }
        },
        setFromApi(direction: Direction, sections: ExtrasSection[]) {
            const mapState = (r: ExtrasResource): ResourceState[] => {
                const resources: ResourceState[] = [];
                for (let i = 1; i <= r.amount; i++) {
                    resources.push({
                        id: r.id,
                        identifier: r.identifier,
                        code: r.code,
                        number: i,
                    });
                }

                return resources;
            };

            if (direction === Direction.Outward) {
                this.outward = sections.flatMap((s) => s.resources).flatMap((r) => mapState(r as ExtrasResource)) ?? [];
            } else if (direction === Direction.Return) {
                this.return = sections.flatMap((s) => s.resources).flatMap((r) => mapState(r as ExtrasResource)) ?? [];
            }
        },
    },
    persist: {
        storage: storageWithExpiration(localStorage),
    },
});
