<template>
    <BaseSection class="my-profile">
        <template #info>
            <header>
                <h4>{{ username }}</h4>
            </header>
        </template>
        <BasePanel>
            <CustomerCardInfo v-if="isCardCustomer" class="my-profile__card" />
            <BaseButton class="btn--transparent my-profile__link" icon="booking/logout" @click="showAlertBox = true">
                {{ $t("button.logout") }}
            </BaseButton>
        </BasePanel>
        <MyProfileLogOut v-model="showAlertBox" />
    </BaseSection>
</template>

<script setup lang="ts">
import BaseButton from "@/components/base/BaseButton.vue";
import BasePanel from "@/components/base/BasePanel.vue";
import BaseSection from "@/components/base/BaseSection.vue";
import { useUserStore } from "@/store/user";
import { computed, ref } from "vue";
import useCustomerCardsQuery from "../composables/useCustomerCardsQuery";
import CustomerCardInfo from "./CustomerCardInfo.vue";
import MyProfileLogOut from "./MyProfileLogOut.vue";

interface Props {
    username?: string;
}

defineProps<Props>();

const { data } = useCustomerCardsQuery();

const showAlertBox = ref(false);
const userStore = useUserStore();

const user = computed(() => {
    return userStore.user;
});

const isCardCustomer = computed(() => !!data.value && user.value.isCardCustomer);
</script>

<style lang="scss" scoped>
.my-profile {
    header {
        padding-left: $space-lg;
        padding-top: $space-xxl;

        h4 {
            font-weight: 400;
        }
    }

    &__card {
        border-bottom: 1px solid $c-gray-100;
        margin-bottom: 15px;
    }

    &__link {
        :deep(svg) {
            margin-left: 10px;
        }
    }
}
</style>
