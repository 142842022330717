<template>
    <div class="room">
        <div class="room__row" :class="{ oneRoom: !allowMultipleRooms }">
            <label v-if="allowMultipleRooms"
                ><strong>{{ $t("onebasket.accommodation.search.rooms.room") }} {{ index }}</strong></label
            >
            <BaseButton type="button" class="btn--transparent" v-if="deletable" @click="remove">{{
                $t("onebasket.accommodation.search.rooms.remove")
            }}</BaseButton>
        </div>
        <div class="room__row">
            <label>{{ $t("onebasket.accommodation.search.rooms.adults") }}</label>
            <BaseQuantity :id="'room1'" :name="'room1'" :minValue="1" :maxValue="9" v-model="adults" />
        </div>
        <div class="room__row">
            <label>{{ $t("onebasket.accommodation.search.rooms.children") }}</label>
            <BaseQuantity :id="'room2'" :name="'room2'" :minValue="0" :maxValue="9" v-model="children" />
        </div>
        <div class="age-container">
            <div class="room__row" v-for="(i, index) in childrenAges" :key="index">
                <label
                    >{{ $t("onebasket.accommodation.search.rooms.age") }} {{ $t("onebasket.accommodation.search.rooms.child") }}
                    {{ index + 1 }}</label
                >
                <ChildAge :id="index" @ageChanged="handleAge" :age="i" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import BaseQuantity from "@/components/base/BaseQuantity.vue";
import { repeat } from "@/composables/useUtilities";
import { Room } from "@/models/front/rooms/Room";
import { computed, ref } from "vue";
import BaseButton from "../base/BaseButton.vue";
import ChildAge from "../onebasket/accommodation-search/AccommodationSearchChildAge.vue";

interface Props {
    id: string;
    index: number;
    deletable: boolean;
    room: Room;
    allowMultipleRooms: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:room", "onRemove"]);

const childrenAges = ref<number[]>(props.room.childrenAges ?? []);

const updateAges = () => {
    emit("update:room", { ...props.room, childrenAges: childrenAges.value });
};

const adults = computed({
    get(): number {
        return props.room.adults;
    },
    set(value: number) {
        emit("update:room", { ...props.room, adults: value });
    },
});

const handleAge = (a: number, b: number) => {
    childrenAges.value[b] = a;

    updateAges();
};

const children = computed({
    get(): number {
        return props.room.childrenAges?.length ?? 0;
    },
    set(value: number) {
        if (childrenAges.value.length > value) {
            childrenAges.value.splice(value);
        } else if (childrenAges.value.length < value) {
            childrenAges.value.push(...repeat<number>(value - childrenAges.value.length, 10));
        }

        updateAges();
    },
});

const remove = () => {
    emit("onRemove", props.id);
};
</script>

<style lang="scss" scoped>
.room {
    $room: &;
    border-bottom: 1px solid $c-gray;

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 42px;
    }

    .btn--transparent {
        padding: 0;
    }

    + #{$room} {
        margin-top: $space-sm;
    }

    .age-container {
        margin-top: $space-sm;
    }
}

.oneRoom {
    display: none;
}
</style>
