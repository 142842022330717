import useApi from "@/composables/useApi";
import { UserType } from "@/models/Enums";
import { CoVehicleResponse } from "@/models/api/customer/CoVehiclesResponse";
import { CoVehicle } from "@/models/front/customer/CoVehicles";
import { useUserStore } from "@/store/user";
import { computed } from "vue";
import { useMutation, useQuery, useQueryClient } from "vue-query";

const useMyCoVehiclesQuery = () => {
    const userStore = useUserStore();
    const { get, post } = useApi();
    const queryClient = useQueryClient();

    const fetcher = () => {
        return get<CoVehicleResponse[]>("customer/covehicles", "").then((resp) => resp.data?.map((d) => new CoVehicle(d)) ?? []);
    };

    const updateMutation = useMutation(
        (req: CoVehicleResponse[]) => {
            return post("customer/covehicles", { vehicles: req });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("myCoVehicles");
            },
        }
    );

    return {
        ...useQuery(["myCoVehicles", userStore.getUser.number], fetcher, {
            staleTime: Infinity,
            enabled: computed(() => userStore.getUserType === UserType.Customer),
        }),
        updateMutation,
    };
};

export default useMyCoVehiclesQuery;
