export function storageWithExpiration(storage: Storage, ttl = 1500000) {
    return {
        setItem(key: string, value: string): void {
            const now = new Date();
            const item = {
                value,
                expiry: now.getTime() + ttl,
            };

            storage.setItem(key, JSON.stringify(item));
        },
        getItem(key: string): string {
            const itemStr = storage.getItem(key);
            // if the item doesn't exist
            if (!itemStr) {
                return "";
            }
            try {
                const item = JSON.parse(itemStr);
                const now = new Date();
                // compare the expiry time of the item with the current time
                if (now.getTime() > item.expiry) {
                    // If the item is expired, delete the item from storage
                    storage.removeItem(key);
                    return "";
                }
                return item.value;
            } catch (err: any) {
                storage.removeItem(key);
                return "";
            }
        },
        removeItem(key: string) {
            storage.removeItem(key);
        },
    };
}
