import { eventBusKey } from "@/models/Symbols/Eventbus";
import { loginModalKey } from "@/models/Symbols/LoginModal";
import { inject, onMounted, ref } from "vue";

const isOpen = ref(false);

const useLoginModal = () => {
    const loginRef = ref();
    const eventBus = inject(eventBusKey);

    eventBus?.on(loginModalKey, (e: any) => {
        isOpen.value = !!e;
    })

    const showLoginModal = (event: Event) => {
        event.preventDefault();
        eventBus?.emit(loginModalKey, true);
    };

    onMounted(() => {
        // adding some delay here because the i18n-string is not inserted instantly..
        setTimeout(() => {
            if (!loginRef.value) return;
            loginRef.value.querySelectorAll("a").forEach((item: HTMLElement) => {
                item.addEventListener("click", showLoginModal);
            });
        }, 500);
    });

    return {
        loginRef,
        isOpen
    }
}

export default useLoginModal;