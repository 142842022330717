<template>
	<a :href="linkUrl" class="tab" :class="{ active: active }">
		<SvgIcon :icon="icon" />
		<h3>{{ title }}</h3>
		<p>{{ subtitle }}</p>
	</a>
</template>

<script lang="ts">
import SvgIcon from "@/components/base/SvgIcon.vue";
import { defineComponent } from "vue";

export default defineComponent({
	components: {
		SvgIcon
	},
	props: {
		title: String,
		subtitle: String,
		icon: String,
		id: String,
		active: Boolean,
		linkUrl: {
			type: String,
			required: false,
			default: '#'
		}
	}
})
</script>

<style lang="scss" scoped>
.tab {
	display: block;
	position: relative;
	padding: 10px 0;
	height: 100%;
	border: 1px solid $c-blue-dark;
	border-bottom: none;
	border-radius: 8px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	background-color: $c-blue-dark;
	color: $c-white;
	text-align: center;
	text-decoration: none;

	@include media-breakpoint-down(sm) {
		display: flex;
		flex-direction: column;
	}

	@include media-breakpoint-up(sm) {
		padding: 24px 0;
	}

	h3 {
		display: none;
		@include font(h4);

		@include media-breakpoint-up(sm) {
			display: block;
		}
	}

	p {
		@include media-breakpoint-down(sm) {
			font-size: $fs-12;
		}
	}

	.icon {
		$icon: ".icon";
		$icon-size: 32px;

		display: block;
		margin-bottom: 10px;
		width: 100%;
		height: calc(#{$icon-size} - 8px);

		@include media-breakpoint-up(sm) {
			height: $icon-size;
		}
	}

	&.active {
		background-color: $c-white;
		color: $c-black;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: -2px;
			width: 100%;
			height: 3px;
			background-color: $c-white;
			z-index: 1;
		}

		&::after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: -1px;
			width: 60%;
			height: 6px;
			background-color: $c-blue-dark;
			transform: translate(-50%);
			z-index: 1;
		}
	}
}
</style>