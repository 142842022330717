import { UserType } from "@/models/Enums";
import { LoginRequest } from "@/models/api/LoginRequest";
import { AgentLoginResponse, CustomerLoginResponse } from "@/models/api/LoginResponse";
import { OpenBookingResult } from "@/models/api/OpenBookingResult";
import { User, mapFromAgentLoginResponse, mapFromCustomerLoginResponse } from "@/models/store/User";
import { useBookingStore } from "@/store/booking";
import { useUserStore } from "@/store/user";
import { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { inject, ref } from "vue";
import useApi from "../useApi";
import { eventBusKey } from "@/models/Symbols/Eventbus";
import { whoamiKey } from "@/config/storageKeys";

const useLogin = () => {
    const { post, removeToken, get } = useApi({ displayErrors: false });
    const userStore = useUserStore();
    const isLoading = ref(false);
    const bookingStore = useBookingStore();

    const eventBus = inject(eventBusKey);

    const setCookies = (firstName?: string) => {
        if (firstName) {
            Cookies.set(whoamiKey, firstName);
            eventBus?.emit("update-whoami", firstName);
        }
    };

    const bookingLogin = (bookingNumber: number, phone: string) => {
        isLoading.value = true;
        return post<OpenBookingResult>("auth/phone", {
            bookingNumber,
            phoneNumber: phone,
        })
            .then((resp) => {
                setUserState({
                    type: UserType.Booking,
                    mobilePhone: resp.data.phone,
                });

                bookingStore.setFromApi(resp.data, true);
                return resp.data;
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    const agentLogin = (email: string, password: string, force: boolean) => {
        isLoading.value = true;
        return post<AgentLoginResponse>("auth/agent", {
            userName: email,
            password,
            force,
        } as LoginRequest)
            .then((resp) => {
                const agentUser = mapFromAgentLoginResponse(resp.data.agent);

                setUserState(agentUser);

                return { agent: agentUser, url: resp.data.url };
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    const customerLogin = (email: string, password: string, force: boolean): Promise<AxiosResponse<CustomerLoginResponse>> => {
        isLoading.value = true;
        return post<CustomerLoginResponse>("auth/customer", {
            userName: email,
            password,
            force,
        } as LoginRequest)
            .then((resp) => {
                // 202 if product code mismatch (need to login before starting booking process)
                if (force || resp.status === 202) {
                    bookingStore.resetBookingState();
                }

                setUserState(mapFromCustomerLoginResponse(resp.data.customer));
                return resp;
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    const logoutBooking = () => {
        isLoading.value = true;
        get("logout", "")
            .finally(() => {
                removeToken();
                userStore.logout();
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    const setUserState = (user: User) => {
        eventBus?.emit("user-login", user);
        userStore.login(user);
        setCookies(user.firstName ?? user.name);
    };

    return {
        isLoading,
        bookingLogin,
        logoutBooking,
        agentLogin,
        setUserState,
        customerLogin,
    };
};

export default useLogin;
