<template>
    <div id="booking-login" class="login-form" v-if="false">
        <form @submit.prevent="submitForm">
            <header>
                <h4>{{ $t("login.form.accomodationLogin.title") }}</h4>
                <p>{{ $t("login.form.accomodationLogin.subtitle") }}</p>
            </header>
            <div class="form__group">
                <div class="row">
                    <div class="col--12 col-sm--6 col-md--4">
                        <BaseInput
                            :label="$t('login.form.email')"
                            type="email"
                            name="email"
                            :hasError="v$.email.$error"
                            :errors="v$.email.$errors"
                            v-model.trim="v$.email.$model"
                        />
                    </div>
                    <div class="col--12 col-sm--6 col-md--3">
                        <BaseInput
                            :label="$t('login.form.password')"
                            type="password"
                            name="password"
                            :hasError="v$.password.$error"
                            :errors="v$.password.$errors"
                            v-model.trim="v$.password.$model"
                        />
                    </div>
                    <div class="col--12 col-md--5">
                        <div class="button-container">
                            <BaseButton :loading="loading" :disabled="loading" class="btn--primary">{{ $t("button.login") }}</BaseButton>
                            <a href="#" class="link">{{ $t("login.form.forgottenPassword") }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts" setup>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import { email, required } from "@/helpers/validators";
import useVuelidate from "@vuelidate/core";
import { reactive, ref } from "vue";

const loading = ref<boolean>(false);

const state = reactive({
    email: "",
    password: "",
});

const rules = {
    email: { required, e: email },
    password: { required },
};

const v$ = useVuelidate(rules, state);

const submitForm = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    loading.value = true;
};
</script>

<style lang="scss" scoped>
@import "~/styles/components/login-form.scss";
</style>
