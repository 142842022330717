import { PassengerType } from "@/models/Enums";
import i18n from "~/config/i18n";

interface PassengerOption {
    value: string;
    label: string;
    min: number;
    max: number;
    type: string;
}

export const passengerOptions = [
    {
        value: PassengerType.Adult,
        label: i18n.global.t("passengerType.A"),
        min: 26,
        type: 'standard',
    },
    {
        value: PassengerType.Teen,
        label: i18n.global.t("passengerType.T"),
        min: 13,
        max: 18,
        type: 'standard',
    },
    {
        value: PassengerType.Youth,
        label: i18n.global.t("passengerType.U"),
        min: 19,
        max: 25,
        type: 'standard',
    },
    {
        value: PassengerType.Child,
        label: i18n.global.t("passengerType.C"),
        min: 3,
        max: 12,
        type: 'standard',
    },
    {
        value: PassengerType.Infant,
        label: i18n.global.t("passengerType.I"),
        min: 0,
        max: 2,
        type: 'standard',
    },
    {
        value: PassengerType.Senior,
        label: i18n.global.t("passengerType.P"),
        min: 26,
        type: 'standard',
    },
    {
        value: PassengerType.Student,
        label: i18n.global.t("passengerType.S"),
        min: 16,
        type: 'standard',
    },
    {
        value: PassengerType.Commuter,
        label: i18n.global.t("passengerType.F"),
        min: 0,
        type: 'commuter'
    },
    {
        value: PassengerType.DiscountCard,
        label: i18n.global.t('passengerType.K'),
        min: 0,
        type: 'commuter'
    },
    {
        value: PassengerType.CabinCommuter,
        label: i18n.global.t("passengerType.H"),
        min: 0,
        type: 'commuter'
    },
    {
        value: PassengerType.CarPackage,
        label: i18n.global.t("passengerType.M"),
        min: 0,
        type: 'strange',
    }
] as PassengerOption[];
