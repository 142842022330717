<template>
	<div class="booking-continue" v-if="$slots.default">
        <slot :nextStep="nextStep"></slot>
	</div>
</template>


<script setup lang="ts">
import useBookingNavigation from '../../composables/useBookingNavigation';


const { nextStep } = useBookingNavigation();
</script>

<style lang="scss" scoped>
.booking-continue {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    :deep() .btn {
        min-width: 200px;
    }
}
</style>