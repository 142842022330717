<template>
    <form class="send-sms" @submit.prevent="submitForm">
        <div class="send-sms__field">
            <BaseDropdown
                :label="$t('fields.phoneCode.label')"
                name="phoneCode"
                :hasError="v$.phoneCode.$error"
                :errors="v$.phoneCode.$errors"
                v-model="v$.phoneCode.$model"
                :options="countryCodes"
                searchable
            />
        </div>
        <div class="send-sms__field">
            <BaseInput
                :label="$t('fields.mobilePhone.label')"
                type="tel"
                name="phone"
                :hasError="v$.phoneNumber.$error"
                :errors="v$.phoneNumber.$errors"
                v-model.trim="v$.phoneNumber.$model"
            />
        </div>
        <div class="send-sms__field">
            <BaseButton :loading="isLoading">{{ $t("button.send") }}</BaseButton>
        </div>
    </form>
    <div class="send-sms__alert">
        <BaseAlert type="danger" v-if="error" :message="error" noMargin />
        <BaseAlert type="success" v-else-if="success" :message="$t('booking.thankYou.sms.success')" noMargin />
    </div>
</template>

<script lang="ts" setup>
import useSendMessage from "@/composables/useSendMessage";
import { countryCodes } from "@/config/countries";
import { format } from "@/helpers/phoneHelper";
import { integer, required } from "@/helpers/validators";
import { MessageType } from "@/models/Enums";
import useVuelidate from "@vuelidate/core";
import { reactive, ref } from "vue";
import BaseButton from "~/components/base/BaseButton.vue";
import BaseInput from "~/components/base/BaseInput.vue";
import BaseAlert from "./base/BaseAlert.vue";
import BaseDropdown from "./base/BaseDropdown.vue";

interface Props {
    phoneCode?: string;
    phoneNumber?: string;
    type?: MessageType;
    bookingNumber: number;
    accessToken: string;
}

const props = withDefaults(defineProps<Props>(), {
    type: MessageType.BookingInfo,
});

const state = reactive({
    phoneCode: props.phoneCode ?? "",
    phoneNumber: props.phoneNumber ?? "",
});

const rules = {
    phoneCode: { required },
    phoneNumber: { required, integer },
};

const v$ = useVuelidate(rules, state);

const { sendSms, isLoading } = useSendMessage();
const success = ref(false);
const error = ref("");

const submitForm = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;
    error.value = "";
    success.value = false;

    const req = {
        bookingNumber: props.bookingNumber,
        accessToken: props.accessToken,
        type: MessageType[props.type],
        phoneNumber: format(state.phoneNumber as string, state.phoneCode as string),
        message: "",
    };

    sendSms(req)
        .then(() => {
            success.value = true;
        })
        .catch((err: Error) => {
            error.value = err.message;
        });
};
</script>

<style lang="scss" scoped>
.send-sms {
    @include make-row();
    &__field {
        @include make-col-ready();
        @include make-col(12);
        padding: 5px 15px;

        @include media-breakpoint-down(md) {
            &:last-child {
                display: flex;
                justify-content: flex-end;
            }
        }

        @include media-breakpoint-up(sm) {
            @include make-col(5);

            &:first-child {
                @include make-col(4);
            }

            &:last-child {
                @include make-col(3);
            }
        }

        :deep(.btn) {
            margin: auto 0;
            padding: 0 $space-xl;
        }
    }
    &__alert {
        margin-top: 10px;
    }
}
</style>
