<template>
    <button type="button" class="booking" @click="toggleBooking" :class="{ 'booking--active': isOpen }">
        <div class="booking__container">
            <div class="booking__info">
                <h4 class="booking-number">
                    {{ item.bookingNumber }}
                </h4>
                <p :class="{ danger: item.paymentInfo.paymentStatus === PaymentStatus.Unpaid }">
                    {{ $t(`paymentStatus.${item.paymentInfo.paymentStatus}`) }}
                </p>
                <p v-if="item.paymentInfo.leftToPay < 0" class="refund">
                    {{ $t("myPages.myBookings.refund.willBeRefunded") }}: {{ formatPrice(Math.abs(item.paymentInfo.leftToPay)) }}
                </p>
            </div>
            <div class="booking__routes">
                <div 
                    class="booking__route" 
                    v-for="section in item.sections" 
                    :key="section.route" 
                    :class="{ disabled: section.isLocked }"
                >
                    <h4>
                        {{ $t(`routes.${section.route}`) }}
                    </h4>
                    <p v-if="section.departureDateTime">{{ dateAndTime(section.departureDateTime) }}</p>
                    <p v-if="section.flexibility" class="flexibility">{{ section.flexibility.name }}</p>
                </div>
                <div v-if="item.isPaymentBooking" class="booking__route">
                    <p class="flexibility">
                        {{ $t("myPages.myBookings.cardPurchase") }}
                    </p>
                </div>
                <div v-else-if="item.isCancelled" class="booking__route">
                    <p class="flexibility">
                        {{ $t("myPages.myBookings.noBooking") }}
                    </p>
                </div>
            </div>
        </div>
        <div type="button" class="booking__chevron">
            <i class="gg-chevron-right" />
        </div>
    </button>
    <BaseTransitionExpand :expanded="isOpen">
        <MyBookingActions :item="item" :key="item.bookingNumber"> </MyBookingActions>
    </BaseTransitionExpand>
</template>

<script lang="ts" setup>
import BaseTransitionExpand from "@/components/base/BaseTransitionExpand.vue";
import { dateAndTime } from "@/helpers/dateHelpers";
import { PaymentStatus } from "@/models/Enums";
import { MyBooking } from "@/models/front/customer/MyBooking";
import { computed } from "vue";
import MyBookingActions from "../MyBookingActions/MyBookingActions.vue";
import { formatPrice } from "@/helpers/priceHelpers";

const props = defineProps<{
    item: MyBooking;
    activeBookingNumber?: number;
}>();

const emit = defineEmits(["toggle"]);

const isOpen = computed(() => {
    return props.activeBookingNumber === props.item.bookingNumber;
});

const toggleBooking = () => {
    emit("toggle", props.item.bookingNumber);
};
</script>

<style lang="scss" scoped>
@import "~/styles/icons.scss";

.booking {
    cursor: pointer;
    position: relative;
    display: flex;
    background: transparent;
    border: none;
    text-align: left;
    width: 100%;

    h4, p {
        color: $c-black-100;
        line-height: 1.4;
    }

    h4 {
        color: $c-blue-dark;
    }

    &--active {
        .booking__chevron {
            transform: translateX(50%) rotateZ(90deg);
        }
    }

    &__container {
        flex: 1;
        position: relative;
        display: flex;
        flex-flow: row wrap;
        gap: $space-sm;
        padding-right: 30px;
    }

    &__routes {
        display: flex;
        gap: $space-lg;
        padding: 0;
        flex: 1;

        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
        }
    }

    &__info {
        flex: 0 0 calc((100% - $space-lg * 2) / 3);

        @include media-breakpoint-down(sm) {
            flex: 1;
        }
    }

    &__route {
        flex: 0 0 calc((100% - $space-lg) / 2);
        
        @include media-breakpoint-down(sm) {
            flex: 1;
        }

        &.disabled {
            opacity: 0.5;
        }
    }

    &__chevron {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        position: absolute;
        right: 20px;
        bottom: 0;
        background: none;
        border: none;
        cursor: pointer;
        transform: translateX(50%);
        color: #000;

        @include media-breakpoint-down(xs) {
            right: 15px;
        }
    }

    .refund {
        color: $c-blue-dark;
        margin-top: 5px;
    }
}

.booking__arrow.visible {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 30px;
    margin-top: $space-sm;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $c-blue;
}
</style>
