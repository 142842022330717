import { storageWithExpiration } from "@/helpers/storageHelper";
import { Direction } from "@/models/Enums";
import { TransferPassengerInfo, TransferResource } from "@/models/api/ExtrasResponse";
import { ResourceState, TransferState } from "@/models/store/BookingState";
import { defineStore } from "pinia";

const getInitialState = () => {
    return {
        outward: {
            stationId: "",
            passengers: [],
        } as TransferState,
        return: {
            stationId: "",
            passengers: [],
        } as TransferState,
    };
};

export const useTransferStore = defineStore("transfer", {
    state: () => {
        return getInitialState();
    },
    getters: {
        getPassengers:
            (state) =>
            (direction: Direction): ResourceState[] => {
                if (direction === Direction.Outward) {
                    return state.outward.passengers;
                }
                return state.return.passengers;
            },
        getTransferState: (state) => {
            return state;
        },
        getStationId:
            (state) =>
            (direction: Direction): string => {
                if (direction === Direction.Outward) {
                    return state.outward.stationId;
                }
                return state.return.stationId;
            },
    },
    actions: {
        setStationId(direction: Direction, stationId: string) {
            if (direction === Direction.Outward) {
                this.outward.stationId = stationId;
            } else {
                this.return.stationId = stationId;
            }
        },
        setPassenger(direction: Direction, passengerResource: TransferPassengerInfo, value: number) {
            const passengers = this.getPassengers(direction);
            const count = passengers.filter((p) => p.identifier === passengerResource.identifier).length;
            if (count > value) {
                this.removePassenger(direction, passengerResource, value);
            } else {
                this.addPassenger(direction, passengerResource, value);
            }
        },
        addPassenger(direction: Direction, passengerResource: TransferPassengerInfo, value: number) {
            if (value === 0) return;

            for (let i = 0; i < value; i++) {
                if (direction === Direction.Outward) {
                    if (!this.outward.passengers.find((p) => p.identifier === passengerResource.identifier && p.number === i + 1)) {
                        this.outward.passengers.push({
                            id: passengerResource.passengerType,
                            identifier: passengerResource.identifier,
                            code: passengerResource.passengerType,
                            number: i + 1,
                        });
                    }
                } else {
                    if (!this.return.passengers.find((p) => p.identifier === passengerResource.identifier && p.number === i + 1)) {
                        this.return.passengers.push({
                            id: passengerResource.passengerType,
                            identifier: passengerResource.identifier,
                            code: passengerResource.passengerType,
                            number: i + 1,
                        });
                    }
                }
            }
        },
        removePassenger(direction: Direction, passengerResource: TransferPassengerInfo, value: number) {
            if (direction === Direction.Outward && this.outward.passengers.length > 0) {
                this.outward.passengers = this.outward.passengers.filter(
                    (p) => p.number <= value || p.identifier !== passengerResource.identifier
                );
            } else if (this.return.passengers.length > 0) {
                this.return.passengers = this.return.passengers.filter(
                    (p) => p.number <= value || p.identifier !== passengerResource.identifier
                );
            }
        },
        setPassengers(direction: Direction, value: ResourceState[]) {
            if (direction === Direction.Outward) {
                this.outward.passengers = value;
            } else if (direction === Direction.Return) {
                this.return.passengers = value;
            }
        },
        setFromApi(direction: Direction, resources: TransferResource[]) {
            const mapState = (r: TransferResource): TransferState => {
                const state: TransferState = {
                    stationId: r.id,
                    passengers: [],
                };

                const selectedPassengers = r.passengerInfo.filter((pi) => pi.amount > 0);

                selectedPassengers.forEach((p) => {
                    for (let i = 1; i <= p.amount; i++) {
                        state.passengers.push({
                            id: p.identifier,
                            identifier: p.identifier,
                            code: p.passengerType,
                            number: i,
                        });
                    }
                });

                return state;
            };

            const selectedStops = resources?.filter((tr) => tr.amount > 0) ?? [];

            if (direction === Direction.Outward) {
                if (selectedStops.length) {
                    this.outward = mapState(selectedStops[0]);
                } else {
                    this.outward = { stationId: "", passengers: [] };
                }
            } else if (direction === Direction.Return) {
                if (selectedStops.length) {
                    this.return = mapState(selectedStops[0]);
                } else {
                    this.return = { stationId: "", passengers: [] };
                }
            }
        },
    },
    persist: {
        storage: storageWithExpiration(localStorage),
    },
});
