<template>
    <div class="field__container" :class="{ active: active }">
        <label class="field__label" :for="name">{{ label }}</label>
        <textarea
            :name="name"
            @focus="onFocus"
            @blur="onBlur"
            class="field__input"
            v-model="inputValue"
            :disabled="disabled"
            :class="{ 'field__input--error': hasError, disabled: disabled, 'field__input--valid': valid }"
            :placeholder="placeholder"
        />
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
    props: {
        name: String,
        modelValue: String,
        hasError: {
            type: Boolean,
            required: false,
        },
        errors: {
            type: Array,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        valid: {
            type: Boolean,
            required: false,
            default: true,
        },
        placeholder: {
            type: String,
            required: false,
        },
    },
    setup(props, { emit }) {
        const inputValue = computed({
            get(): string {
                return props.modelValue ?? "";
            },
            set(value: string) {
                emit("update:modelValue", value);
            },
        });

        const active = ref(false);

        const onFocus = () => {
            active.value = true;
        };

        const onBlur = () => {
            if (inputValue.value.length < 1) {
                active.value = false;
            } else {
                active.value = true;
            }
        };

        onMounted(() => {
            onBlur();
        });

        return {
            active,
            inputValue,
            onFocus,
            onBlur,
        };
    },
});
</script>

<style lang="scss" scoped>
@import "~/styles/forms.scss";

.field__input {
    width: 100%;
    min-height: 75px !important;
    max-width: 100%;
    min-width: 100%;
    padding: $space-sm $space-md;
    border: 1px solid $c-gray;
    font-family: $font-body;
    font-size: $fs-16;
    outline: none;
    border-radius: $br-input;

    @include media-breakpoint-up(md) {
        font-size: $fs-14;
    }

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
</style>
