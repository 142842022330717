import useDialog from "@/composables/useDialog";
import useRedirect from "@/composables/useRedirect";
import useUnloadBookings from "@/composables/useUnloadBookings";
import { BookingStep } from "@/models/BookingStep";
import { BookingActivity, PageUrl } from "@/models/Enums";
import { DialogType } from "@/models/Symbols/Dialog";
import { useUserStore } from "@/store/user";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const useBookingNavigation = () => {
    const { t } = useI18n();
    const router = useRouter();
    const userStore = useUserStore();

    const isAccommodationActivated = document.body.dataset.accommodationEnabled === "True";

    const steps = computed(() => {
        const defaultSteps: BookingStep[] = [
            {
                number: 1,
                title: t(`booking.steps.1`),
            },
            {
                number: 2,
                title: t(`booking.steps.2`),
            },
            {
                number: 3,
                title: t(`booking.steps.3`),
            },
            {
                number: 4,
                title: t(`booking.steps.4`),
            },
            {
                number: 5,
                title: t(`booking.steps.5`),
            },
        ];

        if (isAccommodationActivated) {
            defaultSteps.push({
                number: 6,
                title: t(`booking.steps.6`),
            });
        }

        defaultSteps.push({
            number: defaultSteps.length + 1,
            title: t(`booking.steps.7`),
            activity: BookingActivity.Checkout,
        });

        return defaultSteps;
    });

    const currentStep = computed(() => {
        const step = parseInt(router.currentRoute.value.query.s?.toString() ?? "1");
        return steps.value.find((v) => v.number === step) ?? steps.value[0];
    });

    const nextStep = () => {
        goToStep(currentStep.value.number + 1);
    };

    const prevStep = () => {
        goToStep(currentStep.value.number - 1 || 1);
    };

    const goToStep = (step: number) => {
        router.push({
            path: router.currentRoute.value.path,
            query: {
                s: step,
            },
        });
    };

    const { unload, isLoading } = useUnloadBookings(false);
    const { redirect } = useRedirect();
    const cancel = () => {
        isLoading.value = true;
        return unload().then(() => {
            if (userStore.isLoggedIn) {
                redirect(PageUrl.Account);
            } else {
                // remove query params
                window.location.href = "/";
            }
        });
    };

    const { displayDialog } = useDialog();

    const closeBookingAndRedirect = () => {
        displayDialog({
            title: t("messages.cancel.title"),
            message: t("messages.cancel.body"),
            yes: t("button.yes"),
            no: t("button.no"),
            callback: () => cancel(),
            type: DialogType.warning,
        });
    };

    const isCheckout = computed(() => {
        return currentStep.value.activity === BookingActivity.Checkout;
    });

    return { nextStep, prevStep, goToStep, cancel, steps, currentStep, isCheckout, closeBookingAndRedirect, isAccommodationActivated };
};

export default useBookingNavigation;
