import { AgentAccountResponse } from "../../api/AgentAccountResponse";

export class AgentAccount {
    name: string;
    address: string;
    city: string;
    company: string;
    email: string;
    zipCode: string;
    productCode: string;

    constructor(resp: AgentAccountResponse) {
        this.name = resp.name;
        this.address = resp.address;
        this.city = resp.city;
        this.company = resp.company;
        this.email = resp.email;
        this.zipCode = resp.zipCode;
        this.productCode = resp.productCode;
    }
}