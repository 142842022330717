import { GiftCardBalanceRequest } from "@/models/api/GiftCardBalanceRequest";
import { GiftCardBalanceResponse } from "@/models/api/GiftCardBalanceResponse";
import { GiftCardPurchaseRequest } from "@/models/api/GiftCardPurchaseRequest";
import { GiftCardPurchaseResponse } from "@/models/api/GiftCardPurchaseResponse";
import { ref } from "vue";
import useApi from "./useApi";

const useGiftCard = () => {
    const isLoading = ref(false);
    const { post, get } = useApi({ displayErrors: false });

    //Buying a giftcard
    const giftCardPurchase = (req: GiftCardPurchaseRequest) => {
        isLoading.value = true;
        return post<GiftCardPurchaseResponse>("giftcard/init", req)
            .then((resp) => resp.data)
            .finally(() => {
                isLoading.value = false;
            });
    };

    //Get giftcard balance and expiry date
    const giftCardBalance = (state: GiftCardBalanceRequest) => {
        isLoading.value = true;

        return get<GiftCardBalanceResponse>("giftcard/balance", state)
            .then((resp) => resp.data)
            .finally(() => {
                isLoading.value = false;
            });
    };

    return {
        giftCardPurchase,
        giftCardBalance,
        isLoading,
    };
};

export default useGiftCard;
