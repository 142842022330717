<template>
    <div class="booking-actions">
        <div v-if="tabs.length" class="booking-actions__primary">
            <div class="tabs">
                <BaseButton
                    v-for="tab in tabs"
                    :key="tab.name"
                    @click="selected = tab.component"
                    class="btn--primary tab-btn"
                    :class="{ active: selected === tab.component }"
                >
                    {{ tab.name }}
                </BaseButton>
            </div>
            <component :is="tabComponents[selected]" class="tab" :booking="item" :key="item.bookingNumber"></component>
        </div>
        <div class="booking-actions__secondary">
            <div class="send-sms">
                <BaseButton
                    type="button"
                    class="btn--transparent"
                    @click="isOpen = !isOpen"
                    icon="chevron/chevron-down"
                    :class="{ active: isOpen }"
                >
                    {{ $t("myPages.myBookings.sendSms") }}
                </BaseButton>
                <div v-if="isOpen" class="send-sms__expanded">
                    <SendSMS
                        :accessToken="item.accessToken"
                        :bookingNumber="item.bookingNumber"
                        :phoneNumber="getPhoneWithoutCode(user.mobilePhone)"
                        :phoneCode="getPhoneCode(user.mobilePhone)"
                    />
                </div>
            </div>
            <div class="booking-confirmation">
                <a target="_blank" :href="item.confirmationUrl">{{ $t("myPages.myBookings.showConfirmation") }}</a>
            </div>
            <div v-if="item.voucherUrl" class="booking-confirmation">
                <a target="_blank" :href="item.voucherUrl">{{ $t("myPages.myBookings.showVoucher") }}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import SendSMS from "@/components/SendSMS.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import { MyBooking } from "@/models/front/customer/MyBooking";
import { useUserStore } from "@/store/user";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { getPhoneCode, getPhoneWithoutCode } from "~/helpers/phoneHelper";
import MyBookingAmend from "./MyBookingAmend.vue";
import MyBookingCancel from "./MyBookingCancel.vue";
import MyBookingPayment from "./MyBookingPayment.vue";
import MyBookingRefund from "./MyBookingRefund.vue";

interface Props {
    item: MyBooking;
}

const props = defineProps<Props>();

const tabComponents = { MyBookingAmend, MyBookingCancel, MyBookingPayment, MyBookingRefund };

type BookingTab = {
    component: keyof typeof tabComponents;
    name: string;
};

const { t } = useI18n();
const userStore = useUserStore();
const user = computed(() => userStore.getUser);
const isOpen = ref(false);

const tabs: BookingTab[] = [];

if (props.item.canEdit) {
    tabs.push({
        component: "MyBookingAmend",
        name: t("myPages.myBookings.amend"),
    });
}

if (props.item.canCancel) {
    tabs.push({
        component: "MyBookingCancel",
        name: t("myPages.myBookings.cancel"),
    });
}

if (props.item.canPay) {
    tabs.unshift({
        component: "MyBookingPayment",
        name: t("myPages.myBookings.payment"),
    });
}

if (props.item.paymentInfo.leftToPay < 0) {
    tabs.unshift({
        component: "MyBookingRefund",
        name: t("myPages.myBookings.refund.title"),
    });
}

const selected = ref(tabs.length ? tabs[0].component : "MyBookingAmend");
</script>

<style lang="scss" scoped>
@import "~/styles/icons.scss";

.booking-actions {
    position: relative;
    display: flex;
    flex-direction: column;
    border-top: none !important;

    &::before,
    &::after {
        content: none !important;
    }

    &__primary {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .tabs {
            display: flex;
            flex-direction: row;
            margin-bottom: -1px;
        }

        .tab-btn {
            max-width: 210px;
            padding: $space-sm $space-lg;
            border-radius: $br-large;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            @include font(p);
            line-height: 1;
            margin-right: $space-xs;

            &.active {
                &:hover {
                    background-color: $c-white-100;
                }
            }
        }

        .active {
            background-color: $c-white-100;
            color: $c-black-100;
            font-weight: $fw-bold;
            border: 1px solid $c-color-primary;
            border-bottom-color: $c-white-100;
            z-index: 10;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 4px;
                background-color: $c-blue-200;
            }
        }
    }

    &__secondary {
        margin-top: 20px;
        .send-sms {
            > button {
                height: auto;
                white-space: break-spaces;
                text-align: left;
                &:not(.active):deep(.icon) {
                    transform: rotate(-90deg);
                }
            }

            &__expanded {
                padding: $space-sm $space-md;
                background-color: $c-gray-300;
                border-radius: 6px;
            }
        }

        .booking-confirmation {
            margin-top: $space-md;
            > a {
                color: $c-blue-300;
                text-decoration: underline;
            }
        }
    }
}
</style>
