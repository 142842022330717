import useApi from "@/composables/useApi";
import { OpenBookingResult } from "@/models/api/OpenBookingResult";
import { useBookingStore } from "@/store/booking";
import { ref } from "vue";
import { OpenBookingType } from "@/models/Enums";

const useOpenBooking = () => {
    const { post } = useApi();
    const isLoading = ref(false);
    const bookingStore = useBookingStore();

    const openBooking = (accessToken: string, type : OpenBookingType) => {
        isLoading.value = true;
        bookingStore.resetBookingState();
        return post<OpenBookingResult>("amend/open", { accessToken, type })
            .then((resp) => {
                bookingStore.setFromApi(resp.data, true);
                
                return resp.data;
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    return {
        isLoading,
        openBooking,
    };
};

export default useOpenBooking;
