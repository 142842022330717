<template>
    <div class="bank-refund">
        <BaseInput :label="$t('fields.bank.label')" v-model="v$.bank.$model" :hasError="v$.bank.$error" />
        <BaseInput
            :label="$t('fields.clearingNumber.label')"
            v-model="v$.clearingNumber.$model"
            :hasError="v$.clearingNumber.$error"
            type="tel"
        />
        <BaseInput :label="$t('fields.accountNumber.label')" v-model="v$.accountNumber.$model" :hasError="v$.accountNumber.$error" type="tel" />
        <BaseInput :label="$t('fields.accountHolder.label')" v-model="v$.accountHolder.$model" :hasError="v$.accountHolder.$error" />
    </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import BaseInput from "../base/BaseInput.vue";
import { required } from "@/helpers/validators";
import useVuelidate from "@vuelidate/core";

const state = reactive({
    bank: "",
    accountNumber: "",
    clearingNumber: "",
    accountHolder: "",
});

const rules = {
    bank: {
        required,
    },
    accountNumber: {
        required,
    },
    clearingNumber: {
        required,
    },
    accountHolder: {
        required,
    },
};

const v$ = useVuelidate(rules, state);

defineExpose({
    state,
});
</script>

<style scoped lang="scss">
.bank-refund {
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
    padding: 10px 0;
}
</style>
