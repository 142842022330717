<template>
    <BaseDropdown
        :label="label"
        :options="genders"
        :name="name"
        :hasError="hasError"
        :errors="errors"
        v-model="inputValue"
        :disabled="disabled"
    />
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import BaseDropdown from "~/components/base/BaseDropdown.vue";
import { genders } from "~/config/genders";

export default defineComponent({
    props: {
        name: String,
        modelValue: String,
        valid: {
            type: Boolean,
            required: false,
            default: true,
        },
        errors: {
            type: Array,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        hasError: {
            type: Boolean,
            required: false,
        },
        label: {
            type: String,
            required: false,
        }
    },
    components: { BaseDropdown },
    setup(props, { emit }) {
        const inputValue = computed({
            get(): string {
                return props.modelValue ?? "";
            },
            set(value: string) {
                emit("update:modelValue", value);
            },
        });
        return { inputValue, genders };
    },
});
</script>

<style scoped></style>
