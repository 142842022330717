<template>
    <BaseLightbox v-model="show">
        <BaseHeading :title="$t('button.logout')" />
        <div class="center">
            <p>{{ $t("myPages.logout.subtitle") }}</p>
            <BaseButton @click="logout" :loading="loggingOut" class="btn--fixed-width" icon="booking/logout">
                {{ $t("button.logout") }}
            </BaseButton>
            <BaseButton @click="show = false" class="btn--link return">
                {{ $t("myPages.logout.cancelLogOut") }}
            </BaseButton>
        </div>
    </BaseLightbox>
</template>

<script lang="ts" setup>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseHeading from "@/components/base/BaseHeading.vue";
import BaseLightbox from "@/components/base/BaseLightbox.vue";
import useLogout from "@/composables/login/useLogout";
import { computed } from "vue";

const props = defineProps<{ modelValue: boolean }>();

const emit = defineEmits(["update:modelValue"]);

const { logout, loggingOut } = useLogout();

const show = computed({
    get() {
        return props.modelValue;
    },
    set(value: boolean) {
        emit("update:modelValue", value);
    },
});
</script>

<style lang="scss" scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    :deep() .icon {
        margin-left: 10px;
    }
}
</style>
