<template>
    <div class="dropdown-container" ref="dropdownRef">
        <slot name="trigger" :toggle="toggle" :isOpen="isOpen"></slot>
        <div @click="toggle" v-if="mobileModal && isOpen" class="dropdown__overlay"></div>
        <div v-if="isOpen" class="dropdown" :class="{ 'dropdown--modal': mobileModal }">
            <div class="dropdown__content">
                <header v-if="mobileModal && modalTitle">
                    <b>{{ modalTitle }}</b>
                </header>
                <div class="dropdown__scroll">
                    <slot :toggle="toggle"></slot>
                </div>
                <footer>
                    <slot name="close" :toggle="toggle"></slot>
                </footer>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";

interface Props {
    mobileModal?: boolean;
    modalTitle?: string;
}

defineProps<Props>();

const isOpen = ref(false);

const toggle = () => {
    isOpen.value = !isOpen.value;
};

const dropdownRef = ref();

onClickOutside(dropdownRef, () => {
    isOpen.value = false;
});

defineExpose({
    toggle,
    isOpen
});
</script>

<style lang="scss" scoped>
.dropdown {
    position: absolute;
    margin-top: 3px;
    width: 100%;
    z-index: 20;

    &-container {
        position: relative;
    }

    &__content {
        background-color: $c-white-100;
        box-shadow: 0 8px 8px rgb($c-black-100, 15%);

        header {
            display: none;
            padding: 10px 15px;
        }
    }

    &__overlay {
        @include media-breakpoint-down(xs) {
            position: fixed;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(#000000, 0.5);
            z-index: 999;
        }
    }

    &__scroll {
        max-height: 65vh;
        overflow-y: auto;
    }

    &--modal {
        @include media-breakpoint-down(xs) {
            position: fixed;
            z-index: 1000;
            top: 50%;
            left: 0;
            margin: $space-sm;
            max-width: calc(100% - #{$space-lg});
            transform: translateY(-55%);

            header {
                display: block;
            }

            .dropdown__scroll {
                max-height: 75vh;
                overflow-y: auto;
            }

            .dropdown__content {
                padding: $space-md $space-sm;
                border-radius: 10px;
                box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
                margin: auto;
            }
        }
    }
}
</style>
