import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseQuantity = _resolveComponent("BaseQuantity")!

  return (_openBlock(), _createBlock(_component_BaseQuantity, {
    id: Math.random.toString(),
    name: Math.random.toString(),
    minValue: 0,
    maxValue: 17,
    modelValue: _ctx.childAge,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.childAge) = $event))
  }, null, 8, ["id", "name", "modelValue"]))
}