<template>
    <div class="expansion-panel">
        <component
            :is="!!$slots.default ? 'button' : 'div'"
            @click="toggle"
            class="expansion-panel__header"
            :disabled="disabled"
            :class="{
                compact: !icon && !$slots['header'],
                'has-button': !!$slots.buttons,
                active: $slots.default && isExpanded,
            }"
        >
            <div class="default-header" v-if="!$slots['header']">
                <div class="default-header__icon" v-if="icon">
                    <SvgIcon :icon="icon" />
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 100 100" class="diagonal">
                        <rect width="100" height="100" style="fill: #f3f3f3"></rect>
                        <path d="M 100 0 L 100 100 L 5 100 L 100 0 Z" style="fill: #ffffff"></path>
                        <path d="M 0 -100 L 0 0 L -95 0 L 0 -100 Z" style="fill: #ffffff" transform="matrix(-1, 0, 0, -1, 0, 0)"></path>
                    </svg>
                    <div class="notification" v-if="notifications">
                        <span>{{ notifications }}</span>
                    </div>
                </div>
                <div class="default-header__text">
                    <h4>
                        <span v-html="title"></span>
                        <span class="default-header__badge" v-if="badge">{{ badge }}</span>
                    </h4>
                    <p v-html="subtitle"></p>
                </div>
                <div class="default-header__buttons">
                    <slot name="buttons"></slot>
                    <i class="icon gg-chevron-right" v-if="$slots.default" :class="{ active: $slots.default && isExpanded }"></i>
                </div>
            </div>
            <slot name="header"></slot>
        </component>
        <div class="content" v-if="$slots.default">
            <BaseTransitionExpand :expanded="isExpanded">
                <slot></slot>
            </BaseTransitionExpand>
        </div>
    </div>
</template>

<script lang="ts" setup>
import BaseTransitionExpand from "@/components/base/BaseTransitionExpand.vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import { watch } from "vue";
import { computed } from "vue";
import { ref } from "vue";

interface Props {
    title?: string;
    subtitle?: string;
    icon?: string;
    notifications?: number;
    badge?: string;
    disabled?: boolean;
    isOpen?: boolean;
}

const props = defineProps<Props>();

const internalValue = ref(props.isOpen ?? false);

const emit = defineEmits(["toggled", "update:isOpen"]);

const toggle = () => {
    isExpanded.value = !isExpanded.value;
};

const isExpanded = computed({
    get() {
        return internalValue.value;
    },
    set(val: boolean) {
        internalValue.value = val;
        emit("update:isOpen", val);
        emit("toggled", val);
    },
});

watch(
    () => props.isOpen,
    (current) => {
        if (typeof current !== "undefined") {
            internalValue.value = current;
        }
    }
);
</script>

<style lang="scss" scoped>
@import "~/styles/icons.scss";

.expansion-panel {
    $prefix: ".expansion-panel";
    $defaultHeader: ".default-header";
    $offsetSize: 4px;

    #{$prefix}__header {
        display: flex;
        position: relative;        
        min-height: 90px;
        background-color: $c-white-100;
        cursor: pointer;
        border: none;
        font-family: $font-body;
        width: 100%;
        text-align: left;
        color: $c-black-100;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.6;

            + .content {
                pointer-events: none;
                opacity: 0.6;
            }
        }

        @include media-breakpoint-up(sm) {
            min-height: 110px;
        }

        &.compact {
            min-height: 80px;

            > #{$defaultHeader} {
                #{$defaultHeader}__text {
                    padding: var(--p-padding-sm) var(--p-padding-sm);

                    @include media-breakpoint-up(sm) {
                        padding: var(--p-padding-sm) var(--p-padding-md);
                    }
                }
            }
        }

        &.has-button {
            cursor: initial;

            > #{$defaultHeader} {
                flex-direction: column;

                @include media-breakpoint-up(sm) {
                    flex-direction: row;
                }

                #{$defaultHeader}__icon {
                    display: none;

                    @include media-breakpoint-up(sm) {
                        display: block;
                    }
                }

                #{$defaultHeader}__text {
                    padding: var(--p-padding-xs) var(--p-padding-sm) 0;

                    @include media-breakpoint-up(sm) {
                        padding: var(--p-padding-sm) 0;
                    }
                }

                #{$defaultHeader}__buttons {
                    justify-content: flex-start;
                    padding: 0 var(--p-padding-sm) var(--p-padding-xs);

                    @include media-breakpoint-up(sm) {
                        justify-content: flex-end;
                        padding: var(--p-padding-sm) var(--p-padding-md) var(--p-padding-sm) var(--p-padding-sm);
                    }
                }
            }
        }

        > #{$defaultHeader} {
            display: flex;
            width: 100%;

            #{$defaultHeader}__icon {
                padding-left: 25px;
                position: relative;

                @include media-breakpoint-up(sm) {
                    padding-left: var(--p-offset-md);
                }

                .icon {
                    position: absolute;
                    top: var(--p-padding-xs);
                    left: var(--p-offset-sm);
                    width: 20px;
                    margin-top: 10px;
                    transform: translateX(-50%);

                    @include media-breakpoint-up(sm) {
                        top: var(--p-padding-sm);
                        left: var(--p-offset-md);
                        width: 32px;
                        max-height: 38px;
                    }
                }

                .diagonal {
                    display: block;
                    height: 100%;
                    width: 90px;

                    @include media-breakpoint-up(sm) {
                        width: 110px;
                    }
                }

                .notification {
                    position: absolute;
                    background-color: $c-red-100;
                    border-radius: 50%;
                    top: 16px;
                    left: 42px;
                    width: 15px;
                    height: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include media-breakpoint-up(sm) {
                        width: 20px;
                        height: 20px;
                        top: 17px;
                        left: 59px;
                    }
                    span {
                        color: $c-white-100;
                        font-size: 0.8rem;
                        @include media-breakpoint-up(sm) {
                            font-size: 1rem;
                        }
                    }
                }
            }

            #{$defaultHeader}__text {
                padding: var(--p-padding-xs) 0;

                @include media-breakpoint-up(sm) {
                    padding: var(--p-padding-sm) 0;
                }

                h4 {
                    display: block;
                    margin-bottom: $space-xs;
                    font-size: $fs-16;

                    @include media-breakpoint-up(sm) {
                        display: inline-block;
                        font-size: $fs-18;
                    }
                }
            }

            #{$defaultHeader}__buttons {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                flex: 1 0 auto;
                margin-top: 10px;
                min-width: calc(10px + var(--p-offset-sm));

                @include media-breakpoint-up(sm) {
                    margin-top: 0;
                    min-width: calc(10px + var(--p-offset-md));
                }

                .icon {
                    position: absolute;
                    bottom: 15px;
                    right: var(--p-offset-sm);
                    transform: translateX(50%) rotateZ(0deg);

                    @include media-breakpoint-up(sm) {
                        right: var(--p-offset-md);
                    }

                    &.active {
                        transform: translateX(50%) rotateZ(90deg);
                    }
                }
            }

            #{$defaultHeader}__badge {
                display: inline;
                padding: 1px 15px;
                border-radius: 20px;
                background-color: $c-blue-200;
                color: $c-white-100;
                font-size: $fs-14;
                font-weight: $fw-regular;
                white-space: nowrap;
                line-height: normal;
            }
        }

        + .content {
            :deep() > .transition-expand > *:first-of-type {
                position: relative;
                border-top: $offsetSize solid $c-gray-300;

                &::before,
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: -#{$offsetSize};
                    right: var(--p-offset-sm);
                    width: 0;
                    height: 0;
                    border: calc(6px + (#{$offsetSize} * 2)) solid transparent;
                    border-top-color: $c-gray-300;
                    transform: translate3d(50%, 0, 0);

                    @include media-breakpoint-up(sm) {
                        right: var(--p-offset-md);
                    }
                }

                &::after {
                    border: 8px solid transparent;
                    border-top-color: $c-white-100;
                }
            }
        }
    }
}

.panel,
.expansion-panel {
    + .panel,
    + .expansion-panel {
        &::before {
            content: "";
            display: block;
            margin: 12px auto;
            width: 90px;
            height: 1px;
            background-color: $c-black-100;
        }
    }
}
</style>
