import { countryList } from "@/config/countries";

export const format = (number: string, phoneCode: string) => {

    if (!phoneCode) {
        return number;
    }

    if (number.includes('+')) {
        number = number.replace(`+${phoneCode}`, "");
    }

    if (number.indexOf('0') == 0) {
        number = number.substring(1);
    }

    return `+${phoneCode}${number}`;
}

export const getPhoneCode = (mobilePhone?: string): string => {
    if (!mobilePhone) return "";

    if (!mobilePhone.startsWith('+')) {
        return "";
    }

    for (const country of countryList) {
        const number = mobilePhone.replace("+", "");
        if (number.startsWith(country.phoneCode)) {
            return country.phoneCode;
        }
    }

    return "";
}

export const getPhoneWithoutCode = (mobilePhone?: string): string => {
    if (!mobilePhone) return "";
    const phoneCode = getPhoneCode(mobilePhone);
    return mobilePhone.replace('+' + phoneCode, '');
}