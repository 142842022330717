<template>
    <div class="card-info" :class="{ 'card-info--center': centered }">
        <div v-if="!hideBalance && data?.travelCard.balance !== null" class="card-info__amounts">
            <div class="card-info__amounts-balance">
                <b> {{ $t("myPages.commuter.currentBalance") }}</b>
                <b> {{ data?.travelCard.balance }}:- </b>
            </div>
            <div class="card-info__amounts-leftToPay">
                <p>{{ $t("myPages.commuter.notPayed") }}</p>
                <p>{{ data?.leftToPay }}:- </p>
            </div>
            <div class="card-info__amounts-paid">
                <p>{{ $t("myPages.commuter.paid") }}</p>
                <p>{{ data?.paid }}:-</p>
            </div>
        </div>
        <div v-if="isLoading" class="card-info__expiration-loading">
            <div>
                <BaseSpinner color="dark" />
            </div>
        </div>
        <div v-else-if="data?.travelCard" class="card-info__expiration">
            {{ $t("myPages.commuter.cardExpiration") }}
            <b v-if="data?.travelCard.validTo && data?.travelCard.validTo < new Date()">{{ $t("myPages.commuter.cardExpired") }}</b>
            <b v-else-if="data?.travelCard.validTo">{{ YYYYMMDD(data?.travelCard.validTo) }}</b>
            <b v-else>{{ $t('myPages.commuter.cardActivating') }}</b>
        </div>
    </div>
</template>

<script setup lang="ts">
import BaseSpinner from "@/components/base/BaseSpinner.vue";
import { YYYYMMDD } from "@/helpers/dateHelpers";
import useCustomerCardsQuery from "@/modules/MyPages/composables/useCustomerCardsQuery";

interface Props {
    centered?: boolean;
    hideBalance?: boolean;
}

defineProps<Props>();

const { data, isLoading } = useCustomerCardsQuery();
</script>

<style lang="scss" scoped>
.card-info {
    padding-bottom: 10px;

    &--center {
        text-align: center;
    }

    &__amounts {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $c-gray-100;

        &-balance,
        &-leftToPay,
        &-paid {
            display: flex;
            justify-content: space-between;
        }
    }

    &__expiration-loading {
        position: relative;
        div {
            display: block;
            height: 20px;
        }
    }
}
</style>
