<template>
    <div class="input-wrapper">
        <label :for="inputId" v-if="outerLabel">{{ outerLabel }}</label>
        <div class="input-field" :class="{ active: isActive || type === 'date', error: hasError }">
            <input
                :id="inputId"
                :name="name"
                @focus="onFocus"
                @blur="onBlur"
                :type="type"
                v-model="inputValue"
                :disabled="disabled"
                :class="{ disabled: disabled, 'has-label': label }"
                :placeholder="placeholder"
                :step="step"
                :autocomplete="autocomplete"
                :title="title"
                :tabindex="tabindex"
                v-maska
                :data-maska="mask"
            />
            <label :for="inputId" v-if="label" :class="{ disabled: disabled }">{{ label }}</label>
            <SvgIcon v-if="icon" :icon="icon" />
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import SvgIcon from "./SvgIcon.vue";
import uniqueId from "lodash.uniqueid";
import { vMaska } from "maska";

interface Props {
    id?: string,
    name?: string;
    modelValue?: string | number;
    hasError?: boolean;
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    type?: string;
    step?: number;
    icon?: string;
    outerLabel?: string;
    autocomplete?: string;
    title?: string;
    tabindex?: string;
    mask?: string;
}

const props = withDefaults(defineProps<Props>(), { type: "text", step: 0.01 });

const inputId = props.id ? props.id : `${props.name}-${uniqueId()}`;

const emit = defineEmits(["update:modelValue"]);

const inputValue = computed({
    get(): string | number {
        return props.modelValue ?? "";
    },
    set(value: string | number) {
        if (typeof value === "number") {
            value = +value.toFixed(2);
        }
        emit("update:modelValue", value);
    },
});

const active = ref(false);
const onFocus = () => {
    active.value = true;
};
const onBlur = () => {
    active.value = false;
};

const isActive = computed(() => {
    return inputValue.value.toString().length > 0 || !!props.placeholder || active.value;
});
</script>

<style lang="scss" scoped>
@import "~/styles/forms.scss";
@import "~/styles/icons.scss";

.input-field {
    input {
        &.has-label {
            padding-top: 15px;
        }
    }

    :deep(svg) {
        position: absolute;
        right: var(--i-padding-md);
        top: 50%;
        transform: translateY(-50%);
        height: 1rem;
        width: 1rem;
        pointer-events: none;
    }

    input[type="date"] {
        cursor: text;

        &::-webkit-calendar-picker-indicator {
            cursor: pointer;
            margin-bottom: $space-sm;
        }
    }
}
</style>
