import useApi from "@/composables/useApi";
import { InitPaymentResponse } from "@/models/api/InitPaymentResponse";
import { PostSaleResponse } from "@/models/api/PostSaleResponse";
import { PostSale } from "@/models/front/PostSale";
import { ref } from "vue";

const usePayment = () => {
    const isLoading = ref(false);
    const { post, reAuthenticate } = useApi();

    const registerSale = (reference: string): Promise<PostSale> => {
        isLoading.value = true;
        return post<PostSaleResponse>("sale", { transactionId: reference })
            .then((resp) => new PostSale(resp.data))
            .finally(() => {
                isLoading.value = false;
            });
    };

    const setPayment = (reference: string): Promise<PostSale> => {
        const fn = () => {
            return registerSale(reference);
        };
        isLoading.value = true;
        return new Promise((resolve, reject) => {
            reAuthenticate<PostSale>(fn)
                .then((data) => {
                    resolve(data);
                })
                .catch((err) => reject(err))
                .finally(() => {
                    isLoading.value = false;
                });
        });
    };

    const getCancelUrl = (): string => {
        const baseUrl = window.location.href.split("?")[0];
        const param = location.search.length > 0 ? "&" : "?";
        return `${baseUrl}${location.search}${param}error=paymentCancelled`;
    };

    const initPayment = (type: string, bookingNumber: number, paymentMethod: string, terms: boolean): Promise<InitPaymentResponse> => {
        isLoading.value = true;
        return post<InitPaymentResponse>(type + "/init", {
            bookingNumber,
            ...(paymentMethod ? { paymentMethod } : {}),
            terms,
            cancelUrl: getCancelUrl(),
        })
            .then((resp) => resp.data)
            .finally(() => {
                isLoading.value = false;
            });
    };

    return {
        setPayment,
        getCancelUrl,
        initPayment,
        isLoading,
    };
};

export default usePayment;
