import { CheckoutType, PostSaleResponse } from "../api/PostSaleResponse";
import { PaymentInfo } from "./PaymentInfo";
import { BasketSection, mapFromBasketSectionResponse } from "./basket/BasketGroup";
import { AddressInformation, mapFromAddressInformationResponse } from "./customer/AddressInformation";

type SaleType = "Card" | "Booking";

export class PostSale {
    accessToken: string;
    bookingNumber: number;
    sections: BasketSection[];
    address: AddressInformation;
    id: string;
    isRebook: boolean;
    paymentInfo: PaymentInfo;
    confirmationUrl: string;
    currency: string;
    type: SaleType;
    checkoutType: CheckoutType;
    smsText?: string;
    voucherUrl?: string;

    constructor(resp: PostSaleResponse) {
        this.accessToken = resp.accessToken;
        this.bookingNumber = resp.bookingNumber;
        this.sections = resp.sections.map((p) => mapFromBasketSectionResponse(p));
        this.address = mapFromAddressInformationResponse(resp.address);
        this.id = resp.id;
        this.isRebook = resp.isRebook ?? false;
        this.paymentInfo = new PaymentInfo(resp.paymentInfo);
        this.confirmationUrl = resp.confirmationUrl;
        this.currency = resp.currency;
        this.checkoutType = resp.type ?? "Payment";
        this.type = getSaleType(this.sections);
        this.smsText = resp.sms;
        this.voucherUrl = resp.voucherUrl;
    }
}

function getSaleType(sections: BasketSection[]): SaleType {
    if (!sections.length) return "Booking";

    if (sections[0].route.toString() == "KORT") {
        return "Card";
    }

    return "Booking";
}
