<template>
    <MyBookingBox class="booking-amend">
        <template #header>{{ $t("myPages.myBookingAmend.subtitle") }}</template>
        <form @submit.prevent="submitForm">
            <BaseDropdown class="booking-amend__dropdown" name="s" v-model="selectedOption" :options="options" />
            <BaseButton class="btn--primary" :loading="isLoading">
                <span> {{ $t("booking.steps.title") }} {{ selectedOption }} </span>
            </BaseButton>
        </form>
    </MyBookingBox>
</template>

<script lang="ts" setup>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDropdown from "@/components/base/BaseDropdown.vue";
import { MyBooking } from "@/models/front/customer/MyBooking";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import useOpenBooking from "../../composables/useOpenBooking";
import MyBookingBox from "./MyBookingBox.vue";
import useBookingNavigation from "@/modules/BookingFlow/composables/useBookingNavigation";
import { computed } from "vue";

interface Props {
    booking: MyBooking;
}

const props = defineProps<Props>();

const { t } = useI18n();

const { steps } = useBookingNavigation();

const options = computed(() =>
    steps.value.map((v) => ({
        label: `${t("booking.steps.title")} ${v.number} - ${v.title}`,
        value: v.number,
    }))
);

const selectedOption = ref(1);

const { isLoading, openBooking } = useOpenBooking();

const submitForm = () => {
    openBooking(props.booking.accessToken, "Amend").then((resp) => {
        if (process.env.NODE_ENV === "production") {
            window.location.href = `${resp.url}?s=${selectedOption.value}`;
        } else {
            window.location.href = `/?s=${selectedOption.value}`;
        }
    });
};
</script>

<style lang="scss" scoped>
@import "~/styles/icons.scss";

.booking-amend {
    display: flex;
    flex-direction: column;
    gap: $space-sm;

    form {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: $space-sm;
    }

    &__dropdown {
        max-width: 300px;
        width: 100%;
    }

    .btn--primary {
        padding: 0 $space-xl;
    }
}
</style>
