<template>
    <teleport to="body">
        <transition name="fade" mode="out-in">
            <div v-if="show" tabindex="0" class="dialog-overlay" @keydown.esc="close()">
                <div class="dialog-container" @mousedown.self="close()" :class="{ hideOverflow: hideOverflow }">
                    <div class="dialog" v-bind="$attrs" :style="`max-width: ${maxWidth}px;`">
                        <header :style="`text-align: ${align};`">
                            <BaseButton ref="closeBtnRef" v-if="closable" class="close" @click="show = !show">
                                <SvgIcon :icon="'booking/close-circle'" />
                            </BaseButton>
                            <component :is="headerLevel">
                                {{ title }}
                            </component>
                            <p v-if="subtitle">{{ subtitle }}</p>
                            <slot name="header"></slot>
                        </header>
                        <div class="dialog__body" :style="height ? `height: ${height}px;` : ''">
                            <slot></slot>
                        </div>
                        <footer v-if="$slots.footer">
                            <slot name="footer"></slot>
                        </footer>
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script lang="ts" setup>
import { onKeyStroke } from "@vueuse/core";
import { computed } from "vue";
import BaseButton from "./BaseButton.vue";
import SvgIcon from "./SvgIcon.vue";
import { watch } from "vue";
import { ref } from "vue";
import { nextTick } from "vue";

interface Props {
    title?: string;
    icon?: string;
    subtitle?: string;
    modelValue: boolean;
    closable?: boolean;
    height?: number;
    maxWidth?: number;
    align?: string;
    headerLevel?: string;
    hideOverflow?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    title: "",
    subtitle: "",
    closable: true,
    maxWidth: 800,
    align: "center",
    headerLevel: "h2",
    hideOverflow: false,
});

const emit = defineEmits(["update:modelValue"]);

const show = computed({
    get() {
        return props.modelValue;
    },
    set(value: boolean) {
        emit("update:modelValue", value);
    },
});

const close = () => {
    if (props.closable) {
        show.value = false;
    }
};

onKeyStroke("Escape", (e) => {
    show.value = false;
    e.preventDefault();
});

const closeBtnRef = ref();

let previousElement: unknown;

watch(show, (current) => {
    if (current && props.closable) {
        previousElement = document.activeElement;
        nextTick(() => {
            if (closeBtnRef.value) {
                closeBtnRef.value.$el.focus();
            }
        });
    } else if (previousElement) {
        (previousElement as HTMLElement).focus();
    }
});
</script>

<style lang="scss" scoped>
@import "~/styles/transitions.scss";

.dialog {
    $prefix: ".dialog";
    position: relative;
    padding: var(--p-padding-md) var(--p-padding-sm) var(--p-padding-sm);
    width: 100%;
    max-width: 800px;
    background-color: $c-white-100;
    border-radius: $br-input;

    @include media-breakpoint-up(sm) {
        padding: var(--p-padding-md);
    }

    header {
        max-width: 720px;
        margin: 0 auto 20px;

        .close {
            position: absolute;
            top: 15px;
            right: 15px;
            padding: 0;
            width: 24px;
            height: 24px;
            background-color: unset;
            border: none;
            z-index: 1;

            .icon {
                position: unset;
                width: 100%;
                height: 100%;
                max-width: unset;
                max-height: unset;
                color: $c-blue-300;
                transform: unset;
            }

            &:hover {
                background-color: unset;

                .icon {
                    color: darken($c-blue-300, 5%);
                }
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            + p {
                margin-top: 5px;
            }
        }
    }

    &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 15px;
        width: auto;
        min-height: 100%;

        @include media-breakpoint-up(sm) {
            padding: 30px;
        }
    }

    &-overlay {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(#213744, 45%);
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 10000;
    }

    footer {
        margin-top: $space-md;
    }
}
</style>
