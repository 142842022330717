<template>
    <div class="booking-spacer"></div>
</template>

<style lang="scss" scoped>
.booking-spacer {
    @include media-breakpoint-up(lg) {
        height: 40px;
    }
}
</style>