import useApi from "@/composables/useApi";
import { BookingDataType, Direction } from "@/models/Enums";
import { BasketReponse } from "@/models/api/basket/BasketResponse";
import { mapFromBasketSectionResponse } from "@/models/front/basket/BasketGroup";
import { useBookingStore } from "@/store/booking";
import { useDepartureStore } from "@/store/departure";
import { usePassengerStore } from "@/store/passenger";
import { useResourceStore } from "@/store/resource";
import { useSeatingStore } from "@/store/seatingStore";
import { watchDebounced } from "@vueuse/core";
import { computed, ref } from "vue";
import { useQuery } from "vue-query";
import useBookingNavigation from "./useBookingNavigation";
import { useTransferStore } from "../../../store/transferStore";

const useCalculatePriceQuery = (isCheckout = false) => {
    const { post } = useApi();
    const departureStore = useDepartureStore();
    const resourceStore = useResourceStore();
    const bookingStore = useBookingStore();
    const transferStore = useTransferStore();
    const seatingStore = useSeatingStore();
    const passengerStore = usePassengerStore();
    const { currentStep } = useBookingNavigation();

    const anyDepartureSelected = () => {
        return !!departureStore.getDeparture(Direction.Outward).identifier || !!departureStore.getDeparture(Direction.Return).identifier;
    };

    const accessToken = computed(() => bookingStore.getAccessToken);

    const queryEnabled = computed(() => {
        if (isCheckout) return true;

        if (bookingStore.isAmendment) {
            // wait for a departure to be selected before calculating
            return currentStep.value.number !== 2 || anyDepartureSelected();
        }

        if (accessToken.value) {
            return true;
        }

        return currentStep.value.number > 1 && anyDepartureSelected();
    });

    const getIdentifiers = () => {
        if (currentStep.value.number === 2) {
            return bookingStore.getTrips.flatMap((d) => departureStore.getDeparture(d).identifier).filter((i) => !!i);
        } else if (currentStep.value.number === 5) {
            const transferIdentifiers = [...bookingStore.getTrips.flatMap((d) => transferStore.getPassengers(d).map((p) => p.identifier))];

            const seatingIdentifiers = [
                ...new Set(
                    bookingStore.getTrips.flatMap((d) =>
                        seatingStore.getSelectedSeats(d).seats?.some((s) => s != "?") ? seatingStore.getSelectedSeats(d).identifier : ""
                    )
                ),
            ];

            return bookingStore.getTrips
                .flatMap((d) => resourceStore.getResources(d).map((r) => r.identifier))
                .concat(transferIdentifiers)
                .concat(seatingIdentifiers)
                .filter((i) => !!i);
        }
        return [];
    };

    const params = computed(() => {
        const identifiers = getIdentifiers();
        return {
            identifiers,
            accessToken: accessToken.value,
            includeCurrentBooking: isCheckout || bookingStore.isAmendment || currentStep.value.number > 2,
            currentStep: currentStep.value.number,
            type: typeToRecalc(currentStep.value.number),
            includePayments: bookingStore.isAmendment || isCheckout,
            calculateAMD: isCheckout,
            passengers: bookingStore.getTrips.map((t) => passengerStore.getPassengers(t).map((p) => p.type)),
        };
    });

    const typeToRecalc = (step: number) => {
        switch (step) {
            case 2:
                return BookingDataType.Passenger | BookingDataType.Vehicle | BookingDataType.Cabin;
            case 5:
                return BookingDataType.Extra | BookingDataType.Transfer;
            default:
                return 0;
        }
    };

    const cacheKey = ref();

    watchDebounced(
        params,
        (current) => {
            cacheKey.value = current;
        },
        { debounce: 400 }
    );

    return {
        ...useQuery({
            queryKey: ["calc", cacheKey],
            queryFn: ({ signal }) => {
                return post<BasketReponse>("calculate", params.value, {}, signal).then((resp) => {
                    return {
                        bookingNumber: resp.data.bookingNumber,
                        bookingSections: resp.data.bookingSections.map((s) => mapFromBasketSectionResponse(s)),
                        leftToPay: resp.data.leftToPay,
                        paid: resp.data.paid,
                        total: resp.data.total,
                        amd: resp.data.amd,
                    };
                });
            },
            enabled: queryEnabled,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            staleTime: 1000 * 60,
            retry: false,
        }),
    };
};

export default useCalculatePriceQuery;
