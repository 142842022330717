import { ref } from "vue";
import useApi from "../useApi";

type IslanderVerificationRequest = {
    id: string;
    number: string;
    notIslander?: boolean;
}

type IslanderVerificationResponse = {
    isIslander: boolean;
}

const useIslanderVerification = () => {
    const { post } = useApi();
    const isLoading = ref(false);

    const verifyIslander = (req: IslanderVerificationRequest): Promise<IslanderVerificationResponse> => {
        isLoading.value = true;
        return post<IslanderVerificationResponse>('customer/islander/verify', req)
            .then((resp) => resp.data).finally(() => {
                isLoading.value = false;
            });
    };

    return {
        verifyIslander,
        isLoading
    }
}

export default useIslanderVerification;