<template>
    <div class="payment-options">
        <div v-if="isLoading" class="payment-options__loading">
            <ul>
                <li v-for="i in expectedNumber" :key="i"></li>
            </ul>
        </div>
        <ul v-else-if="paymentAlternatives" :class="{ error: hasError }">
            <li
                v-for="(paymentOption, index) in paymentAlternatives"
                :key="index"
                class="payment-option"
                :class="{ active: inputValue === paymentOption.id }"
            >
                <BaseRadio :disabled="disabled" v-model="inputValue" :value="paymentOption.id" :id="paymentOption.id" name="payment-option">
                    <span class="payment-option__type">{{ paymentOption.label }}</span>
                    <span class="payment-option__description">
                        <b v-if="paymentOption.paymentMethod === 'CC' && reference">{{$t('booking.refund.refundMethod.option.usedCard')}} {{ reference }}</b>

                        <span v-html="paymentOption.description"></span>
                        <SvgIcon class="payment-option__icon" v-if="paymentOption.icon" :icon="paymentOption.icon" />
                    </span>
                </BaseRadio>
                <SavedCreditCards v-if="showSavedCards(paymentOption.id)" />
                <slot v-if="inputValue === paymentOption.id" :option="paymentOption" />
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import BaseRadio from "@/components/base/BaseRadio.vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import { PaymentAlternative } from "@/models/front/PaymentAlternative";
import { useUserStore } from "@/store/user";
import { computed } from "vue";
import SavedCreditCards from "./SavedCreditCards.vue";

interface Props {
    paymentAlternatives?: PaymentAlternative[];
    hasError?: boolean;
    modelValue?: string;
    isLoading?: boolean;
    disabled?: boolean;
    expectedNumber?: number;
    reference?: string;
}

const props = withDefaults(defineProps<Props>(), {
    expectedNumber: 0,
});

const emit = defineEmits(["update:modelValue"]);

const inputValue = computed({
    get() {
        return props.modelValue;
    },
    set(val: string | undefined) {
        emit("update:modelValue", val);
    },
});

const userStore = useUserStore();

const showSavedCards = (paymentOptionId: string) => {
    return inputValue.value === "Card" && paymentOptionId === inputValue.value && userStore.isLoggedIn && !userStore.isAgent;
};
</script>

<style lang="scss" scoped>
.payment-options {
    margin-top: 20px;
    .error {
        &::after {
            content: "";
            height: 16px;
            width: 16px;
            left: 3px;
            top: 3px;
            position: absolute;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCI+PHBhdGggZD0iTTEwMjQgMCAwIDEwMjRWMjA3LjNDMCA5Mi44MSA5Mi44MSAwIDIwNy4zIDBaIiBzdHlsZT0iZmlsbDojYjgwMDAwIi8+PC9zdmc+");
        }
    }

    &__loading {
        > ul {
            > li {
                @include shine;
                height: 62px;

                + li {
                    margin-top: $space-sm;
                }
            }
        }
    }
}

.payment-option {
    padding-left: $space-xs;

    :deep(.radio-button) {
        --radio-size: 28px;

        input[type="radio"] {
            + label {
                flex-direction: column;
                align-items: flex-start;
                padding: 12px 0 12px calc(var(--radio-size) + 15px);
                width: 100%;

                @include media-breakpoint-up(sm) {
                    flex-direction: row;
                    align-items: center;
                }

                .payment-option__type {
                    @include media-breakpoint-up(sm) {
                        flex: 0 0 calc(100% / 3);
                    }
                }

                .payment-option__description {
                    margin-top: 10px;
                    display: flex;
                    flex-direction: column;

                    span{
                        opacity: $o-disabled;
                    }

                    @include media-breakpoint-up(sm) {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    &__icon {
        height: 16px;
        margin-left: 4px;
        margin-bottom: -4px;
    }

    &.active {
        :deep() .radio-button {
            input[type="radio"] {
                + label {
                    .payment-option__description {
                        opacity: 1;
                    }
                }
            }
        }
    }

    + .payment-option {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, $c-gray-200 0, $c-white-100 100%);
        }
    }
}
</style>
