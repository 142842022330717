import { groupBy } from "@/helpers/groupBy";
import { getBookitLocaleStringForCurrentLocale } from "@/helpers/localeHelper";
import { Direction, PassengerType } from "@/models/Enums";
import { Passenger } from "@/models/front/Passenger";
import { PassengerState } from "@/models/store/BookingState";
import { defineStore } from "pinia";
import { useBookingStore } from "./booking";
import { storageWithExpiration } from "@/helpers/storageHelper";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isEqual = require("lodash.isequal");

const getInitialState = () => {
    return {
        outward: [] as PassengerState[],
        return: [] as PassengerState[],
    };
};

export const usePassengerStore = defineStore("passenger", {
    state: () => {
        return getInitialState();
    },
    getters: {
        getPassengers:
            (state) =>
            (direction: Direction): PassengerState[] => {
                const bookingStore = useBookingStore();
                switch (direction) {
                    case Direction.Outward:
                        return state.outward as PassengerState[];
                    case Direction.Return:
                        if (!bookingStore.differentDetailsReturn) {
                            const newPassengers: PassengerState[] = [];
                            for (let i = 0; i < state.return.length; i++) {
                                newPassengers.push({
                                    ...state.outward[i],
                                    type: state.return[i].type,
                                    number: state.return[i].number,
                                    id: state.return[i].id,
                                    isIslander: state.return[i].isIslander,
                                    needsValidation: state.return[i].needsValidation,
                                });
                            }
                            return newPassengers as PassengerState[];
                        }
                        return state.return as PassengerState[];
                }
            },
        isMixed: (state) => {
            const passengers = state.outward.concat(state.return);

            return passengers.some((o) => o.isIslander) && passengers.some((o) => !o.isIslander);
        }
    },
    actions: {
        setPassenger(direction: Direction, passenger: PassengerState) {
            const passengerArray = this.getPassengers(direction);
            const count = passengerArray.filter((p) => p.type === passenger.type).length;
            if (count > passenger.number) {
                this.removePassenger(direction, passenger.type, passenger.number);
            } else {
                this.addPassenger(direction, passenger);
            }
        },
        addPassenger(direction: Direction, passenger: PassengerState) {
            if (passenger.number === 0) return;

            const getId = (index: number) => {
                return `${passenger.type}|${index}`;
            };
            for (let i = 0; i < passenger.number; i++) {
                if (direction === Direction.Outward) {
                    if (!this.outward.find((p) => p.type === passenger.type && p.number === i + 1)) {
                        this.outward.push({ ...passenger, id: getId(i), number: i + 1, unknownDetails: false });
                    }
                } else {
                    if (!this.return.find((p) => p.type === passenger.type && p.number === i + 1)) {
                        this.return.push({ ...passenger, id: getId(i), number: i + 1, unknownDetails: false });
                    }
                }
            }
        },
        removePassenger(direction: Direction, type: PassengerType, value: number) {
            if (direction === Direction.Outward && this.outward.length > 0) {
                this.outward = this.outward.filter((p) => p.number <= value || p.type !== type);
            } else if (this.return.length > 0) {
                this.return = this.return.filter((p) => p.number <= value || p.type !== type);
            }
        },
        setPassengers(direction: Direction, passengers: PassengerState[]) {
            if (direction === Direction.Outward) {
                this.outward = passengers;
            } else {
                this.return = passengers;
            }
        },
        updatePassenger(direction: Direction, passenger: PassengerState) {
            if (direction === Direction.Outward) {
                const index = this.outward.findIndex((p) => p.id === passenger.id);
                this.outward.splice(index, 1, passenger);
            } else {
                const index = this.return.findIndex((p) => p.id === passenger.id);
                this.return.splice(index, 1, passenger);
            }
        },
        setFromApi(travelers: Passenger[]) {
            if (travelers.length < 1) {
                this.setPassengers(Direction.Outward, []);
                this.setPassengers(Direction.Return, []);
            }

            const routes = groupBy(travelers, "route");
            const bookingStore = useBookingStore();

            for (const route in routes) {
                const direction = route === bookingStore.getBooking.outward.route ? Direction.Outward : Direction.Return;
                const passengers: PassengerState[] = [];
                const travelersInAPI = routes[route] as Passenger[];

                const groupedPassengers = groupBy(
                    travelersInAPI.filter((traveler) => traveler.route == route),
                    "type"
                );

                for (const key in groupedPassengers) {
                    const resources = groupedPassengers[key] as Passenger[];
                    resources.forEach((p, index) => {
                        passengers.push({
                            country: p.country ? p.country : getBookitLocaleStringForCurrentLocale(),
                            dateOfBirth: p.dateOfBirth,
                            disability: p.disability,
                            firstName: p.firstName,
                            gender: p.gender,
                            lastName: p.lastName,
                            title: p.title,
                            type: p.type,
                            number: index + 1,
                            id: p.id,
                            route,
                            isEditable: p.isEditable,
                            isIslander: p.isIslander,
                            needsValidation: p.needsValidation,
                        } as PassengerState);
                    });
                }

                this.setPassengers(direction, passengers);
            }

            const outwardPassengers = this.outward.map((p) => ({
                firstName: p.firstName,
                lastName: p.lastName,
                dateOfBirth: p.dateOfBirth,
                country: p.country,
                gender: p.gender,
            }));

            const returnPassengers = this.return.map((p) => ({
                firstName: p.firstName,
                lastName: p.lastName,
                dateOfBirth: p.dateOfBirth,
                country: p.country,
                gender: p.gender,
            }));

            if (!isEqual(outwardPassengers, returnPassengers)) {
                bookingStore.setIsDifferentDetails(true);
            }
        },
    },
    persist: {
        storage: storageWithExpiration(localStorage),
    },
});
