import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8858da7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!

  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.linkUrl,
    class: _normalizeClass(["tab", { active: _ctx.active }])
  }, [
    _createVNode(_component_SvgIcon, { icon: _ctx.icon }, null, 8, ["icon"]),
    _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.subtitle), 1)
  ], 10, _hoisted_1))
}