<template>
    <form @submit.prevent="verify" class="verify-islander">
        <template v-if="verificationStatus === 'success' || verificationStatus === 'successNotIslander'">
            <div v-if="verificationStatus === 'success'" class="verify-islander__info">
                <SvgIcon class="success" icon="checkmark" />
                <div>
                    <span v-html="$t('islander.verify.success')"></span>
                    <a @click="emit('login')">{{ $t("islander.verify.continueToLogin") }}</a>
                </div>
            </div>
            <div v-else class="verify-islander__info">
                <SvgIcon class="success" icon="checkmark" />
                <div>
                    <span v-html="$t('islander.verify.successNotIslander')"></span>
                    <a @click="emit('login')">{{ $t("islander.verify.continueToLogin") }}</a>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="form" :class="{ disabled: state.notIslander }">
                <div class="verify-islander__info">
                    <SvgIcon :icon="'booking/info'" />
                    <p v-html="$t('islander.verify.description')"></p>
                </div>
                <div class="verify-islander__field">
                    <BaseInput
                        class="verify-islander__input"
                        :name="'personalNumber'"
                        :label="$t('islander.verify.number.label')"
                        :placeholder="$t('islander.verify.number.placeholder')"
                        :hasError="v$.ssn.$error"
                        v-model="v$.ssn.$model"
                        mask="############"
                    />
                    <BaseButton type="submit" class="btn--fixed-width" :loading="isLoading">{{ $t("button.verify") }}</BaseButton>
                </div>
                <BaseMessages v-if="errorMessages.length" :messages="errorMessages" :messageType="BaseMessageType.Error" />
            </div>
            <div v-if="verificationStatus === 'verificationError' && !isLoading" class="verify-islander__error">
                <hr />
                <div class="verify-islander__info">
                    <SvgIcon :icon="'booking/info'" />
                    <b>{{ $t("islander.verify.changeStatus.title") }}</b>
                </div>
                <p v-html="$t('islander.verify.changeStatus.description')"></p>
                <div class="not-islander">
                    <div class="radios">
                        <BaseRadio
                            v-model="v$.notIslander.$model"
                            :label="$t('islander.verify.changeStatus.isIslander')"
                            name="notIslander"
                            id="notIslander"
                            :value="false"
                        />
                        <BaseRadio
                            v-model="v$.notIslander.$model"
                            :label="$t('islander.verify.changeStatus.notIslander')"
                            name="notIslander"
                            id="isIslander"
                            :value="true"
                        />
                    </div>
                    <BaseButton v-if="state.notIslander" class="btn--fixed-width" type="submit" :loading="isLoading">{{
                        $t("button.confirm")
                    }}</BaseButton>
                </div>
            </div>
        </template>
    </form>
</template>

<script setup lang="ts">
import BaseRadio from "@/components/base/BaseRadio.vue";
import useIslanderVerification from "@/composables/login/useIslanderVerification";
import { BaseMessageType } from "@/models/Enums";
import useVuelidate from "@vuelidate/core";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import BaseButton from "~/components/base/BaseButton.vue";
import BaseInput from "~/components/base/BaseInput.vue";
import BaseMessages from "~/components/base/BaseMessages.vue";
import SvgIcon from "~/components/base/SvgIcon.vue";

interface Props {
    id: string;
}

const props = defineProps<Props>();

const emit = defineEmits(["login", "success"]);

const state = reactive({
    ssn: "",
    notIslander: false,
});

const rules = {
    ssn: {
        v: (val: string) => val.length === 12 || state.notIslander,
    },
    notIslander: {},
};

const v$ = useVuelidate(rules, state);

const { verifyIslander, isLoading } = useIslanderVerification();
const verificationStatus = ref<"" | "success" | "error" | "verificationError" | "successNotIslander">("");

const verify = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    verificationStatus.value = "";

    const req = {
        id: props.id,
        number: state.ssn !== "" ? state.ssn : "0",
        notIslander: state.notIslander,
    };

    verifyIslander(req)
        .then((resp) => {
            emit("success");
            if (resp.isIslander) {
                verificationStatus.value = "success";
            } else {
                verificationStatus.value = "successNotIslander";
            }
        })
        .catch((err) => {
            if (err.response.status === 400) {
                verificationStatus.value = "verificationError";
            } else {
                verificationStatus.value = "error";
            }
        });
};

const { t } = useI18n();
const errorMessages = computed(() => {
    switch (verificationStatus.value) {
        case "verificationError":
            return [t("islander.verify.error")];
        case "error":
            return [t("errors.0")];
        default:
            return [];
    }
});
</script>

<script lang="ts"></script>

<style scoped lang="scss">
.verify-islander {
    display: flex;
    flex-flow: column;
    gap: $space-md;
    background-color: $c-yellow-200;
    border-radius: 8px;
    padding: $space-md;

    .form {
        display: flex;
        flex-flow: column;
        gap: $space-md;
    }

    .disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    &__info {
        display: flex;
        gap: $space-sm;
        max-width: 650px;

        a {
            color: $c-blue-300;
            cursor: pointer;
        }
    }

    &__field {
        display: flex;
        gap: $space-md;
        max-width: 650px;
    }

    &__input {
        flex: 1;
    }

    hr {
        color: $c-text-primary;
    }

    &__error {
        max-width: 650px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .not-islander {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 5px;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items: flex-start;
            }
            .radios {
                display: flex;
                align-items: center;
                gap: 15px;
                min-height: 40px;
                flex: 1;
                flex-wrap: wrap;

                .radio-button {
                    text-wrap: nowrap;
                    max-width: 230px;
                }
            }

            .btn {
                @include media-breakpoint-down(sm) {
                    margin-right: 0;
                    align-self: center;
                }
            }
        }
    }

    .icon {
        display: block;
        height: 24px;
        width: 24px;
        min-height: 24px;
        min-width: 24px;
        color: $c-blue-dark;
        background-color: $c-white;
        border-radius: 50%;

        &.success {
            padding: 4px;
            background-color: $c-blue-dark;
            color: $c-white;
        }
    }

    .btn {
        margin-right: auto;
    }

    :deep(.checkbox) {
        --checkbox-size: 24px;
        input + label::before {
            background-color: $c-white;
        }
    }
}
</style>
