<template>
    <div class="radio-button">
        <input type="radio" 
            :id="id" 
            :name="name" 
            :disabled="disabled" 
            :value="value" 
            :step="step" 
            v-model="inputValue" 
            tabindex="0" 
        />
        <label :for="id">
            <slot :label="label">
                <span>{{ label }}</span>
            </slot>
        </label>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
    props: {
        id: String,
        name: String,
        modelValue: [String, Number, Boolean],
        value: [String, Number, Boolean],
        hasError: {
            type: Boolean,
            required: false,
        },
        errors: {
            type: Array,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        valid: {
            type: Boolean,
            required: false,
            default: true,
        },
        step: {
            type: String,
            required: false,
        },
    },
    setup(props, { emit }) {
        const inputValue = computed({
            get(): string | number | Boolean | undefined {
                return props.modelValue;
            },
            set(value: string | number | Boolean | undefined) {
                emit('update:modelValue', value);
            },
        });

        return { inputValue };
    },
});
</script>

<style lang="scss" scoped>
.radio-button {
	--radio-size: 20px;
    display: flex;
    position: relative;
    align-items: center;

    input[type="radio"] {
        position: absolute;
        left: -9999px;
        opacity: 0;

        + label {
            display: flex;
            align-items: center;
            position: relative;
            padding-left: calc(var(--radio-size) + 10px);
            @include font("p");
            cursor: pointer;

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                width: var(--radio-size);
                height: var(--radio-size);
                background-color: $c-white-100;
                border: 1px solid $c-gray-200;
                border-radius: 100%;
                transform: translateY(-50%);
            }

            :deep() > span {
                display: block;
                margin-bottom: -2px;

				a[href] {
					color: $c-blue-300;
					text-decoration: underline;
				}
            }
        }

        &:checked {
            + label {
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: calc((var(--radio-size) / 4));
                    width: calc(var(--radio-size) / 2);
                    height: calc(var(--radio-size) / 2);
                    background-color: $c-black-100;
                    border-radius: 100%;
                    transform: translateY(-50%);
                }
            }
        }

        &:not(:disabled) {
            &:focus {
                + label::before {
                    outline: 2px solid $c-blue-200;
                    outline-offset: -1px;
                }
            }

            &:hover {
                + label::before {
                    border-color: $c-blue-dark;
                }
            }
        }
        
        &:disabled{
            + label {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
}
</style>
