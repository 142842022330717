export enum MessageType {
    CustomerService,
    BookingInfo,
}

export enum TripType {
    Single,
    Return,
}

export enum Direction {
    Outward,
    Return,
}

export enum BusDirection {
    From = 1,
    To = 2,
}

export enum PassengerType {
    Adult = "A",
    Teen = "T",
    Youth = "U",
    Child = "C",
    Infant = "I",
    Senior = "P",
    Student = "S",
    Commuter = "F",
    CabinCommuter = "H",
    DiscountCard = "K",
    CarPackage = "M",
    AdultIslander = "AG",
    TeenIslander = "TG",
    YouthIslander = "UG",
    ChildIslander = "CG",
    InfantIslander = "IG",
    SeniorIslander = "PG",
    CommuterIslander = "FG",
    CabinCommuterIslander = "HG",
    CarPackageIslander = "MG",
}

export enum UserType {
    Unknown,
    Customer,
    Agent,
    Booking,
}

export enum PageUrl {
    Booking = "booking_path",
    Account = "account_path",
    Queue = "queue_path"
}

export enum PaymentStatus {
    Undefined = "Undefined",
    FullyPaid = "FullyPaid",
    Unpaid = "Unpaid",
    PartlyPaid = "PartlyPaid",
}

export enum BookingDataType {
    Passenger = 1,
    Vehicle = 2,
    Extra = 8,
    Transfer = 16,
    Cabin = 64,
}

export enum BaseMessageType {
    Info = "info",
    Success = "success",
    Warning = "warning",
    Error = "danger",
}

export enum BookingActivity {
    Checkout = "checkout",
}

export type OpenBookingType = "Amend" | "Payment" | "Refund";

export enum ErrorCodes {
    BookingLocked = 1512,
    InvalidIslander = 11001,
    CommuterInvalid = 11007,
    InvalidPassword = 11006,
}
