<template>
    <div class="input-wrapper">
        <div class="input-field" :class="{ active: isActive, error: hasError }">
            <Multiselect
                @open="onFocus"
                @close="onBlur"
                v-model="inputValue"
                :options="options"
                :canDeselect="canDeselect"
                :canClear="canClear"
                :disabled="disabled"
                :searchable="searchable"
                :class="{ disabled: disabled, 'has-label': label }"
                :multipleLabel="getMultipleLabel"
                :placeholder="placeholder"
                :mode="mode"
                nativeSupport
                :name="name"
                :closeOnSelect="closeOnSelect"
                :hideSelected="hideSelected"
                @select="onSelect"
                :createOption="createOption"
                :allowAbsent="allowAbsent"
                :max="max"
            >
                <template #caret>
                    <span class="caret">
                        <SvgIcon icon="chevron/chevron-down" />
                    </span>
                </template>
            </Multiselect>
            <label v-if="label">{{ label }}</label>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { DropdownOption } from "@/models/DropdownOption";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { computed, ref } from "vue";
import SvgIcon from "./SvgIcon.vue";

interface Props {
    name?: string;
    modelValue: any;
    options?: Readonly<DropdownOption[]>;
    hasError?: boolean;
    errors?: any[];
    label?: string;
    disabled?: boolean;
    searchable?: boolean;
    canClear?: boolean;
    placeholder?: string;
    mode?: string;
    closeOnSelect?: boolean;
    loading?: boolean;
    hideSelected?: boolean;
    multipleLabelMaxLength?: number;
    canDeselect?: boolean;
    createOption?: boolean;
    allowAbsent?: boolean;
    max?: number;
}

const props = withDefaults(defineProps<Props>(), { mode: "single", closeOnSelect: true, multipleLabelMaxLength: 200 });

const emit = defineEmits(["update:modelValue", "select"]);

const inputValue = computed({
    get(): any {
        return props.modelValue;
    },
    set(value: any) {
        emit("update:modelValue", value);
    },
});

const active = ref(false);
const onFocus = () => {
    active.value = true;
};

const onBlur = () => {
    active.value = false;
};

const isActive = computed(() => {
    return (inputValue.value ?? "").toString().length > 0 || active.value;
});

const getMultipleLabel = () => {
    const label = props.options
        ?.filter((o) => (props.modelValue as any).includes(o.value))
        .map((o) => o.label)
        .join(", ");

    if ((label?.length ?? 0) > props.multipleLabelMaxLength) {
        return label?.substring(0, props.multipleLabelMaxLength) + "...";
    }
    return label;
};

const onSelect = (e: any) => {
    active.value = true;
    emit("select", e);
};
</script>

<style lang="scss" scoped>
@import "@/styles/forms.scss";

.multiselect {
    $prefix: &;
    $height: 42px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--i-padding-md);
    height: $height;
    min-height: initial;
    border: 1px solid $c-gray-200;
    border-radius: $br-medium;

    &:hover {
        outline: 1px solid $c-blue-200;
        outline-offset: -1px;
    }

    &.is-active {
        outline: 2px solid $c-blue-200;
        outline-offset: -1px;
        box-shadow: none;

        .caret {
            :deep(svg) {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    &.is-disabled {
        background-color: $c-white;

        + label {
            opacity: 0.5 !important;
        }
    }

    &.has-label {
        :deep() {
            #{$prefix}-single-label,
            #{$prefix}-multiple-label,
            #{$prefix}-search {
                padding-top: 15px;
            }
        }
    }

    .caret {
        :deep(svg) {
            position: absolute;
            right: 0;
            top: 50%;
            padding-top: 1px;
            height: 0.875rem;
            width: 0.875rem;
            color: $c-black-100;
            transform: translateY(-50%);
        }
    }

    :deep() {
        #{$prefix}-single-label,
        #{$prefix}-multiple-label,
        #{$prefix}-placeholder {
            top: 50%;
            color: $c-black-100 !important;
            @include font("input");
            transform: translateY(-50%);
            padding: 0;
        }

        #{$prefix}-placeholder {
            opacity: 0.7;
        }

        #{$prefix}-dropdown {
            bottom: -4px;
            max-height: 288px;
            box-shadow: 0 8px 8px rgb($c-black-100, 15%);
            background: $c-white-100;
            border: none;
            overflow-y: auto;
            z-index: 20;
        }

        #{$prefix}-options {
            max-height: unset;
        }

        #{$prefix}-clear {
            right: 22px;
            padding: 4px 8px;
            border-radius: $br-input;
            transition: none;

            &:focus {
                outline: 2px solid $c-blue-200;
                outline-offset: -1px;
            }
        }

        #{$prefix}-search {
            padding-left: 0;
            border: none;
        }

        #{$prefix}-option {
            padding: 0 var(--i-padding-md);
            height: 48px;
            border-top: 1px solid $c-gray-200;
            @include font("input");

            &:last-of-type {
                border-bottom: 1px solid $c-gray-200;
            }

            &.is-pointed,
            &.is-selected {
                position: relative;
                background-color: $c-white-100;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 5px;
                    left: 0;
                    right: 0;
                    bottom: 5px;
                    background-color: darken($c-white-100, 5%);
                }

                span {
                    color: $c-black-100;
                    z-index: 1;
                }
            }

            &.is-selected {
                &::before {
                    background-color: $c-blue-800;
                }
            }
        }
    }
}
</style>
