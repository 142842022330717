import { SiteSearch } from '@/models/front/SiteSearch';
import { defineStore } from 'pinia';

export const useSiteSearchStore = defineStore('siteSearchStore', {
	state: () => {
		return {
			query: '',
			result: {
				page: 1,
				pageCount: 1,
				resultCount: 1,
				results: []
			} as SiteSearch,
			page: 1
		};
	},
	getters: {
		getQuery(state) {
			return state.query;
		},
		getPage(state) {
			return state.page;
		},
		getResult(state) {
			return state.result;
		}
	},
	actions: {
		setQuery(query: string) {
			this.query = query;
		},
		setPage(page: number) {
			this.page = page;
		},
		setResults(result: SiteSearch) {
			this.result = result;
		}
	}
});
